// @flow
// flowlint inexact-spread:off

import * as React from "react"
import { Grid } from "semantic-ui-react"

export default ({ children, ...props }: { children: React.Node }) => (
  <Grid stackable {...props}>
    <Grid.Row>
      <Grid.Column
        className="not-mobile"
        computer={3}
        largeScreen={3}
        tablet={1}
      />
      <Grid.Column computer={10} largeScreen={10} mobile={10} tablet={14}>
        {children}
      </Grid.Column>
      <Grid.Column
        className="not-mobile"
        computer={3}
        largeScreen={3}
        tablet={1}
      />
    </Grid.Row>
  </Grid>
)
