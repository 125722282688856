import React from "react"
import { Grid, Icon, Accordion } from "semantic-ui-react"
import { Link } from "react-router-dom"
import SuggestionCard from "../../Products/components/SuggestionCard"
import SocialShareButtons from "../../../components/SocialShareButtons"

const faqEntries = [
  {
    title: "Was ist das Ziel von LegalGo?",
    content: (
      <p>
        LegalGo hat es sich zur Aufgabe gemacht, den Rechtsdienstleistungsmarkt
        für Sie konsumentenfreundlicher zu gestalten. Wir bieten Ihnen
        Anwaltsdienstleistungen zu transparenten und ebenso fairen Preisen an.
        Zudem ist es unser Anliegen, die Verfügbarkeit von
        Rechtsdienstleistungen für Sie mobiler zu gestalten. Egal, ob Sie nun zu
        Hause, im Büro oder in den Ferien sind: Die Rechtsprodukte von LegalGo
        können Sie unabhängig Ihres Standorts und ohne Umweg zum Anwalt
        jederzeit in Anspruch nehmen. Unsere Produktepalette richtet sich sowohl
        an Privatpersonen als auch an Unternehmer, die neben ihren
        geschäftlichen auch ihre privaten Belange professionell regeln wollen.
      </p>
    ),
  },
  {
    title: "Welche Angebote finde ich auf LegalGo?",
    content: (
      <div>
        <p>
          Mit unserer intelligenten Software ermöglichen wir es Ihnen
          professionelle und auf Ihre Bedürfnisse angepasste Rechtsdokumente
          selbst zu erstellen. Wählen Sie eines der verschiedenen Angebote aus.
          Beantworten Sie anschliessend die Fragen in der Eingabemaske.
          Basierend auf Ihren persönlichen Eingaben wird die Software von
          LegalGo in Kürze einen individuell auf Sie abgestimmten Vertrag
          erstellen. Sie erhalten einen professionellen Vertrag mit anwaltlich
          vorgeprüften Formulierungen. Den Vertrag können Sie bequem von zu
          Hause oder vom Büro aus erstellen, ohne den oftmals umständlichen,
          kostspieligen Weg zum Anwalt.
        </p>
        <p>
          Ihre Situation ist kompliziert oder/und Sie wollen lieber von einem
          Anwalt beraten werden? Auch hier hat LegalGo eine Lösung für Sie
          parat! Mit unseren Fixpreisangeboten erarbeiten Sie in Zusammenarbeit
          mit einem Anwalt eine detaillierte, auf Ihre Bedürfnisse
          massgeschneiderte Rechtslösung. Die Anwaltsdienstleistung erfolgt
          absolut professionell und ohne versteckten, nachträglichen Kosten: Sie
          zahlen keinen Franken mehr als auf LegalGo ausgewiesen.{" "}
        </p>
      </div>
    ),
  },
  {
    title: "Wie erstelle ich selbst einen Vertrag auf LegalGo?",
    content: (
      <p>
        Auf der Seite{" "}
        <Link to="/so-funktionieren-vertraege">so funktionieren Verträge</Link>{" "}
        finden Sie den genauen Ablauf zur Erstellung eines Vertrages. Mehr über
        unsere Fixpreisangebote erfahren Sie unter{" "}
        <Link to="/so-funktionieren-fixpreise">so funktionieren Fixpreise</Link>.
      </p>
    ),
  },
  {
    title:
      "Ich habe einen Vertrag erstellt und nun Fehler in der Vertragsvorlage von LegalGo entdeckt: Was kann ich tun?",
    content: (
      <div>
        <p>
          Ihren selbst erstellten Vertrag erhalten Sie im unveränderbaren
          PDF-Format.
        </p>
        <p>
          Sollten Sie in der Vertragsvorlage von LegalGo Fehler (zum Beispiel
          Schreibfehler) entdecken Kontaktieren Sie uns bitte sofort unter{" "}
          <a href="mailto:info@legalgo.ch">info@legalgo.ch</a>.
          Selbstverständlich erhalten Sie umgehend und kostenlos eine
          korrigierte Version Ihres Vertrages.
        </p>
      </div>
    ),
  },
  {
    title:
      "Kann ich meinen selbst erstellten Vertrag nachträglich selbständig anpassen oder ergänzen?",
    content: (
      <div>
        <p>
          Ihren selbst erstellten Vertrag erhalten Sie im unveränderbaren
          PDF-Format.
        </p>
        <p>
          Sollten Sie von Vornherein oder nach der Erstellung/Bezahlung Ihres
          Vertrages noch selbständig Änderungen/Ergänzungen vornehmen wollen,
          können Sie das Vertragsdokument gegen einen Aufpreis jederzeit
          zusätzlich im Wordformat kaufen.
        </p>
        <p>
          Es handelt sich hierbei um ein kostenpflichtiges Zusatzangebot. Dieses
          erlaubt es Ihnen im Sinne einer Lizenz jederzeit und unbeschränkt
          selbständig Anpassungen an Ihrem Vertragsdokument vorzunehmen. Der
          Aufpreis für ein zusätzliches Worddokument ist jeweils beim Abschluss
          des Bestellvorgangs oder rechts auf der Informationsseite zum
          Vertragsangebot ersichtlich.
        </p>
        <p>
          Sie können dieses Zusatzangebot wie folgt kaufen: Direkt im
          Bestellvorgang, oder jederzeit danach über Ihren individuellen
          Downloadlink den Sie nach Abschluss der Bestellung erhalten.
        </p>
      </div>
    ),
  },
  {
    title:
      "Kann ich meinen selbst erstellten Vertrag nachträglich durch einen Anwalt überprüfen lassen?",
    content: (
      <div>
        <p>
          Ja. Gegen einen Aufpreis bietet Ihnen LegalGo eine nachträgliche
          anwaltliche Überprüfung Ihres selbst erstellten Vertrages an. Eine
          solche Überprüfung empfiehlt sich insbesondere dann, wenn Sie sich
          nicht ganz sicher sind, ob der Vertrag Ihre rechtlichen Bedürfnisse
          optimal abdeckt.
        </p>
        <p>
          Bei der anwaltlichen Überprüfung handelt es sich um ein
          kostenpflichtiges Zusatzangebot. Der Aufpreis hierfür ist jeweils beim
          Abschluss des Bestellvorgangs oder rechts auf der Informationsseite
          zum Vertragsangebot ersichtlich.
        </p>
        <p>
          Sie können dieses Zusatzangebot wie folgt kaufen: Direkt im
          Bestellvorgang, oder jederzeit danach über Ihren individuellen
          Downloadlink den Sie nach Abschluss der Bestellung erhalten.
        </p>
      </div>
    ),
  },
  {
    title:
      "Was beinhaltet die anwaltliche Überprüfung meines selbst erstellten Vertrages?",
    content: (
      <div>
        <p>
          Umgehend nach Kauf dieses Zusatzangebotes wird Ihr selbst erstellter
          Vertrag an einen Anwalt von LegalGo weitergeleitet. Er wird Ihr
          Vertragsdokument vorab zur Vorbereitung studieren und Sie innerhalb
          eines Werktages zwecks Besprechung Ihrer Fragen telefonisch
          kontaktieren. Im Rahmen eines 15-minütigen Beratungsgespräches können
          dann noch unklare Vertragsinhalte gemeinsam mit dem Anwalt geklärt
          werden.
        </p>
        <p>
          Bei Bedarf wird der Anwalt Ihren Vertrag im Anschluss an das
          Beratungsgespräch korrigieren. Die korrigierte Version wird Ihnen dann
          per E-Mail zugeschickt.
        </p>
        <p>
          <strong>WICHTIG</strong>: Der Partneranwalt von LegalGo wird Ihren
          selbst erstellten Vertrag mit Ihnen besprechen und allenfalls nach
          Ihren Bedürfnissen korrigieren und ergänzen. Basis hierfür ist der
          bestehende, bereits erstellte Vertrag mithilfe der Software von
          LegalGo. Sollte Bedarf für eine umfangreiche, auf Ihre Person
          detailliert massgeschneiderte Vertragslösung bestehen, verweisen wir
          auf unsere Fixpreisangebote.
        </p>
      </div>
    ),
  },
  {
    title:
      "Wie verläuft die Zusammenarbeit mit dem Anwalt bei den Fixpreisangeboten?",
    content: (
      <p>
        Mehr über den detaillierten Ablauf unserer Fixpreisangebote erfahren Sie
        unter{" "}
        <Link to="/so-funktionieren-fixpreise">so funktionieren Fixpreise</Link>.
      </p>
    ),
  },
  {
    title: "Wie kann ich ein Angebot bezahlen?",
    content: (
      <div>
        <p>
          Wir akzeptieren die Kreditkarten von Mastercard und VISA sowie
          Einzahlungen per Rechnung (zahlbar innert 30 Tagen). Weitere
          Zahlungsmittel werden wir bei Nachfrage aufschalten, kontaktieren Sie
          uns also bitte falls Sie eine zusätzliche Zahlungsmöglichkeit
          wünschen.
        </p>
      </div>
    ),
  },
]

export default class extends React.Component<{}, *> {
  state = { activeIndex: -1 }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index
    this.setState({ activeIndex: newIndex })
  }

  render() {
    const { activeIndex } = this.state
    return (
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={11}>
            <Accordion>
              {faqEntries
                .map(({ title, content }, index) => [
                  <Accordion.Title
                    key={title}
                    active={activeIndex === index}
                    index={index}
                    onClick={this.handleClick}
                  >
                    <Icon name="angle down" />
                    {title}
                  </Accordion.Title>,
                  <Accordion.Content active={activeIndex === index} key={index}>
                    {content}
                  </Accordion.Content>,
                ])
                .reduce((acc, cur) => acc.concat(cur), [])}
            </Accordion>
          </Grid.Column>
          <Grid.Column width={5}>
            <div className="sidebar">
              <SuggestionCard />
              <SocialShareButtons />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}
