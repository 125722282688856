// @flow

export function fetchNavigation(): ApiCall {
  return {
    CALL_API: {
      endpoint: "/navigation",
      config: {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      },
      types: [
        "FETCH_NAVIGATION_REQUEST",
        "FETCH_NAVIGATION_SUCCESS",
        "FETCH_NAVIGATION_FAILURE",
      ],
    },
  }
}

export function findContractOrFixpriceRequest(id: string): ApiCall {
  return {
    CALL_API: {
      endpoint: `/catalog/findContractOrFixpriceRequest?id=${id}`,
      config: {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      },
      types: [
        "FETCH_CONTRACT_OR_FIXPRICEREQUEST_REQUEST",
        "FETCH_CONTRACT_OR_FIXPRICEREQUEST_SUCCESS",
        "FETCH_CONTRACT_OR_FIXPRICEREQUEST_FAILURE",
      ],
    },
  }
}
