// @flow
// flowlint unclear-type:off

import { combineReducers } from "redux"
import type { Store as ReduxStore, Dispatch as ReduxDispatch } from "redux"
import { connectRouter } from "connected-react-router"

// TODO we should also inject these!
import AuthAPI from "../routes/Auth/apis/AuthAPI"
import EmailAPI from "../apis/EmailAPI"

import authReducer from "../routes/Auth/modules"
import type {
  State as AuthState,
  Action as AuthAction,
} from "../routes/Auth/modules"

// We don't load news lazily because they need
// to be server-rendered and that code does not
// support lazy loading (yet).
import { reducer as newsReducer } from "../react-redux-prismic-blog"
import type {
  State as NewsState,
  Action as NewsAction,
} from "../react-redux-prismic-blog"

import expertsReducer from "../routes/Experts/modules"
import type {
  State as ExpertsState,
  Action as ExpertsAction,
} from "../routes/Experts/modules"

import type { State as CommonState, Action as CommonAction } from "../modules"

import commonReducer from "../modules"

export const reducers = {}

export function createReducer(history: History) {
  return combineReducers({
    auth: authReducer,
    common: commonReducer,
    experts: expertsReducer,
    news: newsReducer,
    router: connectRouter(history),
  })
}

export const services = {
  authApi: new AuthAPI(),
  emailAPI: new EmailAPI(),
}

export type Services = {|
  authApi: AuthAPI,
  emailAPI: EmailAPI,
|}

// https://blog.callstack.io/type-checking-react-and-redux-thunk-with-flow-part-2-206ce5f6e705

export type State = {|
  auth: AuthState,
  common: CommonState,
  experts: ExpertsState,
  news: NewsState,
  router: LocationState,
|}

// flowlint-next-line deprecated-type:off
export type Action = CommonAction | AuthAction | NewsAction | ExpertsAction | *

export type Store = ReduxStore<State, Action> & {
  asyncReducers: Object,
}

export type GetState = () => State

export type Thunk<A> = (
  (
    ReduxDispatch<Action> & Thunk<Action>,
    GetState,
    Services
  ) => Promise<void> | void
) => A

// flowlint-next-line deprecated-type:off
export type Dispatch = ReduxDispatch<Action> & Thunk<Action> & ((*) => *)
