// @flow

import * as React from "react"
// $FlowFixMe
import { withRouter } from "react-router"

// https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/guides/scroll-restoration.md

class ScrollToTop extends React.Component<{
  location: Location,
  children: React.Node,
}> {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      /*if (prevProps.scrollTop > 0) {
        window.scrollTo(1, 1)
      } else {
        window.scrollTo(0, 0)
      }*/
      window.scrollTo(0, 0)
    }
  }

  render() {
    return this.props.children
  }
}

export default withRouter(ScrollToTop)
