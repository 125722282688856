import type { Action } from ".."

export type BlogPostId = string

export type BlogPostDto = {
  id: string,
  uid: string,
  last_publication_date: Date,
  data: {
    title: string,
    author: {
      data: {
        name: string,
      },
      slug: string,
    },
    category: {
      data: {
        name: string,
      },
      uid: string,
    },
    content: string,
    summary: string,
    image: { url: string },
  },
}

export type BlogPost = {
  header: string,
  uid: BlogPostId,
  url: string,
  summary: string,
  content: string,
  author: string,
  date: ?Date,
  image: string,
  category: {
    name: string,
    path: string,
    url: string,
  },
}

export type State = {
  posts: {
    [key: BlogPostId]: BlogPost,
  },
  isFetching: boolean,
  errors: {
    [key: BlogPostId]: ?string,
  },
}

const initialState = {
  posts: {},
  isFetching: false,
  errors: {},
}

export default function reduce(
  state: State = initialState,
  action: Action
): State {
  switch (action.type) {
    case "FETCH_BLOG_POST_REQUEST":
      return {
        ...state,
        isFetching: true,
        errors: {},
      }
    case "FETCH_BLOG_POST_SUCCESS": {
      return {
        ...state,
        posts: {
          ...state.posts,
          [action.response.uid]: action.response,
        },
        errors: {},
        isFetching: false,
      }
    }
    case "FETCH_BLOG_POST_FAILURE":
      return {
        ...state,
        isFetching: false,
        errors: {
          ...state.errors,
          [action.uid]: action.error,
        },
      }
    default:
      return state
  }
}

export const getBlogPost = (
  state: ApplicationState,
  id: BlogPostId
): ?BlogPost => {
  // No clue why, but calls is sometimes undefined?
  if (!state.news.news.posts) {
    return null
  }
  return state.news.news.posts[id]
}

export const isFetchingBlogPost = (state: ApplicationState): boolean => {
  return state.news.news.isFetching
}

export const getFetchBlogPostError = (
  state: ApplicationState,
  id: BlogPostId
): ?string => {
  return state.news.news.errors[id]
}
