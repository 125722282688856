// @flow

import * as React from "react"
import { Link } from "react-router-dom"
import { Header, Icon } from "semantic-ui-react"

export default ({ error }: { error?: Error | ApiError | string }) => {
  let message: string = "unbekannt"

  if (error != null && error.message != null) {
    message = error.toString()
  } else if (error != null && typeof error.status === "string") {
    message = error.status
  } else if (typeof error === "string") {
    message = error
  }

  return (
    <div style={{ textAlign: "center" }}>
      <Icon name="remove circle" color="red" size="massive" />
      <Header as="h2">
        Oops, die Seite konnte nicht vollständig geladen werden.
      </Header>
      <p>Ein Fehler ist aufgetreten: {message}</p>
      <p>
        Unsere Entwickler wurden bereits informiert, bitte versuchen Sie es
        später nochmals oder <Link to="/contact">kontaktieren Sie uns</Link> in
        dringenden Fällen.
      </p>
    </div>
  )
}
