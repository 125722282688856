// @flow

import * as React from "react"
import { Container, Grid, Header, Segment, Image } from "semantic-ui-react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Link } from "react-router-dom"
import LazyLoad from "react-lazyload"

import { selectors, actionCreators } from "../../modules"

import step1 from "../../styles/images/fixprice-step1.png"
import step2 from "../../styles/images/fixprice-step2.png"
import step3 from "../../styles/images/fixprice-step3.png"
import step4 from "../../styles/images/fixprice-step4.png"

import "./HowItWorks.css"

import Meta from "../../components/Meta"
import CatalogOverview from "../../components/CatalogOverview"
import MainLayout from "../../layouts/MainLayout"
import Section from "../../components/Section"

export type Props = {
  navigation: Navigation,
  templates: Array<Template>,
  actions: {
    fetchTemplates: () => ApiCall,
  },
}

class HowItWorksFixprices extends React.Component<Props, void> {
  static fetchData(params: {}, dispatch: Dispatch) {
    return Promise.all([dispatch(actionCreators.fetchTemplates())])
  }

  componentDidMount() {
    if (this.props.templates.length === 0) {
      this.props.actions.fetchTemplates()
    }
  }

  render() {
    const { navigation, templates } = this.props
    const title = "LegalGo Fixpreisangebote"
    const description =
      "Massgeschneiderte Lösungen - einfach, sicher und ohne versteckten Kosten"

    return (
      <MainLayout className="HowItWorks">
        <Meta title={title} description={description} />
        <Section as={Container} color="white">
          <Grid stackable>
            <Grid.Row>
              <Header as="h1" content="So funktioniert LegalGo" />
            </Grid.Row>
            <Grid.Row>
              <Header
                as="h2"
                content="Massgeschneiderte Lösungen - einfach, sicher und ohne versteckten Kosten."
              />
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3}>
                <LazyLoad height="160px" once>
                  <Image src={step1} />
                </LazyLoad>
              </Grid.Column>
              <Grid.Column width={13}>
                <Segment stacked>
                  <Header as="h3">Fixpreisangebot auswählen</Header>
                  <p>
                    Unsere Fixpreisangebote lassen sich einfach erklären: Sie
                    erhalten eine Anwaltsdienstleistung zum vordefinierten,
                    verbindlichen Preis – transparent und ohne versteckten
                    Kosten und somit ohne unliebsamen Überraschungen. Wählen Sie
                    eines unserer Fixpreisangebote unter der Rubrik{" "}
                    <Link to="/private">für Private</Link> oder{" "}
                    <Link to="/private">für Unternehmen</Link> aus («Anfrage
                    starten» klicken).
                  </p>
                  <p>
                    Unser Sortiment an Fixpreisangeboten deckt diverse Gebiete
                    aus dem Privat- und Geschäftsalltag ab und wird ständig
                    erweitert. Fehlt Ihnen Fixpreisangebot? Dann zögern Sie
                    nicht uns zu{" "}
                    <a href="mailto:info@legalgo.ch">kontaktieren</a>: Gerne
                    erweitern wir unser Angebot nach Ihren Vorschlägen und
                    Bedürfnissen! Es ist das erklärte Ziel von LegalGo, unser
                    Produktesortiment ständig zu erweitern. Abonnieren Sie daher
                    unseren Newsletter, um stets über unsere neusten Angebote
                    informiert zu werden.
                  </p>
                  <p>Dies sind unsere derzeitigen Fixpreisangebote:</p>
                  <CatalogOverview
                    navigation={navigation}
                    templates={templates}
                  />
                </Segment>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3}>
                <LazyLoad height="160px" once>
                  <Image src={step2} />
                </LazyLoad>
              </Grid.Column>
              <Grid.Column width={13}>
                <Segment stacked>
                  <Header as="h3">Fragen beantworten</Header>
                  <p>
                    Füllen Sie das Kontaktformular und die Fragen im
                    Eingabeformular aus, nachdem Sie Ihr gewünschtes
                    Fixpreisangebot ausgewählt haben. Mit dem Formular soll der
                    mit Ihnen zusammenarbeitende Rechtsanwalt sich einen ersten
                    Überblick über Ihre Situation und Ihre Bedürfnisse
                    verschaffen können, bevor er Sie im Rahmen einer kostenlosen
                    Erstberatung kontaktiert und das weitere Vorgehen mit Ihnen
                    bespricht.
                  </p>
                  <p>
                    Beantworten Sie die Fragen im Kontaktformular bzw. in der
                    Eingabemaske so gut es geht und senden Sie das Formular ab.
                    Sollten Sie eine Frage nicht beantworten können, wird der
                    zuständige Anwalt dies im Rahmen der späteren Erstberatung
                    direkt mit Ihnen klären.
                  </p>
                </Segment>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3}>
                <LazyLoad height="160px" once>
                  <Image src={step3} />
                </LazyLoad>
              </Grid.Column>
              <Grid.Column width={13}>
                <Segment stacked>
                  <Header as="h3">Kostenlose Erstberatung</Header>
                  <p>
                    Innerhalb eines Werktages nachdem Sie das Kontaktformular
                    abgeschickt haben, wird sich einer unserer Rechtsanwälte bei
                    Ihnen melden. Basierend auf Ihren Angaben werden mögliche
                    Lösungswege sowie das weitere Vorgehen in Ihrem Fall
                    besprochen. Diese Erstberatung erfolgt{" "}
                    <strong>kostenlos</strong>.
                  </p>
                </Segment>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3}>
                <LazyLoad height="160px" once>
                  <Image src={step4} />
                </LazyLoad>
              </Grid.Column>
              <Grid.Column width={13}>
                <Segment stacked>
                  <Header as="h3">Bezahlung und Leistung</Header>
                  <p>
                    Die kostenlose Erstberatung hat Sie überzeugt? Erst jetzt
                    bezahlen Sie das gewünschte Fixpreisangebot. Ihr bisher
                    zuständiger Anwalt wird durch LegalGo entsprechend
                    informiert und wird erneut Kontakt mit Ihnen aufnehmen. Ab
                    der Bezahlung des Fixpreises haben Sie Anspruch auf alle
                    unter dem Fixpreisangebot angegebenen Leistungsbestandteile
                    (ersichtlich unter der Informationsseite zu jedem
                    Fixpreisangebot). Der zuständige Anwalt wird in
                    Zusammenarbeit mit Ihnen die gewünschte Rechtsdienstleistung
                    erstellen. Die Leistung erfolgt rechtssicher, transparent
                    und ohne versteckte Kosten zum angegeben Fixpreis.
                  </p>
                  <p>
                    Für die Bezahlung akzeptieren wir im Moment folgende
                    Kreditkarten: VISA / MASTERCARD oder wir senden Ihnen eine
                    Rechnung mit Einzahlungsschein zu.
                  </p>
                </Segment>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3} />
              <Grid.Column width={13}>
                <Segment stacked>
                  <Header as="h3">Haben Sie noch weitere Fragen?</Header>
                  <p>
                    Lesen Sie unsere{" "}
                    <Link to="/faq">Antworten auf häufig gestellte Fragen</Link>{" "}
                    zu den Produkten von LegalGo.
                  </p>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Section>
      </MainLayout>
    )
  }
}

function mapStateToProps(state) {
  return {
    navigation: selectors.getNavigation(state),
    templates: selectors.getFixpriceTemplates(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HowItWorksFixprices)
