// @flow

import * as React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Loader, Header, Card } from "semantic-ui-react"
import type { Match } from "react-router-dom"

import { actionCreators, selectors } from "../../react-redux-prismic-blog"
import {
  extractFields,
  getFurtherPostsQuery,
} from "../../react-redux-prismic-blog"
import { Teaser, Single, PrismicLoader } from "../../react-redux-prismic-blog"

import ErrorMessage from "../../components/ErrorMessage"
import MainLayout from "../../layouts/MainLayout"
import Meta from "../../components/Meta"
import Section from "../../components/Section"
import { stripHtml } from "../../util/stripHtml"
import config from "../../config/index"
import SocialShareButtons from "../../components/SocialShareButtons"

import type { State, Dispatch } from "../../store/rootReducer"
import type { BlogPostId, BlogPost } from "../../react-redux-prismic-blog"

export type Props = {
  uid: BlogPostId,
  match: Match,
  post: ?BlogPost,
  isFetching: boolean,
  error: ?string,
  actions: {
    fetchSingleBlogPost: (uid: BlogPostId, api: string) => mixed,
  },
}

class SingleBlogPost extends React.Component<Props, void> {
  static fetchData({ uid }: { uid: BlogPostId }, dispatch: Dispatch) {
    return dispatch(actionCreators.fetchSingleBlogPost(uid, config.api.prismic))
  }

  componentDidMount() {
    const { post, uid } = this.props
    if (!post) {
      this.props.actions.fetchSingleBlogPost(uid, config.api.prismic)
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { post, uid } = this.props
    if (prevProps.uid === uid) {
      return
    }

    if (!post) {
      this.props.actions.fetchSingleBlogPost(uid, config.api.prismic)
    }
  }

  render() {
    const { uid, match, post, isFetching, error } = this.props
    const showLoader = (isFetching || !post) && !error

    return (
      <MainLayout className="Blog">
        <Meta
          title={post ? post.header : "Blog"}
          image={post ? post.image : null}
          description={
            post
              ? stripHtml(post.summary)
              : "News und Aktuelles rund um LegalGo"
          }
        />
        <Section verticalAlign="top" color="white">
          <div className="SingleBlogPost">
            <div>
              {showLoader ? (
                <Loader active={true} inline="centered" size="large" />
              ) : error != null ? (
                <ErrorMessage errors={{ response: error }} />
              ) : post != null ? (
                <div>
                  <Single
                    newsIndexUrl={config.route.blog.index}
                    footer={<SocialShareButtons buttonsOnly />}
                    author={post.author}
                    category={post.category}
                    content={post.content}
                    date={post.date}
                    header={post.header}
                    image={post.image}
                    summary={post.summary}
                    uid={post.uid}
                    url={post.url}
                  />
                  <PrismicLoader
                    url={config.api.prismic}
                    query={getFurtherPostsQuery(uid)}
                    callback={({ results }) =>
                      results.map(extractFields(match))
                    }
                    renderLoaded={({ response }) => (
                      <div>
                        <Header
                          as="h2"
                          content="Weitere Beiträge im LegalGo Blog"
                        />
                        <Card.Group
                          className="more-blogs"
                          itemsPerRow="3"
                          doubling
                        >
                          {response.map(props => (
                            <Teaser {...props} />
                          ))}
                        </Card.Group>
                      </div>
                    )}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </Section>
      </MainLayout>
    )
  }
}

export type OwnProps = {
  match: Match,
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  // $FlowFixMe
  actions: bindActionCreators(actionCreators, dispatch),
})

function mapStateToProps(
  state: State,
  ownProps: OwnProps
): ReduxProps<Props, typeof mapDispatchToProps> {
  const { uid } = ownProps.match.params
  if (uid == null) {
    throw new Error("Missing News Id in Path")
  }
  return {
    uid,
    match: ownProps.match,
    post: selectors.getBlogPost(state, uid),
    isFetching: selectors.isFetchingBlogPost(state),
    error: selectors.getFetchBlogPostError(state, uid),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleBlogPost)
