// @flow

import * as React from "react"
import { Grid, Header, Image, List, Responsive } from "semantic-ui-react"
import type { Match } from "react-router-dom"

import MainLayout from "../../layouts/MainLayout"
import Section from "../../components/Section"

import kevin from "../../styles/images/kevin-kleger.jpg"
import mirko from "../../styles/images/mirko-stocker.jpg"
import bleuer from "../../styles/images/daniel-bleuer.jpg"

//import facebook from "../../styles/images/facebook.png"
//import twitter from "../../styles/images/twitter.png"
import linkedin from "../../styles/images/linkedin.png"
import Meta from "../../components/Meta"

const Person = ({ title, name, image, body, linkedinUrl }) => {
  return (
    <Grid>
      <Grid.Column computer={3} largeScreen={3} tablet={3} mobile={6}>
        <Image src={image} size="small" circular floated="right" />
        <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
          <Image
            as="a"
            href={linkedinUrl}
            target="_blank"
            rel="noopener noreferrer"
            floated="right"
            src={linkedin}
            size="mini"
          />
        </Responsive>
      </Grid.Column>
      <Grid.Column computer={8} largeScreen={8} tablet={8} mobile={10}>
        <Header as="h3">
          <Header.Subheader content={title} />
          {name}
        </Header>
        {body}
      </Grid.Column>
      <Responsive
        as={Grid.Column}
        computer={4}
        largeScreen={4}
        tablet={4}
        minWidth={Responsive.onlyTablet.minWidth}
      >
        <Image
          as="a"
          href={linkedinUrl}
          target="_blank"
          rel="noopener noreferrer"
          src={linkedin}
          size="mini"
        />
      </Responsive>
    </Grid>
  )
}

const Contact = ({ match }: { match: Match }) => (
  <MainLayout className="Contact">
    <Meta description="Kontakt" />
    <Section narrow color="white">
      <Header as="h2" content="Kontakt" />
      <p>
        Das interdisziplinäre Team von LegalGo ist professionell verwurzelt in
        den Bereichen Wirtschaft, Recht und Technik. Es ist unser ständiges
        Bestreben und unsere Vision technische sowie rechtliche Expertise
        optimal zusammenzuführen, um den Zugang zum Rechtsdienstleistungsmarkt
        für Sie konsumentenfreundlicher zu gestalten.
      </p>
      <p>
        LegalGo ermöglicht Ihnen einen vereinfachten Zugang zu professionellen
        sowie transparenten Rechtsdienstleistungen – zu guten Konditionen und
        mit jederzeitiger Kostenkontrolle.
      </p>
      <Header as="h2" content="Das Team" />
      <Person
        title="Rechtsanwalt"
        name="Kevin Kleger"
        image={kevin}
        linkedinUrl="https://www.linkedin.com/in/kevin-kleger-94a733127/"
        body={
          <p>
            Kevin Kleger studierte an den Universitäten Zürich sowie St. Gallen
            und schloss mit einem Master in Recht seine Studien ab. Kevin Kleger
            ist in der Schweiz zugelassener Rechtsanwalt und öffentlicher Notar
            im Kanton St. Gallen. Als Rechtsanwalt berät und vertritt er seine
            Klienten, national und international, vorwiegend in den Bereichen
            Handelsrecht, Vertragsrecht, Sportrecht, Strafrecht und
            Erb-/Familienrecht.
          </p>
        }
      />
      <Person
        title="Rechtsanwalt"
        name="Daniel Bleuer"
        image={bleuer}
        linkedinUrl="https://www.linkedin.com/in/daniel-bleuer-62b43273/"
        body={
          <p>
            Daniel Bleuer studierte an der Universität St. Gallen und schloss
            mit einem Master in Wirtschaft und Recht seine Studien ab. Daniel
            Bleuer ist in der Schweiz zugelassener Rechtsanwalt und öffentlicher
            Notar im Kanton St. Gallen. Als Rechtsanwalt berät und vertritt er
            seine Klienten, national und international, vorwiegend in den
            Bereichen Handelsrecht, Vertragsrecht (Arbeitsrecht, Mietrecht,
            etc.), Kunstrecht, Erbrecht, Scheidungsrecht und Strafrecht.
          </p>
        }
      />
      <Person
        title="M.Sc. Eng."
        name="Mirko Stocker"
        image={mirko}
        linkedinUrl="https://www.linkedin.com/in/misto/"
        body={
          <div>
            <p>
              Mirko Stocker hat 2010 sein Informatikstudium an der HSR
              Hochschule für Technik in Rapperswil mit einem Master
              abgeschlossen und arbeitet seither als Softwareentwickler und
              Produktmanager. Seit 2015 unterrichtet er an der HSR
              Android-Entwicklung, Cloud Solutions, Programmiersprachen sowie
              Web Engineering und Design. Nebenbei ist er als Prüfungsexperte
              für Informatiker im Kanton St. Gallen unterwegs.
            </p>
            <p>
              Als LegalGo Mitbegründer ist Mirko Stocker für die Entwicklung und
              den Betrieb der Plattform zuständig.
            </p>
            <p>
              Mirko Stocker ist erreichbar unter{" "}
              <a href="mailto:stocker@legalgo.ch">stocker@legalgo.ch</a>.
            </p>
          </div>
        }
      />
    </Section>
    <Section narrow color="white">
      <List>
        <List.Item header="LegalGo GmbH" />
        <List.Item content="Dufourstrasse 121" />
        <List.Item content="9000 St. Gallen" />
      </List>
      <List>
        <List.Item
          content="info@legalgo.ch"
          as="a"
          href="mailto:info@legalgo.ch"
        />
      </List>
      <List>
        <List.Item content="+41 71 272 26 56" />
      </List>
      <List>
        <List.Item header="Handelsregister des Kantons St. Gallen" />
        <List.Item content="Unternehmens-Identifikationsnummer" />
      </List>
      <List>
        <List.Item content="(UID): CHE-412.470.550" />
      </List>
      <List>
        <List.Item content="Handelsregister-Nummer:" />
        <List.Item content="CH-320.4.082.470-7" />
      </List>
      <List>
        <List.Item content="IBAN: CH51 0025 4254 1800 9001 L" />
        <List.Item content="BIC: UBSWCHZH80A" />
      </List>
    </Section>
  </MainLayout>
)

export default Contact
