// @flow
import React from "react"
import { Loader } from "semantic-ui-react"
import loadable from "@loadable/component"

export default loadable(
  () => import(/* webpackChunkName: "experts" */ "./Experts"),
  {
    render: ({ Component, loading, ownProps }) => {
      if (loading) {
        return <Loader active size="large" />
      }
      return <Component {...ownProps} />
    },
  }
)

export const LazyExpertRecommendation = loadable(
  () =>
    import(/* webpackChunkName: "expertrecommendation" */ "./containers/ExpertRecommendation"),
  {
    render: ({ Component, loading, ownProps }) => {
      if (loading) {
        return <Loader active size="large" />
      }
      return <Component {...ownProps} />
    },
  }
)
