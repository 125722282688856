// @flow

import * as React from "react"
import { Button } from "semantic-ui-react"

export type Props = {
  content: string,
  size?: string,
  fluid?: boolean,
  onClick: (event: Event) => void,
  loading: boolean,
}

export default ({ content, size, fluid, onClick, loading }: Props) => {
  return (
    <Button
      data-test="create-fixprice-button"
      className="CreateButton"
      size={size}
      color={"yellow"}
      onClick={onClick}
      content={content}
      loading={loading}
      disabled={loading}
      icon="right arrow"
      labelPosition="right"
      fluid={fluid}
    />
  )
}
