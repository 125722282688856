// @flow

import * as React from "react"
import { Header } from "semantic-ui-react"
import type { Match } from "react-router-dom"

import MainLayout from "../../layouts/MainLayout"
import Section from "../../components/Section"
import Meta from "../../components/Meta"
import FaqContent from "./components/FaqContent"

export default ({ match }: { match: Match }) => (
  <MainLayout className="FAQ">
    <Meta
      title="LegalGo Verträge und Rechtsdokumente "
      description="Mit LegalGo in wenigen Schritten zu Ihrem massgeschneiderten Rechtsdokument."
    />

    <Section color="white">
      <Header as="h1" content="Haben Sie eine Frage zu LegalGo?" />
      <FaqContent />
    </Section>
  </MainLayout>
)
