// @flow

import * as React from "react"
import { Loader, Message, Card } from "semantic-ui-react"
import { getApi } from "prismic-javascript"
import { parse } from "query-string"
import type { Match, Location } from "react-router-dom"

import Single from "./components/Single"
import Excerpt from "./components/Excerpt"
import {
  extractFields,
  getCategoryQuery,
  getAllPostsQuery,
} from "./utils/utils"

import type { BlogPostDto, BlogPostId } from "./modules/NewsModule/reducer"
import PrismicLoader from "./PrismicLoader"

const LargeLoader = ({ loading = true }: { loading?: boolean }) => (
  <Loader active={loading} inline="centered" size="large" />
)

export class PreviewPost extends React.Component<
  { location: Location, match: Match, api: string },
  { loading: boolean, result: ?BlogPostDto }
> {
  state = {
    loading: true,
    result: null,
  }

  componentDidMount = () => {
    const { token } = parse(this.props.location.search)

    getApi(this.props.api).then(api =>
      api
        .previewSession(token, doc => doc)
        .then(result => {
          this.setState({ loading: false, result })
        })
    )
  }

  render() {
    const { match } = this.props
    const { loading, result } = this.state
    if (loading || !result) {
      return <LargeLoader />
    }
    const fields = extractFields(match)(result)
    return (
      <div>
        <Message warning>
          <p>
            Achtung, dies ist nur eine Vorschau! Der Beitrag wird erst nach der
            Veröffentlichung auf der Webseite erscheinen.
          </p>
        </Message>
        <Single newsIndexUrl="/" {...fields} footer={null} />
      </div>
    )
  }
}

export const AllPosts = ({
  match,
  location,
  api,
}: {
  match: Match,
  location: Location,
  api: string,
}) => {
  const { category } = parse(location.search)

  const AllPosts = ({ categoryId }: { categoryId?: BlogPostId }) => (
    <PrismicLoader
      url={api}
      query={getAllPostsQuery(categoryId)}
      callback={({ results }) => results.map(extractFields(match))}
      renderLoading={() => <LargeLoader loading />}
      renderLoaded={({ response }) => (
        <div>
          <Card.Group stackable itemsPerRow="2">
            {response.map(props => (
              <Excerpt {...props} />
            ))}
          </Card.Group>
        </div>
      )}
    />
  )

  if (category) {
    return (
      <PrismicLoader
        url={api}
        query={getCategoryQuery(category)}
        callback={({ results }) => results[0].id}
        renderLoading={() => <LargeLoader loading />}
        renderLoaded={({ response: id }) => <AllPosts categoryId={id} />}
      />
    )
  } else {
    return <AllPosts />
  }
}
