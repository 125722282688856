// @flow

import { isApiCallInProgress, getApiCallFailure } from "../ApiModule/reducer"

import type { State as ApplicationState, Action } from "../../store/rootReducer"

export type State = Navigation

const initialState = []

export default function reduce(
  state: State = initialState,
  action: Action
): State {
  switch (action.type) {
    case "FETCH_NAVIGATION_SUCCESS":
      return action.response
    default:
      return state
  }
}

// reselect?

export const getNavigation = (
  state: ApplicationState
): Array<PrimaryNavigation> => {
  return state.common.navigation
}

export const getSpecialties = (state: ApplicationState): Array<Specialty> => {
  const children = state.common.navigation.map(({ children }) => children)
  return []
    .concat(...children)
    .map(({ title: name, path: id }) => ({ name, id }))
}

export const getPrimaryNavigation = (
  state: ApplicationState,
  primaryCategory: ?string
): ?PrimaryNavigation => {
  return state.common.navigation.find(child => child.path === primaryCategory)
}

export const getPrimaryCategoryForOffering = (
  state: ApplicationState,
  offering: Template
): ?PrimaryNavigation => {
  return state.common.navigation.find(({ children: secondary }) =>
    secondary.find(({ offerings }) => offerings.indexOf(offering.id) >= 0)
  )
}

export const isFetchingNavigation = (state: ApplicationState): boolean => {
  return isApiCallInProgress(state, "FETCH_NAVIGATION_REQUEST")
}

export const getFetchingNavigationErrors = (state: ApplicationState) => {
  return getApiCallFailure(state, "FETCH_NAVIGATION_REQUEST")
}

export const getFetchContractOrFixpriceRequestErrors = (
  state: ApplicationState
) => {
  return getApiCallFailure(state, "FETCH_CONTRACT_OR_FIXPRICEREQUEST_REQUEST")
}
