// @flow

import * as React from "react"
import type { Match, Location } from "react-router-dom"
import { Header } from "semantic-ui-react"
import { AllPosts } from "../../react-redux-prismic-blog"

import MainLayout from "../../layouts/MainLayout"
import Meta from "../../components/Meta"
import Section from "../../components/Section"

import "./Blog.css"
import config from "../../config/index"

const News = (props: { match: Match, location: Location }) => {
  return (
    <MainLayout className="Blog">
      <Meta title="Blog" />
      <Section verticalAlign="top" color="white">
        <Header as="h1">
          Neuigkeiten
          <Header.Subheader>
            News und Aktuelles rund um LegalGo
          </Header.Subheader>
        </Header>
        <AllPosts
          api={config.api.prismic}
          match={props.match}
          location={props.location}
        />
      </Section>
    </MainLayout>
  )
}

export default News
