//@flow
import * as React from "react";
import PrismicIO from "prismic-javascript";
import type { APIOptions, Response, API, Predicates } from "prismic-javascript";

type State = {
  loading: boolean,
  error: ?Error,
  response: Response
};

type Props<T, U> = {
  url: string,
  apiOptions: APIOptions,
  query: Array<Array<Predicates> | APIOptions>,
  callback: T => U,
  renderLoading: State => React.Node,
  renderLoaded: State => React.Node,
  renderError: State => React.Node
};

export default class PrismicLoader<T, U> extends React.Component<
  Props<T, U>,
  State
> {
  static defaultProps = {
    url: "",
    apiOptions: {},
    renderLoading: () => null,
    renderLoaded: () => null,
    renderError: () => null,
    query: () => null,
    callback: (response: T) => null
  };

  constructor(props: Props<T, U>) {
    super(props);
    this.state = {
      loading: true,
      error: null,
      response: {}
    };
  }

  componentDidMount = () => {
    const { url, apiOptions, query, callback } = this.props;
    PrismicIO.api(url, apiOptions)
      .then((api: API) => api.query(...query))
      .then((response: Response) => {
        this.setState((state: State) => ({
          ...state,
          loading: false,
          response: callback(response)
        }));
      })
      .catch((err: Error) => {
        this.setState((state: State) => ({
          ...state,
          loading: false,
          error: err
        }));
      });
  };

  render() {
    if (this.state.loading) {
      return <div>{this.props.renderLoading(this.state)}</div>;
    }
    if (this.state.error != null) {
      return <div>{this.props.renderError(this.state)}</div>;
    }
    if (!this.state.loading) {
      return <div>{this.props.renderLoaded(this.state)}</div>;
    }
    return null;
  }
}
