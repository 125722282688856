// @flow

import { isApiCallInProgress, getApiCallFailure } from "../ApiModule/reducer"
import type { State as ApplicationState, Action } from "../../store/rootReducer"

export type State = {
  +contract: ?Contract,
  +reminderSent: boolean,
  +completedCount: number,
  +isRedirectingToPayment: boolean,
  +isSendingConfirmation: boolean,
} // store by id? should we allow multiple contracts at the same time?

const initialState = {
  contract: null,
  reminderSent: false,
  completedCount: 340, //better than showing 0!
  isRedirectingToPayment: false,
  isSendingConfirmation: false,
}

export default function reduce(
  state: State = initialState,
  action: Action
): State {
  switch (action.type) {
    case "DISCOUNT_CODE_SUCCESS":
    case "CUSTOMER_DATA_SUCCESS":
    case "CUSTOMER_CONTACT_DATA_SUCCESS":
    case "COMPLETE_CONTRACT_SUCCESS":
    case "FETCH_CONTRACT_SUCCESS":
    case "CREATE_NEW_CONTRACT_SUCCESS":
    case "COPY_CONTRACT_SUCCESS":
    case "ORDER_EXTRA_ADD_SUCCESS":
    case "ORDER_EXTRA_REMOVE_SUCCESS":
    case "REOPEN_CONTRACT_SUCCESS":
    case "CHANGE_CONTRACT_STATE_SUCCESS":
    case "START_ORDER_SUCCESS":
    case "CANCEL_ORDER_SUCCESS":
      return {
        ...state,
        contract: action.response,
      }
    case "CREATE_NEW_FIXPRICEREQUEST_SUCCESS":
      return {
        ...state,
        contract: undefined,
      }
    case "AUTOSAVE_CONTRACT_SUCCESS":
      return {
        ...state,
        contract: {
          ...state.contract,
          formValues: action.formData,
        },
      }
    case "CONTRACT_REMINDER_SUCCESS":
      return {
        ...state,
        reminderSent: true,
      }
    case "FETCH_CONTRACT_OR_FIXPRICEREQUEST_SUCCESS": {
      if (action.response.type === "Contract") {
        return {
          ...state,
          contract: action.response,
        }
      } else {
        return state
      }
    }
    case "GET_COMPLETED_COUNT_SUCCESS": {
      return {
        ...state,
        completedCount: action.response,
      }
    }
    case "GET_COMPLETED_COUNT_FAILURE": {
      return {
        ...state,
        completedCount: 185,
      }
    }
    case "REDIRECTING_TO_PAYMENT": {
      return {
        ...state,
        isRedirectingToPayment: true,
      }
    }
    case "SEND_CONFIRMATION_EMAIL_REQUEST": {
      return {
        ...state,
        isSendingConfirmation: true,
      }
    }
    case "SEND_CONFIRMATION_EMAIL_SUCCESS": {
      return {
        ...state,
        isSendingConfirmation: false,
      }
    }
    default:
      return state
  }
}

export const getContract = (state: ApplicationState): ?Contract => {
  return state.common.contracts.contract
}

export const getContractId = (state: ApplicationState): ?ContractId => {
  return state.common.contracts.contract
    ? state.common.contracts.contract.id
    : null
}

export const isReminderSent = (state: ApplicationState): boolean => {
  return state.common.contracts.reminderSent
}

export const isSendingConfirmation = (state: ApplicationState): boolean => {
  return state.common.contracts.isSendingConfirmation
}

export const getNumberOfCompletedContracts = (
  state: ApplicationState
): number => {
  return state.common.contracts.completedCount
}

export const isRedirectingToPayment = (state: ApplicationState): boolean => {
  return state.common.contracts.isRedirectingToPayment
}

export const isFetchingContract = (state: ApplicationState): boolean => {
  return isApiCallInProgress(state, "FETCH_CONTRACT_REQUEST")
}

export const isCopyingContract = (state: ApplicationState): boolean => {
  return isApiCallInProgress(state, "COPY_CONTRACT_REQUEST")
}

export const getFetchContractErrors = (state: ApplicationState) => {
  return getApiCallFailure(state, "FETCH_CONTRACT_REQUEST")
}

export const isCreatingContract = (state: ApplicationState): boolean => {
  return isApiCallInProgress(state, "CREATE_NEW_CONTRACT_REQUEST")
}

export const getCreateContractErrors = (state: ApplicationState) => {
  return getApiCallFailure(state, "CREATE_NEW_CONTRACT_REQUEST")
}

export const isAddingCustomerData = (state: ApplicationState): boolean => {
  return isApiCallInProgress(state, "CUSTOMER_DATA_REQUEST")
}

export const addCustomerDataErrors = (state: ApplicationState) => {
  return getApiCallFailure(state, "CUSTOMER_DATA_REQUEST")
}

export const isAddingCustomerContactData = (
  state: ApplicationState
): boolean => {
  return isApiCallInProgress(state, "CUSTOMER_CONTACT_DATA_REQUEST")
}
export const addCustomerContactDataErrors = (state: ApplicationState) => {
  return getApiCallFailure(state, "CUSTOMER_CONTACT_DATA_REQUEST")
}

export const isAddingOrderExtra = (state: ApplicationState): boolean => {
  return isApiCallInProgress(state, "ORDER_EXTRA_ADD_REQUEST")
}

export const isRemovingOrderExtra = (state: ApplicationState): boolean => {
  return isApiCallInProgress(state, "ORDER_EXTRA_REMOVE_REQUEST")
}

export const isAddingDiscountCode = (state: ApplicationState): boolean => {
  return isApiCallInProgress(state, "DISCOUNT_CODE_REQUEST")
}

export const addDiscountCodeErrors = (state: ApplicationState) => {
  return getApiCallFailure(state, "DISCOUNT_CODE_REQUEST")
}

export const isSendingReminder = (state: ApplicationState): boolean => {
  return isApiCallInProgress(state, "CONTRACT_REMINDER_REQUEST")
}

export const getSendReminderErrors = (state: ApplicationState) => {
  return getApiCallFailure(state, "CONTRACT_REMINDER_REQUEST")
}

export const isStartingOrder = (state: ApplicationState): boolean => {
  return isApiCallInProgress(state, "START_ORDER_REQUEST")
}

export const getStartOrderErrors = (state: ApplicationState) => {
  return getApiCallFailure(state, "START_ORDER_REQUEST")
}
