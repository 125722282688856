// @flow

import * as React from "react"
import {
  Message,
  Icon,
  Modal,
  Button,
  Form,
  Checkbox,
  TextArea,
} from "semantic-ui-react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import { actionCreators, selectors } from "../modules"

export type Props = {
  // flowlint-next-line deprecated-type:off
  as: *,
  isSending: boolean,
  errors: ?ApiError,
  children: Array<React.Node>,
  actions: {
    sendSuggestion: (email: string, suggestion: string) => mixed,
    newsletterSignup: (email: string) => mixed,
  },
}

export type State = {
  open: boolean,
  suggestion: string,
  email: string,
  newsletter: boolean,
}

class SuggestContent extends React.Component<Props, State> {
  // flowlint-next-line deprecated-type:off
  static defaultProps: *
  state = {
    open: false,
    suggestion: "",
    email: "",
    newsletter: false,
  }

  handleOpen = (event: Event) => {
    event.preventDefault()
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  componentWillReceiveProps(nextProps: Props) {
    if (this.props.isSending && !nextProps.isSending && !nextProps.errors) {
      this.handleClose()
    }
  }

  handleSubmit = (event: Event) => {
    event.preventDefault()
    if (this.state.newsletter) {
      this.props.actions.newsletterSignup(this.state.email)
    }
    this.props.actions.sendSuggestion(this.state.email, this.state.suggestion)
  }

  handleEmailChanged = e => {
    this.setState({ email: e.target.value })
  }

  handleSuggestionChanged = e => {
    this.setState({ suggestion: e.target.value })
  }

  handleNewsletterChanged = () => {
    this.setState(({ newsletter }) => ({ newsletter: !newsletter }))
  }

  render() {
    const { isSending, errors, as, actions, children, ...rest } = this.props

    const Trigger = React.createElement(
      as,
      {
        ...rest,
        onClick: this.handleOpen,
      },
      ...children
    )

    return (
      <Modal
        trigger={Trigger}
        open={this.state.open}
        onClose={this.handleClose}
        closeIcon
      >
        <Modal.Header>
          <Icon name="question" color="teal" />
          Welche Inhalte fehlen auf LegalGo? Helfen Sie uns!
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>
              Wir tun unser bestes ein breites Angebot anzubieten, da wir erst
              gestartet sind fehlen aber noch viele Dokumente und
              Vertragsvorlagen.{" "}
            </p>
            <p>
              Helfen Sie uns bei der Priorisierung und schreiben Sie uns was
              Ihnen fehlt:
            </p>
            <Form onSubmit={this.handleSubmit} error={!!errors}>
              <TextArea
                onChange={this.handleSuggestionChanged}
                value={this.state.suggestion}
                name="suggestion"
                placeholder=""
              />
              <p>
                <em>
                  Sie können uns auch jederzeit über{" "}
                  <a href="mailto:info@legalgo.ch">info@legalgo.ch</a> erreichen
                </em>
                .
              </p>
              <Form.Input
                onChange={this.handleEmailChanged}
                value={this.state.email}
                label="Ihre Email-Adresse"
                name="email"
              />
              <Form.Field
                control={Checkbox}
                onChange={this.handleNewsletterChanged}
                checked={this.state.newsletter}
                name="newsletter"
                toggle
                label={
                  <label>
                    Ich möchte den Newsletter abonnieren um über neue Angebote
                    informiert zu werden.
                  </label>
                }
              />
              <Message
                error
                header="Es ist ein Problem aufgetreten:"
                content={`${errors ? errors.status || "" : ""}`}
              />
              <Button
                fluid
                type="submit"
                color="teal"
                loading={isSending}
                disabled={
                  isSending ||
                  (!this.state.email || this.state.email.length <= 0)
                }
              >
                Vorschlag abschicken
              </Button>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    )
  }
}

function mapStateToProps(state, { as }) {
  return {
    as,
    isSending: selectors.isSendingSuggestion(state),
    errors: selectors.getSendSuggestionErrors(state),
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SuggestContent)
