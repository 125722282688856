// @flow

import * as React from "react"
import { Button } from "semantic-ui-react"

export default class StartChatButton extends React.Component<{}> {
  handleChatRequest = () => {
    if (typeof window.purechatApi === "object" && window.purechatApi.set) {
      window.purechatApi.set("chatbox.expanded", true)
    } else {
      setTimeout(() => {
        window.purechatApi.on("chatbox:ready", () =>
          window.purechatApi.set("chatbox.expanded", true)
        )
      }, 3000)
    }
  }

  render() {
    return (
      <Button
        size="large"
        icon="chat"
        labelPosition="right"
        content="Haben Sie eine Frage?"
        onClick={this.handleChatRequest}
      />
    )
  }
}
