// @flow

import * as React from "react"
import { Divider, Header, Card } from "semantic-ui-react"
import SuggestionCard from "./SuggestionCard"

export type Props = {
  title: string,
  subtitle: string,
  path: string,
  children: React.Node,
  isFirst?: boolean,
  showSuggestion?: boolean,
}

export default function CategorySection({
  title,
  subtitle,
  path,
  children,
  isFirst = false,
  showSuggestion = false,
}: Props) {
  return (
    <div>
      {!isFirst && <Divider section />}
      <Header as="h2" id={path}>
        {title}
        <Header.Subheader>{subtitle}</Header.Subheader>
      </Header>
      <Card.Group>
        {children}
        <SuggestionCard />
      </Card.Group>
    </div>
  )
}
