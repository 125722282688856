// @flow

/*
const claimCreated = ({ claimId }: { claimId: ClaimId }) => {
  return {
    hitType: "event",
    eventCategory: "Claim",
    eventAction: "created",
    eventLabel: claimId,
  }
}

const claimSubmitted = ({ claimId }: { claimId: ClaimId }) => {
  return {
    hitType: "event",
    eventCategory: "Claim",
    eventAction: "submitted",
    eventLabel: claimId,
  }
}*/

export default {}
