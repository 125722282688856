// @flow

import * as React from "react"
import { Fragment } from "react"
import { Header } from "semantic-ui-react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import MainLayout from "../../layouts/MainLayout"
import Section from "../../components/Section"

import { selectors, actionCreators } from "../../modules"

import ErrorMessage from "../../components/ErrorMessage"
import "./ProductsOverview.css"
import reactGa from "react-ga"
import CategorySection from "./components/CategorySection"
import TemplateCard from "./components/TemplateCard"
import Meta from "../../components/Meta"

export type Props = {
  templates: Array<Template>,
  navigation: ?PrimaryNavigation,
  primaryCategory: string,
  isLoading: boolean,
  errors: ?ApiError,
  actions: {
    fetchTemplates: () => ApiCall,
  },
}

class ProductsOverview extends React.Component<Props, void> {
  static fetchData(params: {}, dispatch: Dispatch) {
    return dispatch(actionCreators.fetchTemplates())
  }

  componentDidMount() {
    if (this.props.templates.length === 0) {
      this.props.actions.fetchTemplates()
    }
    reactGa.event({
      category: "Page View",
      action: `Viewed ${this.props.primaryCategory}`,
    })
  }

  render() {
    const { navigation, errors, templates } = this.props

    if (errors) {
      return <ErrorMessage error={errors} />
    }

    if (!navigation) {
      return <div />
    }

    const allOffferings = navigation.children.map((category, index) => {
      const offeringsInCategory = category.offerings
        .map(offeringId => templates.find(({ id }) => id === offeringId))
        .filter(Boolean)
      return {
        category,
        offerings: offeringsInCategory,
      }
    })

    if (this.props.primaryCategory === "unternehmen") {
      console.log(allOffferings)
      allOffferings.splice(1, 0, {
        category: { title: "Gründen", path: "gruendungen" },
        offerings: [],
      })
    }

    const categories = allOffferings.map(({ category, offerings }, index) => {
      return (
        <CategorySection
          {...category}
          key={category.path}
          isFirst={index === 0}
          showSuggestion={offerings.length < 4}
        >
          <Fragment>
            {category.title === "Private Vorsorge" && (
              <TemplateCard
                price={59}
                name="Vorsorgeauftrag"
                description={{
                  subtitle:
                    "<p>Erstellen Sie einen individualisierten Vorsorgeauftrag und bestimmen Sie einen oder mehrere Vorsorgebeauftragte für den Fall, dass Sie aufgrund einer Krankheit oder eines Unfalles dereinst nicht mehr selber entscheiden können.</p>",
                }}
                path="/private/vorsorgeauftrag"
              />
            )}
            {category.title === "Gründen" && (
              <TemplateCard
                as="a"
                href="https://gruenden.legalgo.ch"
                price={490}
                kind="Fixprice"
                name="GmbH"
                description={{
                  subtitle:
                    "<p>Zum unschlagbaren Fixpreis gründen wir für Sie Ihre GmbH.</p>",
                }}
              />
            )}
            {category.title === "Gründen" && (
              <TemplateCard
                as="a"
                href="https://gruenden.legalgo.ch"
                price={490}
                kind="Fixprice"
                name="Aktiengesellschaft"
                description={{
                  subtitle:
                    "<p>Zum unschlagbaren Fixpreis gründen wir für Sie Ihre Aktiengesellschaft.</p>",
                }}
              />
            )}
            {offerings.map(offering => {
              const path = `/${navigation.path}/${offering.path}`
              return (
                <TemplateCard
                  key={offering.id}
                  price={offering.price}
                  kind={offering.kind}
                  name={offering.name}
                  description={offering.description || {}}
                  path={path}
                />
              )
            })}
          </Fragment>
        </CategorySection>
      )
    })
    const title = `LegalGo Angebote ${navigation.title}`

    const offerings = allOffferings
      .map(({ category, offerings }) => offerings.map(({ name }) => name))
      .reduce((acc, cur) => acc.concat(cur), [])
      .join(", ")

    const description = `Bei LegalGo finden Sie verschiedenste Angebote ${
      navigation.title
    }: ${offerings}`

    return (
      <MainLayout className="ProductsOverview PrimaryCategoryPage">
        <Meta description={description} title={title} />
        <Section color="white">
          <Header as="h1">Angebote {navigation.title}</Header>
          {categories}
        </Section>
      </MainLayout>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const { pathname } = ownProps.location
  const primaryCategory = pathname.substring(1) // to drop the leading /
  // const secondaryCategory = hash.substring(1) // to drop the leading #
  return {
    primaryCategory,
    templates: selectors.getTemplates(state),
    navigation: selectors.getPrimaryNavigation(state, primaryCategory),
    isLoading:
      selectors.isFetchingTemplates(state) ||
      selectors.isFetchingNavigation(state),
    errors:
      selectors.getFetchingTemplatesErrors(state) ||
      selectors.getFetchingNavigationErrors(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductsOverview)
