// @flow

export function formatDateTime(date: ?Date) {
  if (date && date instanceof Date && isFinite(date)) {
    return date.toLocaleDateString("de-ch", {
      hour: "2-digit",
      minute: "2-digit",
    })
  } else {
    return "-"
  }
}

export function formatDate(date: ?Date) {
  if (date && date instanceof Date && isFinite(date)) {
    return date.toLocaleDateString("de-ch", {})
  } else {
    return "-"
  }
}
