// @flow

import { isApiCallInProgress, getApiCallFailure } from "../ApiModule/reducer"
import type { State as ApplicationState, Action } from "../../store/rootReducer"
import { createSelector } from "reselect"

import { Map } from "immutable"

const initialState = {
  entities: Map(),
}

export type State = {
  +entities: Map<TemplateId, Template>,
}

export default function reduce(
  state: State = initialState,
  action: Action
): State {
  switch (action.type) {
    case "FETCH_TEMPLATES_SUCCESS":
      return {
        ...state,
        entities: Map(action.response.map(t => [t.id, t])),
      }
    default:
      return state
  }
}

export const getTemplates = (state: ApplicationState): Array<Template> => {
  return state.common.templates.entities.toArray()
}

export const getContractTemplates = createSelector(getTemplates, templates => {
  return templates.filter(tpl => tpl.kind === "Contract")
})

export const getFixpriceTemplates = createSelector(getTemplates, templates => {
  return templates.filter(tpl => tpl.kind === "Fixprice")
})

export const getTemplateById = (state: ApplicationState, id: TemplateId) => {
  return state.common.templates.entities.get(id)
}

export const getTemplateByPath = (state: ApplicationState, path: ?string) => {
  return state.common.templates.entities.find(
    template => template.path === path
  )
}

export const isFetchingTemplates = (state: ApplicationState): boolean => {
  return isApiCallInProgress(state, "FETCH_TEMPLATES_REQUEST")
}

export const getFetchingTemplatesErrors = (state: ApplicationState) => {
  return getApiCallFailure(state, "FETCH_TEMPLATES_REQUEST")
}
