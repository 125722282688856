// @flow

import * as React from "react"
import { Header, Grid, Image } from "semantic-ui-react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import MainLayout from "../../layouts/MainLayout"
import Section from "../../components/Section"
import IntroSectionContent from "./components/IntroSectionContent"

import { selectors, actionCreators } from "../../modules"

import contractStep1 from "../../styles/images/contract-step1.png"
import contractStep2 from "../../styles/images/contract-step2.png"
import contractStep3 from "../../styles/images/contract-step3.png"
import contractStep4 from "../../styles/images/contract-step4.png"
import fixpriceStep1 from "../../styles/images/fixprice-step1.png"
import fixpriceStep2 from "../../styles/images/fixprice-step2.png"
import fixpriceStep3 from "../../styles/images/fixprice-step3.png"
import fixpriceStep4 from "../../styles/images/fixprice-step4.png"
import "./Home.css"
import {
  HowContractsWork,
  HowFixpricesWork,
} from "../../components/HowItWorksButtons"
import CatalogOverview from "../../components/CatalogOverview"
import config from "../../config/index"
import FeaturedPostsCarousel from "../News/FeaturedPostsCarousel"
import Partners from "../../components/Partners"

export type Props = {
  navigation: Navigation,
  contractTemplates: Array<Template>,
  fixpriceTemplates: Array<Template>,
  numberOfCompletedContracts: number,
  isLoading: boolean,
  error: ?ApiError,
  actions: {
    fetchTemplates: () => ApiCall,
    fetchNumberOfCompletedContracts: () => ApiCall,
  },
}

class Home extends React.Component<Props, void> {
  static fetchData(params: {}, dispatch: Dispatch) {
    console.log("Fetching Homepage data")
    return Promise.all([
      dispatch(actionCreators.fetchTemplates()),
      dispatch(actionCreators.fetchNumberOfCompletedContracts()),
    ])
  }

  componentDidMount() {
    if (this.props.contractTemplates.length === 0) {
      this.props.actions.fetchTemplates()
    }
    // always do this because it might have changed
    this.props.actions.fetchNumberOfCompletedContracts()
  }

  render() {
    const {
      navigation,
      contractTemplates,
      fixpriceTemplates,
      numberOfCompletedContracts,
    } = this.props
    return (
      <MainLayout className="Home">
        <Section>
          <IntroSectionContent
            numberOfCompletedContracts={numberOfCompletedContracts}
          />
        </Section>
        <Section narrow color="white">
          <Header as="h1" content="Unsere Angebote" />
          <Header as="h2" content="BASIS" />
          <p>
            Sie erstellen Ihre individuelle Rechtsdokumente oder Verträge selber
            online.
          </p>
          <Grid relaxed>
            <Grid.Row
              textAlign="center"
              as={Link}
              to={config.route.infos.howContractsWork}
            >
              <Grid.Column computer="4" tablet="4" mobile="6">
                <Image src={contractStep1} alt="Vorlage auswählen<" />
                <p>
                  Vorlage <br />
                  auswählen
                </p>
              </Grid.Column>
              <Grid.Column computer="4" tablet="4" mobile="6">
                <Image src={contractStep2} alt="Fragen beantworten" />
                <p>
                  Fragen <br />
                  beantworten
                </p>
              </Grid.Column>
              <Grid.Column computer="4" tablet="4" mobile="6">
                <Image src={contractStep3} alt="Dokument herunterladen" />
                <p>
                  Dokument <br />
                  herunterladen
                </p>
              </Grid.Column>
              <Grid.Column computer="4" tablet="4" mobile="6">
                <Image src={contractStep4} alt="Unterschreiben und Go" />
                <p>
                  Unterschreiben <br />
                  und Go
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width="16">
                <p>
                  Einfach ausfüllen, Dokument ausdrucken, unterschreiben und Go.
                </p>
                <div style={{ textAlign: "right" }}>
                  <HowContractsWork content="So funktioniert's im Detail" />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Header as="h2" content="PREMIUM" />

          <p>
            Ein Anwalt erstellt Ihr massgeschneidertes Rechtsdokument oder
            Vertrag zum Fixpreis.
          </p>
          <Grid relaxed>
            <Grid.Row
              textAlign="center"
              as={Link}
              to={config.route.infos.howFixpricesWork}
            >
              <Grid.Column computer="4" tablet="4" mobile="6">
                <Image src={fixpriceStep1} alt="Angebot auswählen" />
                <p>
                  Angebot <br />
                  auswählen
                </p>
              </Grid.Column>
              <Grid.Column computer="4" tablet="4" mobile="6">
                <Image src={fixpriceStep2} alt="Fragen beantworten" />
                <p>
                  Fragen <br />
                  beantworten
                </p>
              </Grid.Column>
              <Grid.Column computer="4" tablet="4" mobile="6">
                <Image src={fixpriceStep3} alt="Kostenlose Erstberatung" />
                <p>
                  Kostenlose <br />
                  Erstberatung
                </p>
              </Grid.Column>
              <Grid.Column computer="4" tablet="4" mobile="6">
                <Image src={fixpriceStep4} alt="Bezahlung und Leistung" />
                <p>
                  Bezahlung und <br />
                  Leistung
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <p>
                  Lösungen nach Mass - einfach, sicher und ohne versteckte
                  Kosten.
                </p>
                <div style={{ textAlign: "right" }}>
                  <HowFixpricesWork content="So funktioniert's im Detail" />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Section>
        <Section
          narrow
          outerColor={["rgba(140,140, 140, 1)", "rgba(200, 200, 200, 1)"]}
          innerColor={["rgba(255, 255, 255, 0.4)", "rgba(255, 255, 255, 0.4)"]}
        >
          <Header style={{ color: "#444" }} as="h1" content="UNSERE PARTNER" />
          <Partners />
        </Section>
        <Section narrow color="white">
          <Header style={{ color: "#444" }} as="h1" content="AKTUELL" />
          <p>Aktuelles rund um LegalGo finden Sie in unserem Blog:</p>
          <FeaturedPostsCarousel />
        </Section>
        <Section
          narrow
          outerColor={["rgba(140,140, 140, 1)", "rgba(200, 200, 200, 1)"]}
          innerColor={["rgba(255, 255, 255, 0.4)", "rgba(255, 255, 255, 0.4)"]}
        >
          <Header as="h1" content="Unsere Rechtsdienstleistungen" />
          <Header as="h2" content="BASIS" />
          <p>
            Sie erstellen Ihre individuelle Rechtsdokumente oder Verträge selber
            online.
          </p>
          <CatalogOverview
            navigation={navigation}
            templates={contractTemplates}
          />
          <Header as="h2" content="PREMIUM" />
          <p>
            Ein Anwalt erstellt Ihr massgeschneidertes Rechtsdokument oder
            Vertrag zum Fixpreis.
          </p>
          <CatalogOverview
            navigation={navigation}
            templates={fixpriceTemplates}
          />
        </Section>
      </MainLayout>
    )
  }
}

function mapStateToProps(state) {
  const navigation = selectors.getNavigation(state)
  const contractTemplates = selectors.getContractTemplates(state)
  const fixpriceTemplates = selectors.getFixpriceTemplates(state)

  return {
    navigation,
    contractTemplates,
    fixpriceTemplates,
    numberOfCompletedContracts: selectors.getNumberOfCompletedContracts(state),
    isLoading: selectors.isFetchingTemplates(state),
    error: selectors.getFetchingTemplatesErrors(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home)
