// @flow
import Teaser from "./components/Teaser"
import Single from "./components/Single"
import PrismicLoader from "./PrismicLoader"
import LatestPost from "./components/LatestPost"
import { AllPosts, PreviewPost } from "./Blog"

import { mkRssFeed } from "./RssFeed"

import { default as reducer, actionCreators, selectors } from "./modules"

import {
  extractFields,
  getFurtherPostsQuery,
  getFeaturedPostsQuery,
  getAllPostsQuery,
} from "./utils/utils"

export type { State, Action } from "./modules"

export type { BlogPost, BlogPostId } from "./modules/NewsModule/reducer"

export {
  AllPosts,
  PreviewPost,
  Teaser,
  Single,
  LatestPost,
  PrismicLoader,
  reducer,
  actionCreators,
  selectors,
  extractFields,
  getFurtherPostsQuery,
  getFeaturedPostsQuery,
  getAllPostsQuery,
  mkRssFeed,
}
