// @flow

import * as React from "react";

export type Props = {
  html: string
};

export default function RawHtmlComponent({ html }: Props) {
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
}
