import { getApi } from "prismic-javascript"
import { getSinglePostQuery, extractFields } from "../../utils/utils"

import type { BlogPostId } from "./reducer"
import type { Action } from ".."
import type { Dispatch as ReduxDispatch } from "redux"

export type Dispatch = ReduxDispatch<Action>

export function fetchSingleBlogPost(uid: BlogPostId, api: string) {
  return (dispatch: Dispatch) => {
    dispatch({ type: "FETCH_BLOG_POST_REQUEST" })
    const [predicates, options] = getSinglePostQuery(uid)
    return getApi(api)
      .then(api => api.query(predicates, options))
      .then(({ results }) => {
        if (results.length < 1) {
          return dispatch({
            type: "FETCH_BLOG_POST_FAILURE",
            uid,
            error: "Beitrag konnte nicht geladen werden",
          })
        } else {
          const post = extractFields()(results[0])
          return dispatch({
            type: "FETCH_BLOG_POST_SUCCESS",
            response: post,
          })
        }
      })
  }
}
