// @flow
import Alert from "react-s-alert"
import { push } from "connected-react-router"

import type {
  Dispatch,
  GetState,
  Services,
} from "../../../../store/rootReducer"
import config from "../../../../config"

export const validatePasswordToken = (token: string) => async (
  dispatch: Dispatch,
  getState: GetState,
  { authApi }: Services
) => {
  try {
    const response = await authApi.validatePasswordToken(token)
    Alert.success(response.description)
  } catch (e) {
    Alert.error(e.response.description)
    dispatch(push(config.route.auth.passwordRecovery))
  }
}

export const resetPassword = (token: string, password: string) => async (
  dispatch: Dispatch,
  getState: GetState,
  { authApi }: Services
) => {
  dispatch({ type: "AUTH_RESET_PASSWORD_REQUESTED" })
  try {
    const response = await authApi.resetPassword(token, { password })
    Alert.success(response.description, { timeout: 30000 })
    dispatch({ type: "AUTH_RESET_PASSWORD_SUCCEEDED" })
    dispatch(push(config.route.auth.signIn))
  } catch (e) {
    dispatch({ type: "AUTH_RESET_PASSWORD_FAILED" })
    switch (e.response.code) {
      case "auth.password.reset.form.invalid":
        Alert.error(e.response.description)
        break
      case "auth.password.reset.token.invalid":
        dispatch(push(config.route.auth.signIn))
        Alert.error(e.response.description)
        break
      default:
        Alert.error(e.response.description)
    }
  }
}
