// @flow
import React from "react"
import { Route, Switch, withRouter } from "react-router-dom"

import routes from "../routes/routes"
import withNavigation from "../containers/withNavigation"
import * as navigationActionCreators from "../modules/NavigationModule/actions"
import Alert from "../components/Alert"
import ApiDebugInfo from "../components/ApiDebugInfo"

export type Props = {
  navigation: Navigation,
  actions: {
    fetchNavigation: () => ApiCall,
  },
}

class App extends React.Component<Props, void> {
  static fetchData(params: {}, dispatch) {
    return dispatch(navigationActionCreators.fetchNavigation())
  }

  componentDidMount() {
    const { navigation, actions } = this.props
    if (navigation.length === 0) {
      actions.fetchNavigation()
    }
  }

  render() {
    return (
      <div className="App">
        <Alert />
        <ApiDebugInfo />
        <Switch>
          {routes.map(({ component: Component, path, ...rest }) => {
            return (
              <Route
                key={path}
                path={path}
                render={props => <Component {...props} />}
                {...rest}
              />
            )
          })}
        </Switch>
      </div>
    )
  }
}

export default withRouter(withNavigation(App))
