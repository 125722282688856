// @flow

import * as React from "react";
import { Segment, Header, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";

import { extractFields, getAllPostsQuery } from "../utils/utils";
import RawHtmlComponent from "./RawHtmlComponent";
import PrismicLoader from "../PrismicLoader";

const Post = ({ header, author, baseUrl, url, date, summary }) => (
  <Segment stacked>
    <Header as="h2">
      <Header.Subheader content="Aktuelle News" />
      <Header.Content>{header}</Header.Content>
    </Header>
    <RawHtmlComponent html={summary} />
    <br />
    <p>
      <Link to={`${baseUrl}${url}`} target="_blank">
        <Icon fitted name="long arrow alternate right" /> Weiterlesen Blog
      </Link>
    </p>
  </Segment>
);

export default ({ api, baseUrl }: { api: string, baseUrl: string }) => (
  <PrismicLoader
    url={api}
    query={getAllPostsQuery(null, 1)}
    callback={({ results }) => results.map(extractFields())}
    renderLoaded={({ response }) =>
      response.map(props => <Post baseUrl={baseUrl} {...props} />)
    }
  />
);
