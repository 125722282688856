// @flow

let runningInE2ETesting = false
try {
  if (
    typeof navigator !== undefined &&
    (navigator.userAgent.indexOf("SELENIUM") > 0 ||
      navigator.userAgent.indexOf("Electron") > 0)
  ) {
    runningInE2ETesting = true
  }
} catch (e) {}

export default {
  // ======================================================
  // Overrides when NODE_ENV === 'test'
  // ======================================================
  test: () => ({
    apiBaseUrl: "http://localhost",
    analytics: false,
  }),

  // ======================================================
  // Overrides when NODE_ENV === 'development'
  // ======================================================
  development: () => ({
    apiBaseUrl:
      process.env.REACT_APP_API_HOST != null
        ? process.env.REACT_APP_API_HOST
        : "http://localhost:9000",
    analytics: false,
  }),

  // ======================================================
  // Overrides when NODE_ENV === 'production'
  // ======================================================
  production: () => ({
    apiBaseUrl:
      process.env.REACT_APP_API_HOST != null
        ? process.env.REACT_APP_API_HOST
        : runningInE2ETesting
          ? "https://api-test.legalgo.ch"
          : "https://api.legalgo.ch",
    analytics: runningInE2ETesting ? false : true,
  }),
}
