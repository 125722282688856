// @flow

import type { Action } from "./actionTypes"

export type State = {
  +isPending: boolean,
}

const initialState: State = {
  isPending: false,
}

export default function reducer(
  state: State = initialState,
  action: Action
): State {
  switch (action.type) {
    case "AUTH_RECOVER_PASSWORD_REQUESTED":
      return {
        ...state,
        isPending: true,
      }
    case "AUTH_RECOVER_PASSWORD_SUCCEEDED":
    case "AUTH_RECOVER_PASSWORD_FAILED":
      return {
        ...state,
        isPending: false,
      }
    default:
      return state
  }
}
