// @flow

export function fetchTemplates(): ApiCall {
  return {
    CALL_API: {
      endpoint: "/templates?activeOnly=true&expand=",
      config: {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      },
      types: [
        "FETCH_TEMPLATES_REQUEST",
        "FETCH_TEMPLATES_SUCCESS",
        "FETCH_TEMPLATES_FAILURE",
      ],
    },
  }
}
