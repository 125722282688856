// @flow

import * as React from "react";
import { Icon, Image, Header } from "semantic-ui-react";
import { Link } from "react-router-dom";

import { formatDate as toLocaleDateString } from "../utils/format";
import RawHtmlComponent from "./RawHtmlComponent";

export type Props = {
  header: string,
  author: string,
  url: string,
  newsIndexUrl: string,
  date: ?Date,
  image: string,
  content: string,
  summary: string,
  category: {
    name: string,
    path: string
  },
  footer: React.Node
};

export default function SingleBlogPost({
  header,
  author,
  date,
  newsIndexUrl,
  image,
  content,
  summary,
  category,
  footer
}: Props) {
  return (
    <div>
      <Header as="h1">
        {header}
        <Header.Subheader>
          {!date ? (
            <span>NOCH NICHT VERÖFFENTLICHT</span>
          ) : (
            <span>
              {author}, {toLocaleDateString(date)}
            </span>
          )}
        </Header.Subheader>
      </Header>

      {date ? null : <RawHtmlComponent html={summary} />}

      <Image src={image} />

      <RawHtmlComponent html={content} />

      {date && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "1em",
            marginBottom: "1em"
          }}
        >
          <object>
            <Link to={`${newsIndexUrl}?category=${category.path}`}>
              <Icon name="grid layout" />
              Mehr zum Thema {category.name}
            </Link>
          </object>
          {footer}
        </div>
      )}
    </div>
  );
}
