// @flow
import Alert from "react-s-alert"
import { push } from "connected-react-router"

import type {
  Dispatch,
  GetState,
  Services,
} from "../../../../store/rootReducer"

import config from "../../../../config"

export const activateAccount = (token: string) => async (
  dispatch: Dispatch,
  getState: GetState,
  { authApi }: Services
) => {
  try {
    const response = await authApi.activateAccount(token)
    Alert.success(response.description)
    dispatch(push(config.route.auth.signIn))
  } catch (e) {
    Alert.error(e.response.description)
    switch (e.response.code) {
      case "auth.account.activate.invalid":
        dispatch(push(config.route.auth.accountActivation))
        break
      default:
        Alert.error(e.response.description)
    }
  }
}

export const sendActivationEmail = (email: string) => async (
  dispatch: Dispatch,
  getState: GetState,
  { authApi }: Services
) => {
  dispatch({ type: "AUTH_SEND_ACTIVATION_EMAIL_REQUESTED" })
  try {
    const response = await authApi.sendActivationMail(email)
    Alert.success(response.description, { timeout: 30000 })
    dispatch({ type: "AUTH_SEND_ACTIVATION_EMAIL_SUCCEEDED" })
  } catch (e) {
    Alert.error(e.response.description)
    dispatch({ type: "AUTH_SEND_ACTIVATION_EMAIL_FAILED" })
  }
}
