// @flow
import { combineReducers } from "redux"

import newsReducer, * as newsSelectors from "./NewsModule/reducer"
import * as newsActionCreators from "./NewsModule/actions"
import type { State as NewsState } from "./NewsModule/reducer"
import type { Action as NewsAction } from "./NewsModule/actionTypes"

export default combineReducers({
  news: newsReducer,
})

export const actionCreators = {
  ...newsActionCreators,
}

export const selectors = {
  ...newsSelectors,
}

export type State = {
  news: NewsState,
}

export type Action = NewsAction
