// @flow

import { getBackendAddress } from "../store/api"

export function stripHtml(html: string) {
  return html.replace(/<\/?[^>]+(>|$)/g, "")
}

// https://stackoverflow.com/questions/16427636/check-if-localstorage-is-available
export function hasLocalStorage() {
  const type = "localStorage"
  try {
    const storage = window[type]
    const x = "__storage_test__"
    storage.setItem(x, x)
    storage.removeItem(x)
    return true
  } catch (e) {
    return false
  }
}

// flowlint-next-line deprecated-type:off
export function trackContractCompleteConversion(params: *) {
  trackGoogleConversion("et7bCLHTvHQQ35C_4QM", params)
}

// flowlint-next-line deprecated-type:off
export function trackFixpriceRequestSentConversion(params: *) {
  trackGoogleConversion("8nlqCI_ypHQQ35C_4QM", params)
}

// flowlint-next-line deprecated-type:off
function trackGoogleConversion(label: string, params: *) {
  const conv_handler = window["google_trackConversion"]
  if (typeof conv_handler === "function") {
    conv_handler({
      google_conversion_id: 1009764447,
      google_conversion_label: label,
      google_conversion_format: "3",
      google_custom_params: params,
      google_remarketing_only: false,
    })
  }
}

const monthNames = [
  "Januar",
  "Februar",
  "März",
  "April",
  "Mai",
  "Juni",
  "Juli",
  "August",
  "September",
  "Oktober",
  "November",
  "Dezember",
]

export function toLocaleDateString(date: Date) {
  return `${date.getDate()}. ${
    monthNames[date.getMonth()]
  } ${date.getFullYear()}`
}

export function toRoundedDownCurrencyAmount(number: number): string {
  if (number === 0) {
    return "Kostenlos"
  } else {
    return `CHF ${(Math.floor(number * 20) / 20)
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, "'")}`
  }
}

export function toRoundedUpCurrencyAmount(number: number): string {
  if (number === 0) {
    return "Kostenlos"
  } else {
    return `CHF ${(Math.ceil(number * 20) / 20)
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, "'")}`
  }
}

export function toRoundedCurrencyAmount(number: number): string {
  if (number === 0) {
    return "Kostenlos"
  } else {
    return `CHF ${(Math.ceil(number * 20 - 0.5) / 20)
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, "'")}`
  }
}

export function getPaymentUrl(order: Order, redirectUrl: string) {
  return `${getBackendAddress()}/payment/${
    order.id
  }/initialize?redirectUrl=${redirectUrl}`
}

export function getCurrentUrl(offeringRequestId: OfferingRequestId): string {
  try {
    // In development and on the live site,
    // this will give us the current url.
    if (window && window.location) {
      return window.location.href
    }
  } catch (e) {}
  // Fallback for server rendering when running on node
  return `https://www.legalgo.ch/${offeringRequestId}`
}

let runningInSelenium = false
try {
  if (
    typeof navigator !== undefined &&
    navigator.userAgent.indexOf("SELENIUM") > 0
  ) {
    runningInSelenium = true
  }
} catch (e) {}

export function isNotProduction() {
  if (runningInSelenium) {
    return true
  }

  if (typeof process === undefined) {
    return false
  }

  try {
    return process.env.NODE_ENV !== "production"
  } catch (e) {
    return false
  }
}
