// @flow

export function dismiss(call: string) {
  return {
    type: "API_CALL_DISMISS",
    call,
  }
}

export function dismissAll() {
  return {
    type: "API_CALL_DISMISS_ALL",
  }
}
