// @flow

import Home from "./Home/Home"
import Contact from "./Contact/Contact"
import Auth from "./Auth"
import config from "../config"
import Newsletter from "./Contact/Newsletter"
import TermsOfService from "./TermsOfService"
import News from "./News/News"
import SingleBlogPost from "./News/SingleBlogPost"
import Preview from "./News/Preview"
import ProductsOverview from "./Products/ProductsOverview"
import ProductDetails from "./Products/ProductDetails"
import CombinedProductDetails from "./Products/CombinedProductDetails"
import HowItWorksTemplates from "./Infos/HowItWorksTemplates"
import HowItWorksFixprices from "./Infos/HowItWorksFixprices"
import Faq from "./Infos/Faq"
import Experts from "./Experts"
import Request from "./Request"

export default [
  {
    path: config.route.index,
    component: Home,
    exact: true,
  },
  {
    path: config.route.contact.index,
    component: Contact,
    exact: true,
  },
  {
    path: config.route.infos.faq,
    component: Faq,
  },
  {
    path: config.route.experts.index,
    component: Experts,
  },
  {
    path: config.route.infos.howContractsWork,
    component: HowItWorksTemplates,
    exact: true,
  },
  {
    path: config.route.infos.howFixpricesWork,
    component: HowItWorksFixprices,
    exact: true,
  },
  {
    path: config.route.newsletter.index,
    component: Newsletter,
    exact: true,
  },
  {
    path: config.route.terms.index,
    component: TermsOfService,
    exact: true,
  },
  {
    path: config.route.auth.index,
    component: Auth,
  },
  {
    path: config.route.blog.preview,
    component: Preview,
  },
  {
    path: `${config.route.blog.index}/:uid`,
    component: SingleBlogPost,
  },
  {
    path: config.route.blog.index,
    component: News,
  },
  {
    path: "/bestellung/:requestId",
    component: Request,
  },
  {
    path: "/download/:requestId",
    component: Request,
  },
  {
    path: "/:requestId(\\w{8}-\\w{4}-\\w{4}-\\w{4}-\\w{12})",
    component: Request,
  },
  {
    path: "/:primaryCategory/vorsorgeauftrag",
    component: CombinedProductDetails,
  },

  {
    path: "/:primaryCategory/:offeringName/:requestId",
    component: Request,
  },
  {
    path: "/:primaryCategory/:name",
    component: ProductDetails,
  },
  {
    path: "/:primaryCategory",
    component: ProductsOverview,
  },
]
