// @flow

export function fetchExperts(): ApiCall {
  return {
    CALL_API: {
      endpoint: "/experts",
      config: {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      },
      types: [
        "FETCH_EXPERTS_REQUEST",
        "FETCH_EXPERTS_SUCCESS",
        "FETCH_EXPERTS_FAILURE",
      ],
    },
  }
}

export function fetchExpert(expertId: ExpertId) {
  return {
    CALL_API: {
      endpoint: `/experts/${expertId}`,
      config: {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      },
      types: [
        "FETCH_EXPERT_REQUEST",
        "FETCH_EXPERT_SUCCESS",
        "FETCH_EXPERT_FAILURE",
      ],
    },
  }
}

export function saveExpert(expert: Expert): ApiCall {
  return {
    CALL_API: {
      endpoint: `/experts/${expert.id}`,
      config: {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(expert),
      },
      types: [
        "SAVE_EXPERT_REQUEST",
        "SAVE_EXPERT_SUCCESS",
        "SAVE_EXPERT_FAILURE",
      ],
    },
  }
}

export function createExpert(expert: Expert): ApiCall {
  return {
    CALL_API: {
      endpoint: "/experts",
      config: {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(expert),
      },
      types: [
        "CREATE_EXPERT_REQUEST",
        "CREATE_EXPERT_SUCCESS",
        "CREATE_EXPERT_FAILURE",
      ],
    },
  }
}

export function deleteExpert(expert: Expert): ApiCall {
  return {
    CALL_API: {
      endpoint: `/experts/${expert.id}`,
      config: {
        method: "DELETE",
      },
      types: [
        "DELETE_EXPERT_REQUEST",
        "DELETE_EXPERT_SUCCESS",
        "DELETE_EXPERT_FAILURE",
      ],
      parameter: {
        expertId: expert.id,
      },
    },
  }
}

export function toggleExpertActiveState(expert: Expert): ApiCall {
  return {
    CALL_API: {
      endpoint: `/experts/${expert.id}/toggleActive`,
      config: {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
      },
      types: [
        "TOGGLE_EXPERT_ACTIVE_REQUEST",
        "TOGGLE_EXPERT_ACTIVE_SUCCESS",
        "TOGGLE_EXPERT_ACTIVE_FAILURE",
      ],
      parameter: {
        expertId: expert.id,
      },
    },
  }
}

export function filterExpertsByField(filter: Array<SpecialtyId>) {
  try {
    sessionStorage.setItem("specialtiesFilter", JSON.stringify(filter))
  } catch (e) {}
  return { type: "FILTER_EXPERT_FIELDS", filter }
}

export function filterExpertsByCanton(filter: Array<string>) {
  try {
    sessionStorage.setItem("cantonsFilter", JSON.stringify(filter))
  } catch (e) {}
  return { type: "FILTER_EXPERT_CANTON", filter }
}

export function selectExpertCanton(canton: string) {
  try {
    sessionStorage.setItem("selectedCanton", JSON.stringify(canton))
  } catch (e) {}
  return { type: "SELECT_EXPERT_CANTON", canton }
}
