// @flow

import { isApiCallInProgress, getApiCallFailure } from "../ApiModule/reducer"
import type { State as ApplicationState, Action } from "../../store/rootReducer"

export type State = {
  +fixpriceRequest: ?FixpriceRequest,
  +reminderSent: boolean,
} // store by id? should we allow multiple fixpriceRequests at the same time?

const initialState = {
  fixpriceRequest: null,
  reminderSent: false,
}

export default function reduce(
  state: State = initialState,
  action: Action
): State {
  switch (action.type) {
    case "COMPLETE_FIXPRICEREQUEST_SUCCESS":
    case "SUBMIT_FIXPRICEREQUEST_SUCCESS": {
      return {
        ...state,
        fixpriceRequest: {
          ...state.fixpriceRequest,
          // flowlint-next-line inexact-spread:off
          ...action.response,
        },
      }
    }
    case "DISCOUNT_CODE_FIXPRICE_SUCCESS":
    case "CUSTOMER_DATA_FIXPRICE_SUCCESS":
    case "ORDER_FIXPRICE_SUCCESS":
    case "FETCH_FIXPRICEREQUEST_SUCCESS":
    case "CREATE_NEW_FIXPRICEREQUEST_SUCCESS":
      return {
        ...state,
        fixpriceRequest: action.response,
      }
    case "CREATE_NEW_CONTRACT_SUCCESS":
      return {
        ...state,
        fixpriceRequest: undefined,
      }
    case "AUTOSAVE_FIXPRICEREQUEST_SUCCESS":
      return {
        ...state,
        fixpriceRequest: {
          ...state.fixpriceRequest,
          formValues: action.formData, // Should we do that? Could it override local data?
        },
      }
    case "FETCH_CONTRACT_OR_FIXPRICEREQUEST_SUCCESS": {
      if (
        action.response.type === "FixpriceRequest" ||
        action.response.type === "Fixprice"
      ) {
        return {
          ...state,
          fixpriceRequest: action.response,
        }
      } else {
        return state
      }
    }
    default:
      return state
  }
}

export const getFixpriceRequest = (
  state: ApplicationState
): ?FixpriceRequest => {
  return state.common.fixprices.fixpriceRequest
}

export const getFixpriceRequestId = (
  state: ApplicationState
): ?FixpriceRequestId => {
  return state.common.fixprices.fixpriceRequest
    ? state.common.fixprices.fixpriceRequest.id
    : null
}

export const isReminderSent = (state: ApplicationState): boolean => {
  return state.common.fixprices.reminderSent
}

export const isFetchingFixpriceRequest = (state: ApplicationState): boolean => {
  return isApiCallInProgress(state, "FETCH_FIXPRICEREQUEST_REQUEST")
}

export const getFetchFixpriceRequestErrors = (state: ApplicationState) => {
  return getApiCallFailure(state, "FETCH_FIXPRICEREQUEST_REQUEST")
}

export const isFinishingFixpriceRequest = (
  state: ApplicationState
): boolean => {
  return isApiCallInProgress(state, "FINISH_FIXPRICEREQUEST_REQUEST")
}

export const getFinishFixpriceRequestErrors = (state: ApplicationState) => {
  return getApiCallFailure(state, "SUBMIT_FIXPRICEREQUEST_REQUEST")
}

export const isSubmittingFixpriceRequest = (
  state: ApplicationState
): boolean => {
  return isApiCallInProgress(state, "SUBMIT_FIXPRICEREQUEST_REQUEST")
}

export const getSubmitFixpriceRequestErrors = (state: ApplicationState) => {
  return getApiCallFailure(state, "SUBMIT_FIXPRICEREQUEST_REQUEST")
}

export const isAcceptingFixpriceRequest = (
  state: ApplicationState
): boolean => {
  return isApiCallInProgress(state, "ACCEPT_FIXPRICEREQUEST_REQUEST")
}

export const getAcceptFixpriceRequestErrors = (state: ApplicationState) => {
  return getApiCallFailure(state, "ACCEPT_FIXPRICEREQUEST_REQUEST")
}

export const isDecliningFixpriceRequest = (
  state: ApplicationState
): boolean => {
  return isApiCallInProgress(state, "DECLINE_FIXPRICEREQUEST_REQUEST")
}

export const getDeclineFixpriceRequestErrors = (state: ApplicationState) => {
  return getApiCallFailure(state, "DECLINE_FIXPRICEREQUEST_REQUEST")
}

export const isCompletingFixpriceRequest = (
  state: ApplicationState
): boolean => {
  return isApiCallInProgress(state, "COMPLETE_FIXPRICEREQUEST_REQUEST")
}

export const getCompleteFixpriceRequestErrors = (state: ApplicationState) => {
  return getApiCallFailure(state, "COMPLETE_FIXPRICEREQUEST_REQUEST")
}

export const isCreatingFixpriceRequest = (state: ApplicationState): boolean => {
  return isApiCallInProgress(state, "CREATE_NEW_FIXPRICEREQUEST_REQUEST")
}

export const getCreateFixpriceRequestErrors = (state: ApplicationState) => {
  return getApiCallFailure(state, "CREATE_NEW_FIXPRICEREQUEST_REQUEST")
}

export const isAddingCustomerDataFixprice = (
  state: ApplicationState
): boolean => {
  return isApiCallInProgress(state, "CUSTOMER_DATA_FIXPRICE_REQUEST")
}

export const addCustomerDataFixpriceErrors = (state: ApplicationState) => {
  return getApiCallFailure(state, "CUSTOMER_DATA_FIXPRICE_REQUEST")
}

export const isAddingDiscountCodeFixprice = (
  state: ApplicationState
): boolean => {
  return isApiCallInProgress(state, "DISCOUNT_CODE_FIXPRICE_REQUEST")
}

export const addDiscountCodeFixpriceErrors = (state: ApplicationState) => {
  return getApiCallFailure(state, "DISCOUNT_CODE_FIXPRICE_REQUEST")
}

export const isOrderingFixprice = (state: ApplicationState): boolean => {
  return isApiCallInProgress(state, "ORDER_FIXPRICE_REQUEST")
}

export const getOrderFixpriceErrors = (state: ApplicationState) => {
  return getApiCallFailure(state, "ORDER_FIXPRICE_REQUEST")
}
