// @flow

import React from "react"
import { Helmet } from "react-helmet"

export type Props = {
  description?: string,
  title?: string,
  image?: ?string,
}
export default ({ description, title, image }: Props) => (
  <Helmet>
    {title != null && <title>{title}</title>}
    {description != null && <meta name="description" content={description} />}
    {title != null && <meta name="twitter:title" content={title} />}
    {description != null && (
      <meta name="twitter:description" content={description} />
    )}
    {image != null && <meta name="twitter:image" content={image} />}
    {title != null && <meta property="og:title" content={title} />}
    {description != null && (
      <meta property="og:description" content={description} />
    )}
    {image != null && <meta property="og:image" content={image} />}
  </Helmet>
)
