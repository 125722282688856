import { createStore, applyMiddleware, compose } from "redux"
import { createReducer, services } from "./rootReducer"
import thunk from "redux-thunk"
import { routerMiddleware } from "connected-react-router"
import { createMiddleware } from "redux-beacon"
import GoogleAnalytics from "@redux-beacon/google-analytics"
//import logger from "@redux-beacon/logger"

import type { Store } from "./rootReducer"
import eventsMap from "./eventsMap"

import api from "./api"

// For injected reducers, see
// https://blog.five2one.com.au/dynamically-load-your-redux-store-8fed7a246eb

const reduxBeaconMiddleware = createMiddleware(
  eventsMap,
  GoogleAnalytics() /*, {
  logger,
}*/
)

export default function configureStore(initialState = {}, history): Store {
  const store = createStore(
    createReducer(history),
    initialState,
    compose(
      applyMiddleware(
        thunk.withExtraArgument(services),
        api,
        routerMiddleware(history),
        reduxBeaconMiddleware
      ),
      typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : f => f
    )
  )
  return store
}
