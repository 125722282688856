// @flow

import * as React from "react"
import { Button, Header } from "semantic-ui-react"
import * as ShareButtons from "react-share"
import type { CommonShareButtonProps } from "react-share"
import { withRouter } from "react-router"

import config from "../config"

import "./SocialShareButtons.css"

export type Props = {
  buttonsOnly: boolean,
  to?: string,
  location: Location,
}

class FacebookShareButton extends React.Component<CommonShareButtonProps> {
  render() {
    return <ShareButtons.FacebookShareButton {...this.props} />
  }
}

class LinkedinShareButton extends React.Component<CommonShareButtonProps> {
  render() {
    return <ShareButtons.LinkedinShareButton {...this.props} />
  }
}

class TwitterShareButton extends React.Component<CommonShareButtonProps> {
  render() {
    return <ShareButtons.TwitterShareButton {...this.props} />
  }
}

class SocialShareButtons extends React.Component<CommonShareButtonProps> {
  render() {
    const {
      buttonsOnly,
      location: { pathname },
      to,
    } = this.props
    const url = `${config.domain}${to != null ? to : pathname}`

    return (
      <div className="SocialShareButtons">
        {buttonsOnly || (
          <Header as="h4">Gefällt Ihnen LegalGo? Bitte weitersagen:</Header>
        )}
        <Button
          as={FacebookShareButton}
          url={url}
          color="facebook"
          aria-label="Auf Facebook teilen"
          icon="facebook"
        />
        <Button
          icon="twitter"
          as={TwitterShareButton}
          url={url}
          color="twitter"
          aria-label="Auf Twitter teilen"
        />
        <Button
          icon="linkedin"
          as={LinkedinShareButton}
          url={url}
          color="linkedin"
          aria-label="Auf LinkedIn teilen"
        />
      </div>
    )
  }
}

export default withRouter(SocialShareButtons)
