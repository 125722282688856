// @flow

import type { Action } from "./actionTypes"

export type State = {
  +isPending: boolean,
  +errorMessage: ?string,
}

const initialState: State = {
  isPending: false,
  errorMessage: null,
}

export default function reducer(
  state: State = initialState,
  action: Action
): State {
  switch (action.type) {
    case "AUTH_SIGN_IN_REQUESTED":
      return {
        ...state,
        isPending: true,
        errorMessage: null,
      }
    case "AUTH_SIGN_IN_FAILED":
      return {
        ...state,
        isPending: false,
        errorMessage: action.message,
      }
    case "AUTH_SIGN_IN_SUCCEEDED":
      return {
        ...state,
        isPending: false,
        errorMessage: null,
      }
    default:
      return state
  }
}
