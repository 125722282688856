// @flow

import * as React from "react"
import type { Match, Location } from "react-router-dom"

import MainLayout from "../../layouts/MainLayout"
import { PreviewPost } from "../../react-redux-prismic-blog"

import Meta from "../../components/Meta"
import Section from "../../components/Section"
import config from "../../config/index"

const News = (props: { match: Match, location: Location }) => {
  return (
    <MainLayout className="Blog">
      <Meta title="News" />
      <Section verticalAlign="top" color="white">
        <PreviewPost
          api={config.api.prismic}
          match={props.match}
          location={props.location}
        />
      </Section>
    </MainLayout>
  )
}

export default News
