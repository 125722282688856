// @flow

import * as React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Button, Form, Header, Segment } from "semantic-ui-react"

import { actionCreators, selectors } from "../../modules"
import MainLayout from "../../layouts/MainLayout"
import Meta from "../../components/Meta"
import Section from "../../components/Section"

export type OwnProps = {}

export type Props = {
  isSignedUp: boolean,
  isLoading: boolean,
  errors: ?Error,
  actions: {
    newsletterSignup: (email: string) => void,
  },
}

function mapStateToProps(state, ownProps: OwnProps) {
  return {
    isSignedUp: selectors.isSignedUp(state),
    isLoading: selectors.isSigningUp(state),
    errors: selectors.getSignupErrors(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

class NewsletterSignup extends React.Component<Props, { email: string }> {
  state = {
    email: "",
  }

  handleClick = (event: Event) => {
    event.preventDefault()
    this.props.actions.newsletterSignup(this.state.email)
  }

  handleEmailChanged = e => this.setState({ email: e.target.value })

  render() {
    const { isSignedUp, isLoading, errors } = this.props
    const disabled = isLoading || this.state.email.length < 5
    return (
      <MainLayout className="Newsletter">
        <Meta description="Newsletter" />
        <Section narrow color="white">
          <Header as="h1" content="LegalGo Newsletter" />
          {isSignedUp ? (
            <p>Vielen Dank, Sie haben unseren Newsletter jetzt abonniert.</p>
          ) : (
            <Segment>
              <p>
                In unserem Newsletter informieren wir Sie regelmässig über das
                Neuste über LegalGo
              </p>
              <p>Sie können sich jederzeit wieder abmelden.</p>
              <Form
                success={isSignedUp}
                error={!!errors}
                onSubmit={this.handleClick}
              >
                <Form.Input
                  onChange={this.handleEmailChanged}
                  value={this.state.email}
                  autoFocus
                  placeholder="E-Mail Adresse"
                  aria-label="E-Mail Adresse für die Newsletteranmeldung"
                  name="email"
                />
                <Button
                  type="submit"
                  color="teal"
                  fluid
                  content="Newsletter anmelden"
                  loading={isLoading}
                  disabled={disabled}
                />
              </Form>
            </Segment>
          )}
        </Section>
      </MainLayout>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsletterSignup)
