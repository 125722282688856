// @flow
import { combineReducers } from "redux"

import signInReducer from "./SignInModule/reducer"
import * as signInActionCreators from "./SignInModule/actions"
import type { State as SignInState } from "./SignInModule/reducer"
import type { Action as SignInAction } from "./SignInModule/actionTypes"

import signUpReducer from "./SignUpModule/reducer"
import * as signUpActionCreators from "./SignUpModule/actions"
import type { State as SignUpState } from "./SignUpModule/reducer"
import type { Action as SignUpAction } from "./SignUpModule/actionTypes"

import userReducer from "./UserModule/reducer"
import * as userActionCreators from "./UserModule/actions"
import type { State as UserState } from "./UserModule/reducer"
import type { Action as UserAction } from "./UserModule/actionTypes"

import activateAccountReducer from "./ActivateAccountModule/reducer"
import * as activateAccountActionCreators from "./ActivateAccountModule/actions"
import type { State as ActivateAccountState } from "./ActivateAccountModule/reducer"
import type { Action as ActivateAccountAction } from "./ActivateAccountModule/actionTypes"

import recoverPasswordAccountReducer from "./RecoverPasswordModule/reducer"
import * as recoverPasswordActionCreators from "./RecoverPasswordModule/actions"
import type { State as RecoverPasswordState } from "./RecoverPasswordModule/reducer"
import type { Action as RecoverPasswordAction } from "./RecoverPasswordModule/actionTypes"

import resetPasswordAccountReducer from "./ResetPasswordModule/reducer"
import * as resetPasswordActionCreators from "./ResetPasswordModule/actions"
import type { State as ResetPasswordState } from "./ResetPasswordModule/reducer"
import type { Action as ResetPasswordAction } from "./ResetPasswordModule/actionTypes"

export { default as AuthAPI } from "../apis/AuthAPI"

export default combineReducers({
  signIn: signInReducer,
  signUp: signUpReducer,
  user: userReducer,
  activateAccount: activateAccountReducer,
  recoverPassword: recoverPasswordAccountReducer,
  resetPassword: resetPasswordAccountReducer,
})

export const actionCreators = {
  ...signInActionCreators,
  ...signUpActionCreators,
  ...userActionCreators,
  ...activateAccountActionCreators,
  ...recoverPasswordActionCreators,
  ...resetPasswordActionCreators,
}

export type State = {
  signIn: SignInState,
  signUp: SignUpState,
  user: UserState,
  activateAccount: ActivateAccountState,
  recoverPassword: RecoverPasswordState,
  resetPassword: ResetPasswordState,
}

export type Action =
  | SignInAction
  | SignUpAction
  | UserAction
  | ActivateAccountAction
  | RecoverPasswordAction
  | ResetPasswordAction
