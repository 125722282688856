// @flow

import * as React from "react"
import { Card, Button } from "semantic-ui-react"
import { Link } from "react-router-dom"

import { toRoundedDownCurrencyAmount } from "../../../util/utils"
import RawHtmlComponent from "../../../components/RawHtmlComponent"

type Props = {
  as?: React.Node,
  href?: string,
  name: string,
  path: string,
  kind?: string,
  price: number,
  description: { [key: string]: string },
}

export default function TemplateCard({
  as = Link,
  href,
  name,
  path,
  kind,
  price,
  description,
}: Props) {
  return (
    <Card
      as={as}
      href={href}
      to={path}
      itemScope
      itemType="http://schema.org/Product"
    >
      <Card.Content>
        <Card.Header itemProp="name">{name}</Card.Header>
        <Card.Meta
          itemProp="offers"
          itemScope
          itemType="http://schema.org/Offer"
        >
          <link href="http://schema.org/OnlineOnly" itemProp="availability" />
          <span>{toRoundedDownCurrencyAmount(price)}</span>
          <span itemProp="price" content={price} />
          <span itemProp="priceCurrency" content="CHF" />
          <span
            itemProp="seller"
            itemScope
            itemType="http://schema.org/Organization"
          >
            <meta itemProp="name" content="LegalGo.ch" />
          </span>
        </Card.Meta>
        <RawHtmlComponent
          itemProp="description"
          html={description["subtitle"]}
        />
      </Card.Content>
      <Card.Content extra>
        {kind != null ? (
          <Button
            fluid
            color={kind === "Fixprice" ? "yellow" : "teal"}
            content={
              kind === "Contract"
                ? "Sofort online erstellen"
                : kind === "Fixprice"
                ? "Anfrage für Fixpreisangebot"
                : ""
            }
          />
        ) : (
          <Button.Group widths="2" fluid>
            <Button color="teal">Basis</Button>
            <Button color="yellow">Premium</Button>
          </Button.Group>
        )}
      </Card.Content>
    </Card>
  )
}
