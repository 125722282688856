// @flow

import { Predicates } from "prismic-javascript";
import { RichText } from "prismic-dom";
import type { Match } from "react-router-dom";
import type { BlogPostDto, BlogPostId } from "../modules/NewsModule/reducer";

export const getAllPostsQuery = (id: ?BlogPostId, count: number = 20) => [
  [
    id != null
      ? Predicates.at("my.blog_post.category", id)
      : Predicates.at("document.type", "blog_post")
  ],
  {
    pageSize: count,
    orderings: "[document.last_publication_date desc]",
    fetchLinks: ["author.name", "category.name"]
  }
];

export const getCategoryQuery = (uid: BlogPostId) => [
  [Predicates.at("my.category.uid", uid)]
];

export const getSinglePostQuery = (uid: BlogPostId) => [
  [Predicates.at("my.blog_post.uid", uid)],
  {
    fetchLinks: ["author.name", "category.name"]
  }
];

export const getFurtherPostsQuery = (uid: BlogPostId) => [
  [
    Predicates.at("document.type", "blog_post"),
    Predicates.not("my.blog_post.uid", uid)
  ],
  {
    pageSize: "3",
    orderings: "[document.last_publication_date desc]",
    fetchLinks: ["author.name", "category.name"]
  }
];

export const getFeaturedPostsQuery = () => [
  [Predicates.at("document.tags", ["featured"])],
  {
    pageSize: "5",
    orderings: "[document.last_publication_date desc]",
    fetchLinks: ["author.name", "category.name"]
  }
];

function linkResolver(doc) {
  // Pretty URLs for known types

  //console.log("Resolving Links for ", doc)

  if (doc.type === "blog") return "/post/" + doc.uid;

  if (doc.type === "page") return "/" + doc.uid;

  // Fallback for other types, in case new custom types get created

  return doc;
}

function htmlSerializer(type, element, content, children) {
  switch (type) {
    case "hyperlink":
      return `<a target="_blank" href="${element.data.url ||
        element.data.value.url}">${content}</a>`;
    default:
      return null;
  }
}

export const extractFields = (
  match: Match = {
    url: /*`${config.domain}${config.route.news.index}`*/ "",
    isExact: true,
    params: {},
    path: ""
  }
) => (post: BlogPostDto) => {
  const {
    id,
    uid,
    data: { title, author, category, content, summary, image }
  } = post;

  const lastPublicationDate = post["last_publication_date"];

  let url;
  if (match.params && match.params.uid != null) {
    // we are on a detail page
    url = uid;
  } else if (match.url.endsWith("/")) {
    url = `${match.url}${uid}`;
  } else {
    url = `${match.url}/${uid}`;
  }

  try {
    return {
      uid,
      key: id,
      url,
      date: new Date(lastPublicationDate || 0),
      header: title || "",
      image: image ? image.url : "",
      author: author.data ? author.data.name : author.slug,
      category: {
        name: category.data ? category.data.name : category.slug,
        path: category.uid,
        url: `${match.url}?category=${category.uid}`
      },
      content: content
        ? RichText.asHtml(content, linkResolver, htmlSerializer)
        : "",
      summary: summary
        ? RichText.asHtml(summary, linkResolver, htmlSerializer)
        : ""
    };
  } catch (e) {
    console.log("Error constructing result from ", post);
    throw e;
  }
};
