// @flow

import * as React from "react"
import { Item } from "semantic-ui-react"

import TemplateExtraItem from "./TemplateExtraItem"
import { toRoundedDownCurrencyAmount } from "../../../util/utils"

import pdf from "../../../styles/images/document-pdf.jpg"
import lawyer from "../../../styles/images/lawyer.jpg"

export type Props = {
  name: string,
  orderButton?: React.Node,
  description: string,
  price: number,
  kind: "Contract" | "Fixprice",
  extras: Array<TemplateAddition>,
  long?: boolean,
}

export default ({
  name,
  orderButton,
  description,
  price,
  kind,
  extras,
  long = false,
}: Props) => (
  <div>
    <Item.Group unstackable>
      <Item header={name} />
      <Item itemScope itemType="http://schema.org/Product">
        <meta itemProp="name" content={name} />
        <meta itemProp="description" content={description} />
        <Item.Image size="tiny" src={kind === "Contract" ? pdf : lawyer} />
        {price === 0.0 ? (
          <Item.Content
            itemProp="offers"
            itemScope
            itemType="http://schema.org/Offer"
          >
            <link href="http://schema.org/OnlineOnly" itemProp="availability" />
            <Item.Meta>Dieses Angebot ist</Item.Meta>
            <Item.Header>Kostenlos</Item.Header>
            <Item.Description>
              {kind === "Contract" ? "Dokument im PDF Format" : ""}
            </Item.Description>
            <span itemProp="price" content="0.00" />
            <span itemProp="description" content="Kostenlos" />
            <span itemProp="priceCurrency" content="CHF" />
          </Item.Content>
        ) : (
          <Item.Content
            itemProp="offers"
            itemScope
            itemType="http://schema.org/Offer"
          >
            <link href="http://schema.org/OnlineOnly" itemProp="availability" />
            <Item.Meta>
              {long
                ? "Dieses Angebot erhalten Sie zum Preis von"
                : "Dieses Angebot für nur"}
            </Item.Meta>
            <Item.Header>{toRoundedDownCurrencyAmount(price)}</Item.Header>
            <Item.Description>
              {kind === "Contract" ? "Dokument im PDF Format" : ""}
            </Item.Description>
            <span itemProp="price" content={price} />
            <span itemProp="description" content={description} />
            <span itemProp="priceCurrency" content="CHF" />
          </Item.Content>
        )}
      </Item>
    </Item.Group>
    {orderButton}
    <br />
    <Item.Group unstackable>
      {extras.length > 0 && (
        <Item
          header="Zusatzangebote"
          description="Folgende Zusatzangebote können Sie beim Abschluss des Bestellvorgangs oder jederzeit nach dem Kauf erwerben."
        />
      )}
      {extras.map(extra => (
        <TemplateExtraItem key={extra.name} extra={extra} />
      ))}
    </Item.Group>
  </div>
)
