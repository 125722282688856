// @flow

import type { Action } from "./actionTypes"

export type State = {
  +isPending: boolean,
  +email: string,
}

const initialState: State = {
  isPending: false,
  email: "",
}

export default function reducer(
  state: State = initialState,
  action: Action
): State {
  switch (action.type) {
    case "AUTH_SAVE_ACTIVATION_EMAIL":
      return {
        ...state,
        email: action.email,
      }
    case "AUTH_SEND_ACTIVATION_EMAIL_REQUESTED":
      return {
        ...state,
        isPending: true,
      }
    case "AUTH_SEND_ACTIVATION_EMAIL_SUCCEEDED":
      return {
        ...state,
        isPending: false,
        email: "",
      }
    case "AUTH_SEND_ACTIVATION_EMAIL_FAILED":
      return {
        ...state,
        isPending: false,
      }
    default:
      return state
  }
}
