// @flow

import type { Action } from "./actionTypes"

export type State = {
  +initialized: boolean,
  +data: ?{
    [key: string]: string,
  },
}

const initialState: State = {
  initialized: false,
  data: null,
}

export default function reducer(
  state: State = initialState,
  action: Action
): State {
  switch (action.type) {
    case "AUTH_SAVE_USER":
      return {
        ...state,
        initialized: true,
        data: action.details,
      }
    case "AUTH_SIGN_OUT":
      return initialState
    default:
      return state
  }
}
