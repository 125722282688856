// @flow

import React from "react"
import { Grid, Image, Responsive, Divider } from "semantic-ui-react"

import LazyLoad from "react-lazyload"

import unentgeltliche from "../styles/images/partner-unentgeltliche.png"
import ubs from "../styles/images/partner-ubs.png"
import fasoon from "../styles/images/partner-fasoon.png"
import legi from "../styles/images/partner-legi.png"
import legalfly from "../styles/images/partner-legalfly.png"

export default function Partners() {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column tablet={4} verticalAlign="middle" computer={4} mobile={12}>
          <LazyLoad height="102px" once>
            <Image
              size="small"
              src={ubs}
              as="a"
              href="https://www.ubs.ch/?utm_source=legalgo&utm_medium=partner"
              target="_blank"
            />
          </LazyLoad>
        </Grid.Column>
        <Grid.Column tablet={5} verticalAlign="middle" computer={5} mobile={12}>
          <LazyLoad height="102px" once>
            <Image
              size="small"
              src={fasoon}
              as="a"
              href="https://fasoon.ch/?utm_source=legalgo&utm_medium=partner"
              target="_blank"
            />
          </LazyLoad>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column tablet={4} verticalAlign="middle" computer={4} mobile={12}>
          <LazyLoad height="102px" once>
            <Image
              size="small"
              src={legalfly}
              as="a"
              href="https://www.legalfly.ch/?utm_source=legalgo&utm_medium=partner"
              target="_blank"
            />
          </LazyLoad>
          <Responsive as={Divider} hidden {...Responsive.onlyMobile} />
        </Grid.Column>
        <Grid.Column tablet={4} verticalAlign="middle" computer={4} mobile={12}>
          <LazyLoad height="82px" once>
            <Image
              size="small"
              src={unentgeltliche}
              as="a"
              href="http://www.unentgeltliche-rechtsauskunft.ch/?utm_source=legalgo&utm_medium=partner"
              target="_blank"
            />
          </LazyLoad>
          <Responsive as={Divider} hidden {...Responsive.onlyMobile} />
        </Grid.Column>
        <Grid.Column tablet={3} verticalAlign="middle" computer={3} mobile={12}>
          <LazyLoad height="102px" once>
            <Image
              size="small"
              src={legi}
              as="a"
              href="https://legi.ch/?utm_source=legalgo&utm_medium=partner"
              target="_blank"
            />
          </LazyLoad>
          <Responsive as={Divider} hidden {...Responsive.onlyMobile} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}
