// @flow

import * as React from "react";
import { Card, Image } from "semantic-ui-react";
import { Link } from "react-router-dom";

import { formatDate as toLocaleDateString } from "../utils/format";

export type Props = {
  header: string,
  author: string,
  url: string,
  date: ?Date,
  image: string
};

export default function Teaser({ header, author, url, date, image }: Props) {
  return (
    <Card as={Link} to={url}>
      <Card.Content>
        <Card.Header>{header}</Card.Header>
        <Card.Meta>
          {author}, {toLocaleDateString(date)}
        </Card.Meta>
      </Card.Content>
      <Image src={image} />
    </Card>
  );
}
