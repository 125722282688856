// @flow

import { Accordion, Icon } from "semantic-ui-react"
import * as React from "react"
import { Component } from "react"

export type Props = {
  entries: Array<{
    name: string,
    content: React.Node,
  }>,
  onClick?: (index: number) => void,
}

type State = { activeIndex: number }

export default class extends Component<Props, State> {
  state = { activeIndex: -1 }

  handleTitleClick = (event: Event, { index }: { index: number }) => {
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index
    this.setState({ activeIndex: newIndex })
    this.props.onClick && this.props.onClick(index)
  }

  render() {
    const { entries } = this.props
    return (
      <Accordion className="Accordion">
        {entries
          .map(({ name, content }, index) => [
            <Accordion.Title
              key={name}
              active={this.state.activeIndex === index}
              index={index}
              onClick={this.handleTitleClick}
            >
              <Icon name="angle down" />
              {name}
            </Accordion.Title>,
            <Accordion.Content
              key={`${name}-${index}`}
              active={this.state.activeIndex === index}
            >
              {content}
            </Accordion.Content>,
          ])
          .reduce((acc, cur) => acc.concat(cur), [])}
      </Accordion>
    )
  }
}
