// @flow
import { combineReducers } from "redux"

import apiReducer, * as apiSelectors from "./ApiModule/reducer"
import * as apiActionCreators from "./ApiModule/actions"
import type { State as ApiState } from "./ApiModule/reducer"
import type { Action as ApiAction } from "./ApiModule/actionTypes"

import ctrReducer, * as ctrSelectors from "./ContractsModule/reducer"
import * as ctrActionCreators from "./ContractsModule/actions"
import type { State as ContractsState } from "./ContractsModule/reducer"
import type { Action as ContractsAction } from "./ContractsModule/actionTypes"

import emailReducer, * as emailSelectors from "./EmailsModule/reducer"
import * as emailActionCreators from "./EmailsModule/actions"
import type { State as EmailState } from "./EmailsModule/reducer"
import type { Action as EmailAction } from "./EmailsModule/actionTypes"

import fixReducer, * as fixSelectors from "./FixpricesModule/reducer"
import * as fixActionCreators from "./FixpricesModule/actions"
import type { State as FixpricesState } from "./FixpricesModule/reducer"
import type { Action as FixpricesAction } from "./FixpricesModule/actionTypes"

import locReducer, * as locSelectors from "./LocationModule/reducer"
import * as locActionCreators from "./LocationModule/actions"
import type { State as LocationState } from "./LocationModule/reducer"
import type { Action as LocationAction } from "./LocationModule/actionTypes"

import naviReducer, * as naviSelectors from "./NavigationModule/reducer"
import * as naviActionCreators from "./NavigationModule/actions"
import type { State as NavigationState } from "./NavigationModule/reducer"
import type { Action as NavigationAction } from "./NavigationModule/actionTypes"

import tplReducer, * as tplSelectors from "./TemplatesModule/reducer"
import * as tplActionCreators from "./TemplatesModule/actions"
import type { State as TemplatesState } from "./TemplatesModule/reducer"
import type { Action as TemplatesAction } from "./TemplatesModule/actionTypes"

export default combineReducers({
  api: apiReducer,
  contracts: ctrReducer,
  email: emailReducer,
  fixprices: fixReducer,
  location: locReducer,
  navigation: naviReducer,
  templates: tplReducer,
})

export const actionCreators = {
  ...apiActionCreators,
  ...ctrActionCreators,
  ...emailActionCreators,
  ...fixActionCreators,
  ...locActionCreators,
  ...naviActionCreators,
  ...tplActionCreators,
}

export const selectors = {
  ...apiSelectors,
  ...ctrSelectors,
  ...emailSelectors,
  ...fixSelectors,
  ...locSelectors,
  ...naviSelectors,
  ...tplSelectors,
}

export type State = {
  api: ApiState,
  contracts: ContractsState,
  email: EmailState,
  fixprices: FixpricesState,
  location: LocationState,
  navigation: NavigationState,
  templates: TemplatesState,
}

export type Action =
  | ApiAction
  | ContractsAction
  | EmailAction
  | FixpricesAction
  | LocationAction
  | NavigationAction
  | TemplatesAction
