// @flow

import * as React from "react"
import { Provider } from "react-redux"
import { ConnectedRouter } from "connected-react-router"
import { createBrowserHistory } from "history"
import ReactGA from "react-ga"
import transit from "transit-immutable-js"

import ScrollToTop from "../components/ScrollToTop"

// $FlowFixMe
import configureStore from "./configureStore"
import type { Store } from "./rootReducer"

import App from "../containers/App"

import { dismissAll } from "../modules/ApiModule/actions"

ReactGA.initialize("UA-88139660-1", {
  debug: false,
})
ReactGA.plugin.require("ecommerce")
ReactGA.pageview(window.location.pathname + window.location.search)

export const history = createBrowserHistory()

history.listen(location => {
  store.dispatch(dismissAll())
  ReactGA.pageview(location.pathname + location.search)
})

const initialState = window.__initialState__
  ? transit.fromJSON(window.__initialState__)
  : {}

const store: Store = configureStore(initialState, history)

const router = (
  <Provider store={store}>
    <div className="router-wrapper">
      <ConnectedRouter history={history}>
        <ScrollToTop>
          <App />
        </ScrollToTop>
      </ConnectedRouter>
    </div>
  </Provider>
)

export default router
