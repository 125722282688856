// @flow
// flowlint deprecated-type:off

import * as React from "react"
import { Container } from "semantic-ui-react"
import NarrowContent from "./NarrowContent"

import "./Section.css"

export type Props = {
  as?: string | React.ComponentType<*>,
  verticalAlign?: "top" | "center",
  color?: string,
  outerColor?: string | Array<string>,
  innerColor?: string | Array<string>,
  children?: React.Node,
  narrow?: boolean,
  inverted?: boolean,
}

export default (props: Props) => {
  const {
    as = props.narrow === true ? NarrowContent : "div",
    outerColor = props.color === "blue"
      ? ["rgb(0,22,65)", "rgb(40,64,109)"]
      : props.color === "white"
        ? ["rgba(240, 240, 240, 1)", "rgba(220, 220, 220, 1)"]
        : props.color || "",
    innerColor = props.color === "blue"
      ? ["rgba(255, 255, 255, 0.1)", "rgba(255, 255, 255, 0.1)"]
      : props.color === "white"
        ? ["rgba(255, 255, 255, 0.4)", "rgba(255, 255, 255, 0.4)"]
        : props.color || "",
    verticalAlign = "center",
    children,
    inverted = false,
    narrow,
    ...rest
  } = props

  const outerStyle =
    typeof outerColor === "string"
      ? { backgroundColor: outerColor }
      : { background: `linear-gradient( ${outerColor[0]}, ${outerColor[1]})` }
  const innerStyle =
    typeof outerColor === "string"
      ? { backgroundColor: innerColor }
      : { background: `linear-gradient( ${innerColor[0]}, ${innerColor[1]})` }

  const inv = inverted ? "inverted" : ""

  return (
    <div className={`Section ${verticalAlign} ${inv}`} style={outerStyle}>
      <Container style={innerStyle}>
        {React.createElement(as, rest, children)}
      </Container>
    </div>
  )
}
