// @flow
import React from "react"

import Alert from "react-s-alert"
import "react-s-alert/dist/s-alert-default.css"
import "react-s-alert/dist/s-alert-css-effects/stackslide.css"

export default () => (
  <Alert
    stack={{ limit: 3 }}
    html
    effect="stackslide"
    position="bottom-right"
    beep={false}
    timeout={10000}
  />
)
