// @flow

import * as React from "react"
import debounce from "lodash.debounce"

export default function<T>(Component: React.ComponentType<T>) {
  class ComponentWithScrollPosition<Props> extends React.Component<
    Props,
    { scrollTop: number }
  > {
    debouncedUpdateScrollPosition = null

    constructor(props: Props) {
      super(props)
      this.state = { scrollTop: 0 }
      this.debouncedUpdateScrollPosition = debounce(
        this.updateScrollPosition,
        50
      ).bind(this)
    }

    componentDidMount() {
      window.addEventListener(
        "scroll",
        this.debouncedUpdateScrollPosition,
        true
      )
    }

    componentWillUnmount() {
      window.removeEventListener(
        "scroll",
        this.debouncedUpdateScrollPosition,
        true
      )
    }

    updateScrollPosition() {
      const scrollTop =
        window.pageYOffset ||
        (document.documentElement && document.documentElement.scrollTop) ||
        0

      if (
        (this.state.scrollTop === 0 && scrollTop > 0) ||
        (this.state.scrollTop > 0 && scrollTop === 0)
      ) {
        this.setState({
          scrollTop,
        })
      }
    }

    render() {
      return <Component scrollTop={this.state.scrollTop} {...this.props} />
    }
  }

  return ComponentWithScrollPosition
}
