// @flow

import * as React from "react";
import { Card, Icon, Image } from "semantic-ui-react";
import { Link } from "react-router-dom";

import RawHtmlComponent from "./RawHtmlComponent";
import { formatDate as toLocaleDateString } from "../utils/format";

export type Props = {
  header: string,
  author: string,
  url: string,
  date: ?Date,
  image: ?string,
  summary: string,
  category: {
    name: string,
    path: string,
    url: string
  }
};

export default function Excerpt({
  header,
  author,
  url,
  date,
  image,
  summary,
  category,
  footer
}: Props) {
  return (
    <Card as={Link} to={url}>
      <Card.Content>
        <Card.Header>{header}</Card.Header>
        <Card.Meta>
          {author}, {toLocaleDateString(date)}
        </Card.Meta>
      </Card.Content>
      <Card.Content>
        <Card.Description>
          <RawHtmlComponent html={summary} />
          <p className="ReadMore">
            <Icon fitted name="long arrow alternate right" /> Weiterlesen
          </p>
        </Card.Description>
      </Card.Content>
      {image != null && <Image src={image} />}
      <Card.Content extra>
        <object>
          <Link to={category.url}>
            <Icon name="grid layout" />
            Mehr zum Thema {category.name}
          </Link>
        </object>
      </Card.Content>
    </Card>
  );
}
