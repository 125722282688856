// @flow

import * as React from "react"
import { Fragment } from "react"
import MenuBar from "./components/MenuBar"
import Footer from "./components/Footer"
import Helmet from "react-helmet"

import config from "../config"

import logo from "../styles/images/logo.png"
import provideScrollPosition from "../components/provideScrollPosition"
import withNavigation from "../containers/withNavigation"
import Reminder from "../containers/Reminder"

export type Props = {
  className: string,
  children: React.Node,
  scrollTop: number,
  navigation: Navigation,
}

const title = "LegalGo.ch"
const description = "Anwaltsleistungen für Private und Unternehmen"
const image = logo.startsWith("/")
  ? `${config.domain}${logo}`
  : `${config.domain}/${logo}`

class MainLayout extends React.Component<Props> {
  render() {
    const { className, scrollTop, children, navigation } = this.props
    const scrolled = scrollTop > 0
    return (
      <Fragment>
        <Helmet titleTemplate="%s - LegalGo.ch">
          <title>{description}</title>
          <meta name="description" content={description} />
          <meta itemprop="name" content={title} />
          <meta itemprop="description" content={description} />
          <meta itemprop="image" content={image} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@legalgo_ch" />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:creator" content="@legalgo_ch" />
          <meta name="twitter:image" content={image} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={image} />
          <meta property="og:site_name" content="LegalGo" />
          <meta property="og:locale" content="de_DE" />
          <meta property="fb:admins" content="785073103" />
          <script type="application/ld+json">{`
            {
              "@context" : "http://schema.org",
              "@type" : "Organization",
              "name" : "LegalGo",
              "logo" : "${image}",
              "url" : "https://www.legalgo.ch",
              "contactPoint": [{
                "@type": "ContactPoint",
                "telephone": "+41 71 272 26 56",
                "availableLanguage": "German",
                "areaServed": "CH",
                "contactType": "customer service"
              }],
              "sameAs" : [
                "https://twitter.com/legalgo_ch",
                "https://www.facebook.com/legalgo.ch"
              ]
            }
          `}</script>
        </Helmet>
        <div id="main" className={className + (scrolled ? " scrolled" : "")}>
          <MenuBar navigation={navigation} />
          {children}
        </div>
        <Reminder scrolled={scrolled} />
        <div id="footer">
          <Footer navigation={navigation} />
        </div>
      </Fragment>
    )
  }
}

export default provideScrollPosition(withNavigation(MainLayout))
