// @flow

import * as React from "react"
import { Item, Image, Popup, Icon } from "semantic-ui-react"

import { toRoundedDownCurrencyAmount } from "../../../util/utils"

import doc from "../../../styles/images/document-doc.jpg"
import lawyer from "../../../styles/images/lawyer.jpg"
import letter from "../../../styles/images/document.jpg"
import RawHtmlComponent from "../../../components/RawHtmlComponent"

export type Props = {
  extra: TemplateAddition,
  action?: React.Node,
}

function getIconForExtra(iconName) {
  switch (iconName) {
    case "doc":
      return doc
    case "letter":
      return letter
    default:
      return lawyer
  }
}

export default function({ extra, action }: Props) {
  const id = extra.name.toLowerCase().replace(" ", "-")
  return (
    <Item key={`template-extra-${id}`} className={id}>
      <Item.Image size="tiny">
        <Image src={getIconForExtra(extra.icon)} size="huge" />
      </Item.Image>
      <Item.Content>
        <Popup
          trigger={
            <Icon style={{ float: "right" }} link circular name="help" />
          }
          header={extra.name}
          content={
            <div>
              <RawHtmlComponent html={extra.help} />
              <p>
                LegalGo Zusatzangebote können Sie sofort mit dem Kauf oder auch
                später jederzeit noch dazukaufen.
              </p>
            </div>
          }
        />
        <Item.Meta>{extra.price && extra.name}</Item.Meta>
        <Item.Header>
          {extra.price ? toRoundedDownCurrencyAmount(extra.price) : extra.name}
        </Item.Header>
        <Item.Extra>
          {action}
          {extra.description}
        </Item.Extra>
      </Item.Content>
    </Item>
  )
}
