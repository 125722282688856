// @flow

import { push } from "connected-react-router"
import ReactGA from "react-ga"

import { getContractId } from "./reducer"
import { dismiss } from "../ApiModule/actions"
import { trackContractCompleteConversion } from "../../util/utils"

import type {
  State as ApplicationState,
  Action,
  Dispatch,
} from "../../store/rootReducer"

export function goToPage(page: number) {
  return push({
    search: `page=${page}`,
  })
}

export function createNewContract(templateId: TemplateId, path: string) {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    return dispatch({
      CALL_API: {
        endpoint: `/contracts/new/${templateId}`,
        config: {
          method: "POST",
          headers: {
            Accept: "application/json",
          },
        },
        types: [
          "CREATE_NEW_CONTRACT_REQUEST",
          "CREATE_NEW_CONTRACT_SUCCESS",
          "CREATE_NEW_CONTRACT_FAILURE",
        ],
      },
    }).then(() => {
      const id = getContractId(getState())
      if (id != null) {
        dispatch(push(`${path}/${id}`))
      }
    })
  }
}

export function autoSaveContract(
  contractId: ContractId,
  formData: Array<FormData>
): Action {
  return {
    CALL_API: {
      endpoint: `/contracts/${contractId}`,
      config: {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      },
      types: [
        "AUTOSAVE_CONTRACT_REQUEST",
        "AUTOSAVE_CONTRACT_SUCCESS",
        "AUTOSAVE_CONTRACT_FAILURE",
      ],
      parameter: {
        contractId,
        formData,
      },
    },
  }
}

export function completeContract(
  contractId: ContractId,
  formData: Array<FormData>
) {
  return {
    CALL_API: {
      endpoint: `/contracts/${contractId}/complete`,
      config: {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      },
      types: [
        "COMPLETE_CONTRACT_REQUEST",
        "COMPLETE_CONTRACT_SUCCESS",
        "COMPLETE_CONTRACT_FAILURE",
      ],
    },
  }
}

export function copyContract(contractId: ContractId) {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    return dispatch({
      CALL_API: {
        endpoint: `/contracts/${contractId}/copy`,
        config: {
          method: "POST",
          headers: {
            Accept: "application/json",
          },
        },
        types: [
          "COPY_CONTRACT_REQUEST",
          "COPY_CONTRACT_SUCCESS",
          "COPY_CONTRACT_FAILURE",
        ],
      },
    }).then(() => {
      const id = getContractId(getState())
      if (id != null) {
        dispatch(push(`/${id}`))
      }
    })
  }
}

export function resendConfirmation(contract: Contract): ApiCall {
  return {
    CALL_API: {
      endpoint: contract._links.resendConfirmation,
      config: {
        method: "POST",
      },
      types: [
        "SEND_CONFIRMATION_EMAIL_REQUEST",
        "SEND_CONFIRMATION_EMAIL_SUCCESS",
        "SEND_CONFIRMATION_EMAIL_FAILURE",
      ],
    },
  }
}

export function reopenContract(contractId: ContractId) {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    return dispatch({
      CALL_API: {
        endpoint: `/contracts/${contractId}/reopen`,
        config: {
          method: "POST",
          headers: {
            Accept: "application/json",
          },
        },
        types: [
          "REOPEN_CONTRACT_REQUEST",
          "REOPEN_CONTRACT_SUCCESS",
          "REOPEN_CONTRACT_FAILURE",
        ],
      },
    }).then(() => {
      const id = getContractId(getState())
      if (id != null) {
        dispatch(push(`/${id}`))
      }
    })
  }
}

export function addCustomerContactData(orderId: OrderId, formData: FormData) {
  return {
    CALL_API: {
      endpoint: `/orders/${orderId}/contact`,
      config: {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      },
      types: [
        "CUSTOMER_CONTACT_DATA_REQUEST",
        "CUSTOMER_CONTACT_DATA_SUCCESS",
        "CUSTOMER_CONTACT_DATA_FAILURE",
      ],
    },
  }
}

export function addCustomerData(
  orderId: OrderId,
  formData: FormData,
  paymentUrl: string
) {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    return dispatch({
      CALL_API: {
        endpoint: `/orders/${orderId}/customer`,
        config: {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        },
        types: [
          "CUSTOMER_DATA_REQUEST",
          "CUSTOMER_DATA_SUCCESS",
          "CUSTOMER_DATA_FAILURE",
        ],
        onSuccess: contract => {
          dispatch({ type: "REDIRECTING_TO_PAYMENT" })
          try {
            if (contract.template && contract.order) {
              ReactGA.plugin.execute("ecommerce", "addTransaction", {
                id: orderId,
                revenue: contract.order.totalPayable,
              })
              ReactGA.plugin.execute("ecommerce", "addItem", {
                id: orderId,
                name: contract.template.name,
                sku: contract.template.id,
                category: "Contract",
                price: contract.template.price,
                quantity: "1",
              })
              ReactGA.plugin.execute("ecommerce", "send")
              ReactGA.plugin.execute("ecommerce", "clear")
            }
          } catch (e) {
            console.error(e)
          }
          setTimeout(() => {
            window.location.href = paymentUrl
          }, 1000)
        },
      },
    })
  }
}

export function sendReminder(
  id: OfferingRequestId,
  email: string,
  url: string
): ApiCall {
  ReactGA.event({
    category: "Reminder Email",
    action: "Send Reminder",
  })
  return {
    CALL_API: {
      endpoint: `/notifications/${id}/reminder`,
      config: {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, url }),
      },
      types: [
        "CONTRACT_REMINDER_REQUEST",
        "CONTRACT_REMINDER_SUCCESS",
        "CONTRACT_REMINDER_FAILURE",
      ],
    },
  }
}

export function dismissSendReminderErrors() {
  return dismiss("CONTRACT_REMINDER_REQUEST")
}

// TODO move to an order reducer!
export function startOrder(orderId: OrderId) {
  return {
    CALL_API: {
      endpoint: `/orders/${orderId}/start`,
      config: {
        method: "POST",
      },
      types: [
        "START_ORDER_REQUEST",
        "START_ORDER_SUCCESS",
        "START_ORDER_FAILURE",
      ],
      onSuccess: () => {
        trackContractCompleteConversion({
          orderId,
        })
      },
    },
  }
}

export function cancelOrder(orderId: OrderId) {
  return {
    CALL_API: {
      endpoint: `/orders/${orderId}/cancel`,
      config: {
        method: "POST",
      },
      types: [
        "CANCEL_ORDER_REQUEST",
        "CANCEL_ORDER_SUCCESS",
        "CANCEL_ORDER_FAILURE",
      ],
    },
  }
}

export function addDiscountCode(orderId: OrderId, discountCode: ?string) {
  const body = discountCode != null ? { code: discountCode } : {}
  return {
    CALL_API: {
      endpoint: `/orders/${orderId}/discount`,
      config: {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      },
      types: [
        "DISCOUNT_CODE_REQUEST",
        "DISCOUNT_CODE_SUCCESS",
        "DISCOUNT_CODE_FAILURE",
      ],
    },
  }
}

export function addOrderExtra(orderId: OrderId, extra: TemplateAddition) {
  return {
    CALL_API: {
      endpoint: `/orders/${orderId}/extra`,
      config: {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ extra }),
      },
      types: [
        "ORDER_EXTRA_ADD_REQUEST",
        "ORDER_EXTRA_ADD_SUCCESS",
        "ORDER_EXTRA_ADD_FAILURE",
      ],
    },
  }
}

export function removeOrderExtra(orderId: OrderId, extra: TemplateAddition) {
  return {
    CALL_API: {
      endpoint: `/orders/${orderId}/extra/${extra.name}`,
      config: {
        method: "DELETE",
      },
      types: [
        "ORDER_EXTRA_REMOVE_REQUEST",
        "ORDER_EXTRA_REMOVE_SUCCESS",
        "ORDER_EXTRA_REMOVE_FAILURE",
      ],
    },
  }
}

export function fetchContract(contractId: ContractId): ApiCall {
  return {
    CALL_API: {
      endpoint: `/contracts/${contractId}`,
      config: {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      },
      types: [
        "FETCH_CONTRACT_REQUEST",
        "FETCH_CONTRACT_SUCCESS",
        "FETCH_CONTRACT_FAILURE",
      ],
    },
  }
}

export function changeContractStateToEditing(cid: ContractId): ApiCall {
  return changeContractState(cid, "Editing")
}

export function changeContractState(
  cid: ContractId,
  state: ContractState
): ApiCall {
  return {
    CALL_API: {
      endpoint: `/contracts/${cid}/state`,
      config: {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(state),
      },
      types: [
        "CHANGE_CONTRACT_STATE_REQUEST",
        "CHANGE_CONTRACT_STATE_SUCCESS",
        "CHANGE_CONTRACT_STATE_FAILURE",
      ],
    },
  }
}

export function fetchNumberOfCompletedContracts(): ApiCall {
  return {
    CALL_API: {
      endpoint: "/contracts/count",
      config: {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      },
      types: [
        "GET_COMPLETED_COUNT_REQUEST",
        "GET_COMPLETED_COUNT_SUCCESS",
        "GET_COMPLETED_COUNT_FAILURE",
      ],
    },
  }
}
