// @flow

import * as React from "react"

const Spacer = ({ size = "5em" }: { size?: string }) => (
  <span style={{ width: size, display: "inline-block" }} />
)
export const VerticalSpacer = () => (
  <div style={{ width: "100%", height: "1em" }} />
)

export const SmallSpacer = () => <Spacer size="2em" />
export const LargeSpacer = () => <Spacer size="5em" />
