// @flow
import API, { APIResponse } from "./API"

export default class extends API {
  newsletterSignup(email: string): Promise<APIResponse<{}>> {
    return this.jsonRequest("newsletter/signup", { email })
  }
  sendReminderEmail(
    claimId: string,
    email: string,
    url: string
  ): Promise<APIResponse<{}>> {
    return this.jsonRequest(`api/claims/${claimId}/reminder`, { email, url })
  }
}
