// @flow
import React from "react"
import { Loader } from "semantic-ui-react"
import loadable from "@loadable/component"

const Component = loadable(
  () => import(/* webpackChunkName: "auth" */ "./Auth"),
  {
    render: ({ Component, loading, ownProps }) => {
      if (loading) {
        return <Loader active size="large" />
      }
      return <Component {...ownProps} />
    },
  }
)

export default Component
