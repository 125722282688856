// @flow

import Feed from "feed";
import { getApi } from "prismic-javascript";
import { getAllPostsQuery, extractFields } from "./utils/utils";

function mkItem({ baseUrl, contact }) {
  return function({ url, date, header, image, author, summary, content }) {
    return {
      title: header,
      id: `${baseUrl}${url}`,
      link: `${baseUrl}${url}`,
      description: summary,
      content: summary,
      author: [
        {
          name: author,
          email: contact
        }
      ],
      date,
      image
    };
  };
}

export const mkRssFeed = (
  config: { baseUrl: string, contact: string },
  api: string
) => {
  const [predicates, options] = getAllPostsQuery();

  const feed = new Feed(config);

  return getApi(api)
    .then(api => api.query(predicates, options))
    .then(({ results }) => results.map(extractFields()))
    .then(entries => entries.map(mkItem(config)))
    .then(items => {
      items.forEach(item => feed.addItem(item));
      return feed.rss2();
    });
};
