// @flow

// TODO maybe provide as context instead?

import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import type { Match } from "react-router-dom"

import * as actionCreators from "../modules/NavigationModule/actions"
import * as navigationSelectors from "../modules/NavigationModule/reducer"
import type { State, Dispatch } from "../store/rootReducer"

export type OwnProps = {
  match: Match,
}

function mapStateToProps(state: State, ownProps: OwnProps) {
  return {
    navigation: navigationSelectors.getNavigation(state),
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return { actions: bindActionCreators(actionCreators, dispatch) }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)
