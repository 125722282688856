// @flow

import * as React from "react"
import { List, Grid, Icon } from "semantic-ui-react"
import { Link } from "react-router-dom"
import ReactGA from "react-ga"

import { toRoundedDownCurrencyAmount } from "../util/utils"

import "./CatalogOverview.css"
import Accordion from "./Accordion"

export type Props = {
  navigation: Navigation,
  templates: Array<Template>,
}

export default class CatalogOverview extends React.Component<Props> {
  sortedCategoryNames = []
  aggregatedEntries = {}

  constructor(props: Props) {
    super(props)
    this.initialize(props)
  }

  componentWillReceiveProps(nextProps: Props) {
    this.initialize(nextProps)
  }

  initialize = (props: Props) => {
    this.sortedCategoryNames = []
    this.aggregatedEntries = {}
    props.navigation.forEach(({ path: parentPath, children }) =>
      children.forEach(({ title, path, offerings: ids }) => {
        const filteredOfferings = ids
          .map(id => props.templates.find(t => t.id === id))
          .filter(Boolean)

        if (filteredOfferings.length <= 0) {
          return
        }

        this.aggregatedEntries[title] = [
          ...(this.aggregatedEntries[title] || []),
          ...filteredOfferings.map(({ id, name, path, price }) => ({
            id,
            name,
            parentPath,
            path,
            price,
          })),
        ]
      })
    )

    // TODO remove this hack:
    /*
    const price =
      this.aggregatedEntries["Private Vorsorge"] &&
      this.aggregatedEntries["Private Vorsorge"].filter(
        e => e.name === "Ehevertrag"
      ).length > 0
        ? 380
        : 59

    const vorsorgeauftrag = {
      id: "eff49a73-060d-4d0d-9488-9a6df956d1a9",
      name: "Vorsorgeauftrag",
      parentPath: "private",
      path: "vorsorgeauftrag",
      price,
    }

    if (this.aggregatedEntries["Ihre Familie"]) {
      this.aggregatedEntries["Ihre Familie"].push(vorsorgeauftrag)
    }
    if (this.aggregatedEntries["Private Vorsorge"]) {
      this.aggregatedEntries["Private Vorsorge"].push(vorsorgeauftrag)
    }*/

    this.sortedCategoryNames = Object.keys(this.aggregatedEntries).sort()
  }

  handleTitleClick = (index: number) => {
    ReactGA.event({
      category: "Offerings Overview",
      action: `Opened ${this.sortedCategoryNames[index]}`,
    })
  }

  render() {
    const { templates } = this.props

    if (templates.length <= 0) {
      return <div />
    }

    const entries = this.sortedCategoryNames.map((categoryName, index) => {
      const offerings = this.aggregatedEntries[categoryName].sort(
        (o1, o2) => (o1.name > o2.name ? 1 : -1)
      )
      return {
        name: categoryName,
        content: (
          <List>
            {offerings.map(({ id, name, parentPath, path, price }) => (
              <List.Item
                key={`list-${categoryName}-${index}-${id}`}
                itemScope
                itemType="http://schema.org/Product"
              >
                <List.Content>
                  <Link to={`/${parentPath}/${path}`}>
                    <Icon name="long arrow alternate right" />
                    <span itemProp="name">{name}</span>
                  </Link>
                </List.Content>
                <List.Content
                  floated="right"
                  itemProp="offers"
                  itemScope
                  itemType="http://schema.org/Offer"
                >
                  <link
                    href="http://schema.org/OnlineOnly"
                    itemProp="availability"
                  />
                  <span>{toRoundedDownCurrencyAmount(price)}</span>
                  <span itemProp="price" content={price} />
                  <span itemProp="priceCurrency" content="CHF" />
                  <span itemProp="description" content={name} />
                </List.Content>
              </List.Item>
            ))}
          </List>
        ),
      }
    })

    return (
      <Grid columns="one" className="CatalogOverview" stackable>
        <Grid.Column>
          <Accordion entries={entries} onClick={this.handleTitleClick} />
        </Grid.Column>
      </Grid>
    )
  }
}
