// @flow
import Alert from "react-s-alert"
import { push } from "connected-react-router"

import type {
  Dispatch,
  GetState,
  Services,
} from "../../../../store/rootReducer"
import config from "../../../../config"

export const signUp = (name: string, email: string, password: string) => async (
  dispatch: Dispatch,
  getState: GetState,
  { authApi }: Services
) => {
  dispatch({ type: "AUTH_SIGN_UP_REQUESTED" })
  try {
    const response = await authApi.signUp({ name, email, password })
    Alert.success(response.description, { timeout: 30000 })
    dispatch({ type: "AUTH_SIGN_UP_SUCCEEDED" })
    dispatch(push(config.route.auth.accountActivation))
  } catch (e) {
    switch (e.response.code) {
      case "auth.signUp.form.invalid": {
        dispatch({ type: "AUTH_SIGN_UP_FAILED", message: e.message })
        break
      }
      default:
        Alert.error(e.response.description)
        break
    }
  }
}
