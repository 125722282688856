// @flow

import React, { Fragment } from "react"
import { Container, Grid, List, Image } from "semantic-ui-react"
import { Link } from "react-router-dom"

import logo from "../../styles/images/logo.png"

import facebook from "../../styles/images/facebook-white.png"
import twitter from "../../styles/images/twitter-white.png"
import linkedin from "../../styles/images/linkedin-white.png"
import "./Footer.css"
import config from "../../config/index"
import { VerticalSpacer } from "../../components/spacer/spacer"
import { HashLink } from "react-router-hash-link"

const Logo = () => (
  <Fragment>
    <Image
      className="Logo"
      src={logo.startsWith("/") || logo.startsWith("data:") ? logo : `/${logo}`}
    />
    <VerticalSpacer />
  </Fragment>
)

const SocialIcons = () => (
  <Image.Group size="mini">
    <Image
      as="a"
      href="https://twitter.com/legalgo_ch"
      target="_blank"
      src={twitter}
    />
    <Image
      as="a"
      href="https://www.facebook.com/legalgo.ch/"
      target="_blank"
      src={facebook}
    />
    <Image
      as="a"
      href="https://www.linkedin.com/company/legalgo-ch/"
      target="_blank"
      src={linkedin}
    />
  </Image.Group>
)

const AboutLegalGoPages = () => (
  <Fragment>
    <List inverted>
      <List.Item header="LegalGo" />
      <List.Item
        as={Link}
        to={config.route.blog.index}
        content="News im Blog"
      />
      <List.Item
        as={Link}
        to={config.route.contact.index}
        content="Impressum"
      />
      <List.Item
        as={Link}
        to={config.route.terms.index}
        content="AGB / Datenschutz"
      />
      <List.Item
        as={Link}
        to={config.route.newsletter.index}
        content="Newsletter"
      />
    </List>
    <List inverted>
      <List.Item header="So Funktioniert's" />
      <List.Item
        as={Link}
        to={config.route.infos.howContractsWork}
        content="Vertragsvorlagen"
      />
      <List.Item
        as={Link}
        to={config.route.infos.howFixpricesWork}
        content="Fixpreisangebote"
      />
      <List.Item
        as={Link}
        to={config.route.infos.faq}
        content="Häufige Fragen"
      />
    </List>
  </Fragment>
)

const Contact = () => (
  <List className="Contact" inverted size="tiny">
    <List.Item as="strong" content="LegalGo GmbH" />
    <List.Item as="span" content="Dufourstrasse 121" />
    <List.Item as="span" content="9000 St. Gallen" />
    <List.Item as="a" href="mailto:info@legalgo.ch" content="info@legalgo.ch" />
    <List.Item as="span" content="+41 71 272 2656" />
  </List>
)

const Footer = ({ navigation }: { navigation: Navigation }) => (
  <div className="Footer DesktopFooter">
    <Container>
      <Grid inverted>
        <Grid.Row>
          <Grid.Column width={12} />
          <Grid.Column width={4}>
            <Logo />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row divided>
          <Grid.Column width={4}>
            <AboutLegalGoPages />
          </Grid.Column>
          <ProductCategoriesFooterColumns width={4} navigation={navigation} />
          <Grid.Column width={1}>
            <SocialIcons />
          </Grid.Column>
          <Grid.Column width={3}>
            <Contact />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  </div>
)

const ProductCategoriesFooterColumns = ({
  navigation,
  width,
}: {
  navigation: Navigation,
  width: number,
}) => (
  <Fragment>
    {navigation.map(({ title, path, children }) => (
      <Grid.Column key={path} width={width}>
        <List inverted>
          <List.Item header={title} />

          {children.map(({ title, subtitle, path: childpath }) => (
            <List.Item
              as={HashLink}
              key={childpath}
              to={`/${path}#${childpath}`}
              content={title}
            />
          ))}
        </List>
      </Grid.Column>
    ))}
  </Fragment>
)
const MobileFooter = ({ navigation }: { navigation: Navigation }) => (
  <div className="Footer MobileFooter">
    <Container>
      <Grid inverted>
        <Grid.Row>
          <Grid.Column width={8}>
            <Logo />
          </Grid.Column>
          <Grid.Column verticalAlign="bottom" width={8}>
            <SocialIcons />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row divided>
          <Grid.Column width={8}>
            <AboutLegalGoPages />
          </Grid.Column>
          <Grid.Column width={8}>
            <Contact />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <ProductCategoriesFooterColumns width={8} navigation={navigation} />
        </Grid.Row>
      </Grid>
    </Container>
  </div>
)
export default ({ navigation }: { navigation: Navigation }) => (
  <Fragment>
    <Footer navigation={navigation} />
    <MobileFooter navigation={navigation} />
  </Fragment>
)
