// @flow

import { push } from "connected-react-router"
import Raven from "raven-js"
import config from "../config"

import type { Store } from "./rootReducer"

function callApi(endpoint, additionalConfig) {
  const { headers } = additionalConfig
  const config = {
    ...additionalConfig,
    headers: {
      ...headers,
      Accept: "application/json",
    },
  }

  return fetch(endpoint, config).then(
    response =>
      response.json().then(
        json => ({ json, response }),
        error => ({ json: error, response }) // Failed Play-Routes don't return json
      ),
    error => ({ json: "Failed to fetch", response: {} })
  )
}

// TODO make const?
export function getBackendAddress(): string {
  /////////////////////////////////////////////
  // UGLY HACK TO SWITCH TO A TEST SERVER
  //
  let apiServer = undefined
  try {
    if (navigator && navigator.userAgent.indexOf("SELENIUM") > 0) {
      apiServer = "http://localhost:8713"
    }
  } catch (e) {}
  /////////////////////////////////////////////

  if (process.env.REACT_APP_API_HOST != null) {
    return process.env.REACT_APP_API_HOST
  }

  return apiServer || config.apiServer
}

// flowlint-next-line deprecated-type:off
export default (store: Store) => (next: *) => (action: *) => {
  const callAPI = action["CALL_API"]

  if (typeof callAPI === "undefined") {
    return next(action)
  }

  const { endpoint, config, types, parameter, onSuccess } = callAPI

  const fullUrl = endpoint.startsWith("http")
    ? endpoint // because we got it from the server
    : `${getBackendAddress()}${endpoint}`

  const [requestType, successType, errorType] = types

  next({ type: requestType })
  next({ type: "API_CALL_STARTED", call: requestType })

  return callApi(fullUrl, config || {})
    .then(({ json, response }) => {
      if (!response.ok) {
        return Promise.reject({ json, error: response })
      }
      return json
    })
    .then(
      response => {
        next({ ...parameter, response, type: successType })
        next({ type: "API_CALL_SUCCEEDED", call: requestType })
        if (onSuccess) {
          onSuccess(response)
        }
      },
      ({ json, error }) => {
        Raven.captureMessage(
          error ? error.statusText : "Error calling the API",
          {
            extra: {
              url: fullUrl,
              data: config,
              status: error.status,
              error: error || error.statusText,
              response: error.responseText,
              logger: "api",
            },
          }
        )

        if (error.status === 401) {
          next({ type: "SESSION_EXPIRED" })
          next(push("/"))
        } else {
          const result = {
            type: errorType,
            statusCode: error.status,
            status:
              error.statusText ||
              error.response ||
              `There was an error, status ${error.status}`,
          }
          if (json) {
            next({ ...result, response: json })
          } else {
            next(result)
          }
          next({
            type: "API_CALL_FAILED",
            call: requestType,
            statusCode: result.statusCode,
            status: result.status,
            response: json,
          })
        }
      }
    )
}
