// @flow

import * as React from "react"
import { Button } from "semantic-ui-react"
import { Link } from "react-router-dom"
import config from "../config/index"

export type Props = {
  content?: string,
  icon?: string,
}

export function HowItWorksButton({
  content = "So funktioniert's",
  to,
  color,
  icon = "arrow right",
}: Props & {
  to: string,
  color: string,
}) {
  return (
    <Button
      as={Link}
      to={to}
      color={color}
      content={content}
      icon={icon}
      labelPosition="right"
    />
  )
}

export function HowContractsWork({ content, icon }: Props) {
  return (
    <HowItWorksButton
      color="teal"
      content={content}
      to={config.route.infos.howContractsWork}
      icon={icon}
    />
  )
}

export function HowFixpricesWork({ content, icon }: Props) {
  return (
    <HowItWorksButton
      color="yellow"
      content={content}
      to={config.route.infos.howFixpricesWork}
      icon={icon}
    />
  )
}
