// @flow
import * as React from "react"
import { Card, Button } from "semantic-ui-react"
import SuggestContent from "../../../containers/SuggestContent"

const suggestionCardStyle = {
  backgroundColor: "transparent",
  boxShadow: "0px 1px 3px 0px #E0E0E0, 0px 0px 0px 1px #E0E0E0",
}

function SuggestionCard() {
  return (
    <Card style={suggestionCardStyle} className="SuggestionCard">
      <Card.Content>
        <Card.Header>Nicht fündig geworden?</Card.Header>
        <Card.Meta />
        Haben Sie das gewünschte Angebot nicht gefunden? Bitte teilen Sie uns
        mit wie wir unser Sortiment ausbauen könnten.
      </Card.Content>
      <Card.Content extra>
        <SuggestContent as={Button} fluid>
          Ergänzung vorschlagen
        </SuggestContent>
      </Card.Content>
    </Card>
  )
}

export default SuggestionCard
