// @flow

import * as React from "react"
import { Fragment } from "react"
import { Container, Menu, Dropdown, List } from "semantic-ui-react"
import { Link } from "react-router-dom"
import { HashLink } from "react-router-hash-link"

import "./MenuBar.css"
import logo from "../../styles/images/logo.png"
import config from "../../config/index"

export type Props = {
  navigation: Navigation,
}

const LegalGoLogo = () => (
  <Link className="LogoLink" to={config.route.index}>
    <img
      className="Logo"
      src={logo.startsWith("/") || logo.startsWith("data:") ? logo : `/${logo}`}
      alt=""
    />
  </Link>
)

const MenuContainer = ({ navigation }) => (
  <Container className="full-height">
    <LegalGoLogo />
    <Menu
      itemScope
      itemType="http://www.schema.org/SiteNavigationElement"
      borderless
      inverted
      className="full-height"
    >
      {navigation.map(({ title, path: parentPath, children }, index) => {
        return (
          <Dropdown item text={title} key={index}>
            <Dropdown.Menu>
              {children.map(({ title, subtitle, path: childPath }, index) => (
                <List.Item
                  itemProp="url"
                  key={index}
                  as={HashLink}
                  to={`/${parentPath}#${childPath}`}
                >
                  <List.Header itemProp="name">{title}</List.Header>
                  <List.Content>{subtitle}</List.Content>
                </List.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )
      })}
      <Dropdown item text="Infos">
        <Dropdown.Menu>
          <List.Item itemProp="url" as={Link} to={config.route.contact.index}>
            <List.Header itemProp="name">Kontakt</List.Header>
            <List.Content>Das LegalGo Team</List.Content>
          </List.Item>
          <List.Item
            itemProp="url"
            as={Link}
            to={config.route.infos.howContractsWork}
          >
            <List.Header itemProp="name">Vertragsdokumente</List.Header>
            <List.Content>So funktioniert's</List.Content>
          </List.Item>
          <List.Item
            itemProp="url"
            as={Link}
            to={config.route.infos.howFixpricesWork}
          >
            <List.Header itemProp="name">Fixpreisangebote</List.Header>
            <List.Content>So funktioniert's</List.Content>
          </List.Item>
          <List.Item itemProp="url" as={Link} to={config.route.terms.index}>
            <List.Header itemProp="name">AGB</List.Header>
            <List.Content>Allgemeine Geschäftsbedingungen</List.Content>
          </List.Item>
          <List.Item
            itemProp="url"
            as={Link}
            to={config.route.newsletter.index}
          >
            <List.Header itemProp="name">Newsletter</List.Header>
            <List.Content>Jetzt anmelden</List.Content>
          </List.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Menu.Item name="Blog" as={Link} to={config.route.blog.index} />{" "}
    </Menu>
  </Container>
)

class MenuBar extends React.Component<Props> {
  render() {
    const navigation =
      this.props.navigation.length > 0
        ? this.props.navigation
        : [
            {
              title: "Für Private",
              subtitle: "",
              path: "private",
              children: [],
            },
            {
              title: "Für Unternehmen",
              subtitle: "",
              path: "unternehmen",
              children: [],
            },
          ]
    return (
      <Fragment>
        <div className="MenuBar">
          <MenuContainer navigation={navigation} />
        </div>
        <div className="MobileMenuBar">
          <MenuContainer navigation={navigation} />
        </div>
      </Fragment>
    )
  }
}

export default MenuBar
