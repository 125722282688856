// @flow

import { push } from "connected-react-router"

import type {
  Dispatch,
  GetState,
  Services,
} from "../../../../store/rootReducer"
import hasSessionStorage from "../../../../util/hasSessionStorage"
import config from "../../../../config"

export const signOut = () => async (
  dispatch: Dispatch,
  getState: GetState,
  { authApi }: Services
) => {
  dispatch({ type: "AUTH_SIGN_OUT" })
  if (hasSessionStorage()) {
    sessionStorage.removeItem("X-Auth-Token")
  }
  await authApi.signOut()
  dispatch(push(config.route.index))
}

export const fetchUser = () => async (
  dispatch: Dispatch,
  getState: GetState,
  { authApi }: Services
) => {
  if (hasSessionStorage() && sessionStorage.getItem("X-Auth-Token")) {
    try {
      const response = await authApi.user()
      dispatch({ type: "AUTH_SAVE_USER", details: response.details })
    } catch (e) {}
  }
}
