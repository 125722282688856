// @flow

import * as React from "react"
import { Container, Breadcrumb, Icon } from "semantic-ui-react"
import { HashLink } from "react-router-hash-link"

import "./BreadCrumbs.css"

export type Props = {
  crumbs: Array<{
    name: string,
    link?: string,
  }>,
}

function BreadCrumbLink({ index, ...props }) {
  return (
    <span
      itemProp="itemListElement"
      itemScope
      itemType="http://schema.org/ListItem"
    >
      <meta itemProp="position" content={index + 1} />
      <meta itemProp="name" content={props.title} />
      <HashLink itemProp="item" {...props} />
    </span>
  )
}

class BreadCrumbs extends React.PureComponent<Props, { sticky: boolean }> {
  state = {
    sticky: false,
  }

  render() {
    const links = [{ name: "Home", link: "/" }, ...this.props.crumbs]
    const crumbs = links.map(({ name, link }, index) => {
      if (link != null) {
        return {
          key: index,
          content: name,
          title: name,
          as: BreadCrumbLink,
          index,
          to: link,
        }
      } else {
        return { key: index, content: name }
      }
    })

    return (
      <div className={`BreadCrumbs ${this.state.sticky ? "sticky" : ""}`}>
        <Container>
          <Breadcrumb
            itemScope
            itemType="http://schema.org/BreadcrumbList"
            size="small"
            sections={crumbs}
            divider={<Icon name="right chevron" />}
          />
        </Container>
      </div>
    )
  }
}

export default BreadCrumbs
