// @flow

import * as React from "react"
import { Fragment } from "react"
import {
  Loader,
  Grid,
  Header,
  Item,
  Container,
  Image,
  Segment,
} from "semantic-ui-react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import MainLayout from "../../layouts/MainLayout"
import Section from "../../components/Section"

import { selectors, actionCreators } from "../../modules"
import { LatestPost } from "../../react-redux-prismic-blog"

import { withRouter, type Match } from "react-router-dom"
import ErrorMessage from "../../components/ErrorMessage"
import { type State } from "../../store/rootReducer"
import "./ProductDetails.css"
import reactGa from "react-ga"
import { stripHtml, toRoundedDownCurrencyAmount } from "../../util/utils"
import { Helmet } from "react-helmet"
import RawHtmlComponent from "../../components/RawHtmlComponent"
import defaultImage from "../../styles/images/template-default.jpg"
import CreateContractButton from "./components/CreateContractButton"
import CreateFixpriceButton from "./components/CreateFixpriceButton"
import StartChatButton from "./components/StartChatButton"
import BreadCrumbs from "../../components/BreadCrumbs"
import Accordion from "../../components/Accordion"
import {
  HowContractsWork,
  HowFixpricesWork,
} from "../../components/HowItWorksButtons"
import { LazyExpertRecommendation } from "../Experts"

import pdf from "../../styles/images/document-pdf.jpg"
import lawyer from "../../styles/images/lawyer.jpg"
import config from "../../config/index"

export type Props = {
  template: ?Template,
  path: string,
  navigation: ?PrimaryNavigation,
  isLoading: boolean,
  isCreating: boolean,
  errors: ?ApiError,
  actions: {
    fetchTemplates: () => ApiCall,
    createNewContract: (templateId: TemplateId, path: string) => void,
    createNewFixpriceRequest: (templateId: TemplateId, path: string) => void,
  },
}

const TemplateHeaderSection = ({
  template,
  isCreating,
}: {
  template: Template,
  isCreating: boolean,
}) => (
  <Grid className="HeaderBar" stackable>
    <Grid.Row verticalAlign="top" columns={16}>
      <Grid.Column width={10}>
        <Header
          inverted
          data-test="template-name"
          className="template-name"
          content={template.name}
        />
        <div data-test="header-description">
          Schützen Sie sich und Ihre Familie für den Vorsorgefall und erstellen
          Sie noch heute mit LegalGo Ihren Vorsorgeauftrag &mdash; einfach,
          rechtssicher und zu fairen Konditionen.
          <ul>
            <li>
              Selbständige oder externe Erstellung eines Vorsorgeauftrages nach
              Ihren Bedürfnissen
            </li>
            <li>
              Mehr Rechtssicherheit dank professionellen, von Anwälten geprüften
              Formulierungen
            </li>
            <li>Keine juristischen Fachkenntnisse vorausgesetzt</li>
            <li>Qualität ohne lange Wartezeiten beim Anwalt oder Notar</li>
          </ul>
        </div>
        <Container textAlign="center" content={<StartChatButton />} />
      </Grid.Column>
      <Grid.Column width={1} />
      <Grid.Column width={4} floated="right">
        <Image
          bordered
          shape="rounded"
          size="huge"
          src={
            template.imageUrl != null
              ? template.imageUrl
              : /*not sure why, but server-rendered the leading / is missing: */
              defaultImage.startsWith("/")
              ? defaultImage
              : `/${defaultImage}`
          }
        />
      </Grid.Column>
    </Grid.Row>
  </Grid>
)

export class ProductDetails extends React.Component<Props, void> {
  static fetchData(params: {}, dispatch: Dispatch) {
    return dispatch(actionCreators.fetchTemplates())
  }

  componentDidMount() {
    const { template } = this.props
    if (!template) {
      this.props.actions.fetchTemplates()
    } else {
      reactGa.event({
        category: "Page View",
        action: `Viewed ${template.name}`,
      })
    }
  }

  handleNewContract = (event: Event) => {
    event.preventDefault()
    const { path, actions } = this.props
    const contractId: TemplateId = "eff49a73-060d-4d0d-9488-9a6df956d1a9"
    actions.createNewContract(contractId, path)
  }

  handleNewFixprice = (event: Event) => {
    event.preventDefault()
    const { path, actions } = this.props
    const fixpriceId: TemplateId = "eb7c65e1-540a-466d-b294-5e41f4d41117"
    actions.createNewFixpriceRequest(fixpriceId, path)
  }

  isContract = () =>
    this.props.template && this.props.template.kind === "Contract"

  render() {
    const {
      template,
      navigation,
      path,
      isLoading,
      isCreating,
      errors,
    } = this.props

    const secondaryNavigation = {
      title: "Private Vorsorge",
      path: "/private#wohnen-und-familie",
    }

    const title = template
      ? template.name +
        (template.kind === "Contract"
          ? " zum Pauschalpreis online erstellen"
          : " zum Fixpreis")
      : ""

    const description = template
      ? stripHtml(template.description["subtitle"] || "")
      : ""

    return (
      <MainLayout
        className={`TemplateDetails ${template ? template.kind : ""}`}
      >
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta itemprop="name" content={title} />
          <meta itemprop="description" content={description} />
          <meta itemprop="image" content={template && template.imageUrl} />
          <meta name="twitter:card" content="product" />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:image" content={template && template.imageUrl} />
          <meta
            name="twitter:data1"
            content={`CHF ${(template && template.price) || ""}`}
          />
          <meta name="twitter:label1" content="Price" />
          <meta property="og:type" content="product" />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={template && template.imageUrl} />
          <meta
            property="og:image:url"
            content={template && template.imageUrl}
          />
          <meta
            property="og:price:amount"
            content={(template && template.price) || ""}
          />
          <meta property="og:price:currency" content="CHF" />
          <meta
            property="og:url"
            content={`https://www.legalgo.ch/${
              navigation ? navigation.path : ""
            }/${path}`}
          />
        </Helmet>

        <Section color="blue">
          {errors ? (
            <ErrorMessage error={errors} />
          ) : !navigation || isLoading ? (
            <Loader active inline="centered" content="Loading" />
          ) : template ? (
            <TemplateHeaderSection
              template={template}
              path={path}
              navigation={navigation}
              isCreating={isCreating}
            />
          ) : (
            <ErrorMessage
              error={{ response: "Dieser Inhalt existiert nicht" }}
            />
          )}
        </Section>
        <Section color="white">
          {navigation && (
            <BreadCrumbs
              crumbs={[
                { name: navigation.title, link: `/${navigation.path}` },
                {
                  name: secondaryNavigation.title,
                  link: `/${navigation.path}#${secondaryNavigation.path}`,
                },
              ]}
            />
          )}
          {template && (
            <Grid stretched stackable columns={16}>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Header as="h1">Unser Angebot für Sie:</Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row stretched>
                <Grid.Column width={8}>
                  <Segment
                    style={{
                      height: "100%",
                    }}
                  >
                    <Item.Group
                      unstackable
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                      }}
                    >
                      <Item itemScope itemType="http://schema.org/Product">
                        <meta itemProp="name" content="Vorsorgeauftrag" />
                        <meta
                          itemProp="description"
                          content={template.description["subtitle"]}
                        />

                        <Item.Content>
                          <Item.Header>BASIS</Item.Header>
                          <Item.Description
                            itemProp="offers"
                            itemScope
                            itemType="http://schema.org/Offer"
                          >
                            <p>
                              Mithilfe unserer Software erstellen Sie selber Ihr
                              Dokument in wenigen Minuten
                            </p>
                            <p>
                              <strong>Der Vorsorgeauftrag beinhaltet:</strong>
                            </p>
                            <ul>
                              <li>
                                Wahl eines umfassenden Vorsorgebeauftragten
                                sowie einer Ersatzperson für alle relevanten
                                Vorsorgebereiche
                              </li>
                              <li>Entschädigung der beauftragten Personen</li>
                              <li>
                                Weitere Wahlmöglichkeiten wie Integration einer
                                Patientenverfügung, etc.
                              </li>
                            </ul>
                            <p>
                              <strong>Sie erhalten:</strong>
                            </p>
                            <ul>
                              <li>
                                Anwaltlich vorformulierter Vorsorgeauftrag
                                angepasst auf Ihre individuellen Angaben
                              </li>
                              <li>
                                Professionelle Vorlage für eine spätere
                                Beurkundung oder als Vorlage für Ihren
                                handschriftlichen Vorsorgeauftrag (das Dokument
                                muss zur Formgültigkeit diesfalls zwingend von
                                Hand abgeschrieben werden).
                              </li>
                            </ul>
                            <link
                              href="http://schema.org/OnlineOnly"
                              itemProp="availability"
                            />
                            <span itemProp="price" content={template.price} />
                            <span
                              itemProp="description"
                              content={template.description["subtitle"]}
                            />
                            <span itemProp="priceCurrency" content="CHF" />
                          </Item.Description>
                        </Item.Content>
                      </Item>
                      <div style={{ flexGrow: 1 }} />
                      <CreateContractButton
                        content="Vorsorgeauftrag selbst erstellen"
                        loading={this.props.isCreating}
                        onClick={this.handleNewContract}
                        fluid
                      />
                      <Item itemScope itemType="http://schema.org/Product">
                        <meta itemProp="name" content={template.name} />
                        <meta
                          itemProp="description"
                          content={template.description["subtitle"]}
                        />
                        <Item.Image size="tiny" src={pdf} />
                        <Item.Content
                          itemProp="offers"
                          itemScope
                          itemType="http://schema.org/Offer"
                        >
                          <link
                            href="http://schema.org/OnlineOnly"
                            itemProp="availability"
                          />
                          <Item.Meta>Dieses Angebot für nur</Item.Meta>
                          <Item.Header>
                            {toRoundedDownCurrencyAmount(template.price)}
                          </Item.Header>
                          <Item.Description>
                            Dokument im PDF Format, optional auch als Word-Datei
                            zur Bearbeitung.
                          </Item.Description>
                          <span itemProp="price" content={template.price} />
                          <span itemProp="description" content={description} />
                          <span itemProp="priceCurrency" content="CHF" />
                        </Item.Content>
                      </Item>
                    </Item.Group>
                  </Segment>
                </Grid.Column>
                <Grid.Column stretched width={8}>
                  <Segment>
                    <Item.Group unstackable>
                      <Item itemScope itemType="http://schema.org/Product">
                        <meta itemProp="name" content="Vorsorgeauftrag" />
                        <meta itemProp="description" content={"blabla"} />
                        <Item.Content>
                          <Item.Header>PREMIUM</Item.Header>
                          <Item.Description
                            itemProp="offers"
                            itemScope
                            itemType="http://schema.org/Offer"
                          >
                            <p>
                              Ein Anwalt und Notar berät Sie ausführlich und
                              erstellt sowie beurkundet Ihren Vorsorgeauftrag
                            </p>
                            <p>
                              <strong>Der Vorsorgeauftrag beinhaltet:</strong>
                            </p>
                            <ul>
                              <li>
                                Keine Einschränkungen: Sämtliche
                                Regelungsinhalte werden nach anwaltlicher
                                Beratung auf ihre individuellen Bedürfnisse
                                massgeschneidert (insbesondere für Unternehmer,
                                bei Eigentümern von Liegenschaften sowie bei
                                Patchwork-Familien geeignet)
                              </li>
                            </ul>
                            <p>
                              <strong>Sie erhalten:</strong>
                            </p>
                            <ul>
                              <li>
                                Maximale Rechtssicherheit durch anwaltliche
                                Beratung
                              </li>
                              <li>
                                15 min kostenlose Erstberatung; erst im
                                Anschluss entscheiden Sie frei, ob Sie das
                                Angebot kostenpflichtig bestellen
                              </li>
                              <li>
                                Notarielle Beurkundung Ihres Vorsorgeauftrages
                                in unserem Notariat in St. Gallen (inkl.
                                notarieller Bestätigung Ihrer Urteilsfähigkeit
                                sowie Ihres Rechtswillens)
                              </li>
                              <li>
                                Mehr Sicherheit beim Vollzug des
                                Vorsorgeauftrages infolge der öffentlichen
                                Beurkundung
                              </li>
                            </ul>
                            <link
                              href="http://schema.org/OnlineOnly"
                              itemProp="availability"
                            />
                            <span itemProp="price" content={template.price} />
                            <span
                              itemProp="description"
                              content={description}
                            />
                            <span itemProp="priceCurrency" content="CHF" />
                          </Item.Description>
                        </Item.Content>
                      </Item>
                      <CreateFixpriceButton
                        content="Vorsorgeauftrag mit Anwalt erstellen"
                        loading={this.props.isCreating}
                        onClick={this.handleNewFixprice}
                        fluid
                      />
                      <Item itemScope itemType="http://schema.org/Product">
                        <meta itemProp="name" content={template.name} />
                        <meta
                          itemProp="description"
                          content={template.description["subtitle"]}
                        />
                        <Item.Image size="tiny" src={lawyer} />
                        <Item.Content
                          itemProp="offers"
                          itemScope
                          itemType="http://schema.org/Offer"
                        >
                          <link
                            href="http://schema.org/OnlineOnly"
                            itemProp="availability"
                          />
                          <Item.Meta>Dieses Angebot für nur</Item.Meta>
                          <Item.Header>
                            {toRoundedDownCurrencyAmount(380)}
                          </Item.Header>
                          <Item.Description>
                            Ein Anwalt erstellt den Vorsorgeauftrag nach Ihren
                            Wünschen.
                          </Item.Description>
                          <span itemProp="price" content={380} />
                          <span itemProp="description" content={description} />
                          <span itemProp="priceCurrency" content="CHF" />
                        </Item.Content>
                      </Item>
                    </Item.Group>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row verticalAlign="top">
                <Grid.Column width={16}>
                  <Header as="h1">Weitere Informationen zum Angebot:</Header>
                  <Accordion
                    entries={[
                      {
                        name: "Was ist ein Vorsorgeauftrag?",
                        content: (
                          <RawHtmlComponent
                            html={template.description["maincontent"]}
                          />
                        ),
                      },
                      {
                        name:
                          "Handschriftlich oder beurkundet – welches Angebot ist das Richtige für mich?",
                        content: (
                          <Fragment>
                            <p>
                              Die Inhalte in Ihrem Vorsorgeauftrag sind im
                              Vorsorgefall zwar grundsätzlich verbindlich. Vor
                              der praktischen Anwendung des Vorsorgeauftrages
                              muss dieser aber von Gesetzes wegen durch die KESB
                              auf seine Formgültigkeit hin geprüft werden. Dies
                              bedeutet nicht, dass die Inhalte und Regelungen in
                              Ihrem Vorsorgeauftrag nicht angewendet werden. Die
                              KESB hat jedoch zu überprüfen, ob die gesetzlichen
                              Formvorschriften des Vorsorgeauftrages eingehalten
                              wurden (bspw. ob der Vorsorgeauftrag komplett
                              handschriftlich oder durch einen Notar beurkundet
                              wurde und ob der Auftraggeber in diesem Zeitpunkt
                              urteilsfähig war). Aus diesem Grund kann die
                              Beurkundung Ihres Vorsorgeauftrages empfehlenswert
                              sein. Dies zumal der Notar in der Urkunde Ihre
                              Urteilsfähigkeit ausdrücklich bestätigen wird und
                              so bei der Genehmigung des Vorsorgeauftrages in
                              der Regel keine langen Diskussionen hierüber
                              entstehen (ebenso muss auch nicht eine persönliche
                              Handschrift oder die Urheberschaft des Dokumentes
                              identifiziert werden).
                            </p>
                            <p>
                              Kurz: Die Verfügungen eines beurkundeten
                              Vorsorgeauftrages können regelmässig schneller und
                              rechtssicherer durchgesetzt werden. Sollten Sie
                              sich für einen handschriftlichen Vorsorgeauftrag
                              entscheiden (mit dem selbst erstellten Dokument
                              von LegalGo als Vorlage), empfiehlt sich die
                              Aufbewahrung mit einem weiteren ebenfalls
                              handschriftlich durch Sie verfassten Dokuments
                              (zur späteren Identifikation Ihrer Unter- sowie
                              Ihrer Handschrift). Für diesen Fall kann
                              zusätzlich die Beglaubigung Ihrer Unterschrift
                              geboten sein.
                            </p>
                            <p>
                              Im Rahmen unseres Premium-Angebots können Sie
                              anlässlich des kostenlosen Erstberatungsgespräches
                              gerne weitere Informationen hierzu in Erfahrung
                              bringen.
                            </p>
                          </Fragment>
                        ),
                      },
                    ]}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8}>
                  <HowContractsWork
                    icon="question"
                    content="So erstellen Sie Ihren Vertrag selbst online"
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <HowFixpricesWork
                    icon="question"
                    content="So läuft die Zusammenarbeit mit einem Anwalt"
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={10}>
                  <Header as="h1" content="Ihre Anwälte" />
                  <LazyExpertRecommendation
                    specialty={{
                      name: secondaryNavigation.title,
                      id: secondaryNavigation.path,
                    }}
                  />
                </Grid.Column>
                {false && (
                  <Grid.Column width={6}>
                    <Header as="h1" content="Ihre Anwälte" />
                    <LatestPost
                      api={config.api.prismic}
                      baseUrl={config.route.blog.index}
                    />
                  </Grid.Column>
                )}
              </Grid.Row>
            </Grid>
          )}
        </Section>
      </MainLayout>
    )
  }
}

export type OwnProps = {
  match: Match,
}

function mapStateToProps(state: State, ownProps: OwnProps) {
  const { primaryCategory, name } = ownProps.match.params
  const template = selectors.getTemplateByPath(
    state,
    "einfacher-vorsorgeauftrag"
  )
  const navigation = selectors.getPrimaryNavigation(state, primaryCategory)
  const path =
    primaryCategory != null && name != null
      ? `/${primaryCategory}/vorsorgeauftrag`
      : ""
  return {
    template,
    navigation,
    path,
    isLoading: selectors.isFetchingTemplates(state),
    isCreating:
      selectors.isCreatingContract(state) ||
      selectors.isCreatingFixpriceRequest(state),
    errors:
      selectors.getFetchingTemplatesErrors(state) ||
      selectors.getCreateContractErrors(state) ||
      selectors.getCreateFixpriceRequestErrors(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProductDetails)
)
