// @flow
import { combineReducers } from "redux"

import expertsReducer, * as expertsSelectors from "./ExpertsModule/reducer"
import * as expertsActionCreators from "./ExpertsModule/actions"
import type { State as ExpertsState } from "./ExpertsModule/reducer"
import type { Action as ExpertsAction } from "./ExpertsModule/actionTypes"

export default combineReducers({
  experts: expertsReducer,
})

export const actionCreators = {
  ...expertsActionCreators,
}

export const selectors = {
  ...expertsSelectors,
}

export type State = {
  experts: ExpertsState,
}

export type Action = ExpertsAction
