// @flow
import "whatwg-fetch"
import config from "../config"
import hasSessionStorage from "../util/hasSessionStorage"

/**
 * The definition of an API response.
 *
 * Needed to return always the same structure from a API method.
 */
export class APIResponse<T> {
  code: string
  description: string
  details: T
  constructor(code: string, description: string, details: T) {
    this.code = code
    this.description = description
    this.details = details
  }
}

/**
 * An API error that can transport an `APIResponse`.
 */
export class APIError<T> extends Error {
  response: APIResponse<T>
  constructor(response: APIResponse<T>) {
    super(response.description)
    this.response = response
  }
}

/**
 * Provides helpers for the API implementations.
 */
export default class API {
  errorMsg = "An error occurred, please try again later!"

  request<T>(route: string, method: string = "GET"): Promise<APIResponse<T>> {
    return this.statusHandler(
      fetch(`${config.apiBaseUrl}/${route}`, {
        method,
        headers: this.getAuthHeader(),
        credentials: "include", // Needed to allow cookies with CORS, see above link
      })
    )
  }

  jsonRequest<T, Payload>(
    route: string,
    json: Payload,
    method: string = "POST"
  ): Promise<APIResponse<T>> {
    return this.statusHandler(
      fetch(`${config.apiBaseUrl}/${route}`, {
        method,
        headers: {
          ...this.getAuthHeader(),
          "Content-Type": "application/json; charset=utf-8",
          Accept: "application/json",
        },
        credentials: "include", // Needed to allow cookies with CORS, see above link
        body: JSON.stringify(json),
      })
    )
  }

  formRequest<T>(
    route: string,
    body: FormData,
    method: string = "POST"
  ): Promise<APIResponse<T>> {
    return this.statusHandler(
      fetch(`${config.apiBaseUrl}/${route}`, {
        method,
        headers: this.getAuthHeader(),
        credentials: "include", // Needed to allow cookies with CORS, see above link
        body,
      })
    )
  }

  statusHandler<T>(promise: Promise<Response>): Promise<APIResponse<T>> {
    const self = this
    return promise
      .then(response => {
        const xAuthToken = response.headers.get("X-Auth-Token")
        if (xAuthToken && hasSessionStorage()) {
          sessionStorage.setItem("X-Auth-Token", xAuthToken)
        }

        // We return a resolved promise with the APIResponse for all 2xx status codes
        if (response.status >= 200 && response.status <= 299) {
          return response.json()
        }

        // We return a rejected promise for all 4xx errors
        if (response.status >= 400 && response.status <= 499) {
          return response.json().then(json => {
            return Promise.reject(new APIError(json))
          })
        }

        // We return a rejected promise for all other status codes
        const msg =
          config.env === "production"
            ? self.errorMsg
            : `Unexpected response status: ${response.status}`
        return Promise.reject(
          new APIError(new APIResponse("unexpected.status", msg))
        )
      })
      .catch(e => {
        // If the exception is already an APIError then we throw it again
        if (e.response !== undefined) {
          throw e
        }

        const msg =
          config.env === "production"
            ? self.errorMsg
            : `Cannot process request; Got exception: ${e.message}`
        throw new APIError(new APIResponse("fatal.error", msg))
      })
  }

  getAuthHeader() {
    if (hasSessionStorage()) {
      const token = sessionStorage.getItem("X-Auth-Token")
      if (token != null) {
        return {
          "X-Auth-Token": token,
        }
      } else {
        return {}
      }
    } else {
      return {}
    }
  }
}
