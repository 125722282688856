// @flow
import "react-app-polyfill/ie11"

import WebFont from "webfontloader"
import { hydrate, render } from "react-dom"
import Raven from "raven-js"
import browserUpdate from "browser-update"
import ReactGA from "react-ga"

import "./util/cancelAnimationFrame"
import router from "./store/router"
import config from "./config"

import "./styles/semantic/dist/semantic.min.css"
import "./index.css"

if (config.env === "development") {
  // no logging
} else {
  Raven.config(config.api.sentry).install()
}

WebFont.load({
  google: {
    families: ["Roboto:400,700"],
  },
  monotype: {
    projectId: "cf22911f-30e6-4e47-a513-6c3e7a38644e",
    loadAllFonts: false, //(optional, loads all project fonts)
  },
  timeout: 2000,
})

browserUpdate({
  required: { e: -4, f: -3, o: -3, s: -1, c: -3 },
  insecure: true,
  unsupported: true,
  mobile: false,
  api: 2018.06,
  newwindow: true,
  l: "de",
  onshow: ({ text }) => {
    ReactGA.event({
      category: "Browser Update",
      action: text,
    })
  },
  text:
    "Ihr Browser {brow_name} ist zu alt. Unsere Seite wird möglicherweise nicht richtig funktionieren: <a{up_but}>Aktualisieren</a> oder <a{ignore_but}>Ignorieren</a>.",
})

const root = document.getElementById("root")

if (config.env === "development" && root) {
  render(router, root)
} else if (root) {
  hydrate(router, root)
}
