// @flow

export function locateUser() {
  return function(dispatch: Dispatch) {
    dispatch({ type: "LOCATE_USER_REQUEST" })
    return fetch("https://freegeoip.net/json/")
      .then(response => response.json())
      .then(({ region_name }) => {
        dispatch({
          type: "LOCATE_USER_SUCCESS",
          canton: translateRegionName(region_name),
        })
      })
      .catch(error => {
        dispatch({ type: "LOCATE_USER_FAILURE" })
      })
  }
}

function translateRegionName(english: string) {
  switch (english) {
    case "Aargau":
      return "Aargau"
    case "Appenzell Ausserrhoden":
      return "Appenzell Ausserrhoden"
    case "Appenzell Innerrhoden":
      return "Appenzell Innerrhoden"
    case "Basel-Landschaft":
      return "Basel-Landschaft"
    case "Basel-City":
      return "Basel-Stadt"
    case "Bern":
      return "Bern"
    case "Fribourg":
      return "Freiburg"
    case "Geneva":
      return "Genf"
    case "Glarus":
      return "Glarus"
    case "Grisons":
      return "Graubünden"
    case "Jura":
      return "Jura"
    case "Lucerne":
      return "Luzern"
    case "Neuchâtel":
      return "Neuenburg"
    case "Nidwalden":
      return "Nidwalden"
    case "Obwalden":
      return "Obwalden"
    case "Schaffhausen":
      return "Schaffhausen"
    case "Schwyz":
      return "Schwyz"
    case "Solothurn":
      return "Solothurn"
    case "Saint Gallen":
      return "St. Gallen"
    case "Ticino":
      return "Tessin"
    case "Thurgau":
      return "Thurgau"
    case "Uri":
      return "Uri"
    case "Vaud":
      return "Vaud"
    case "Valais":
      return "Valais"
    case "Zug":
      return "Zug"
    case "Zurich":
      return "Zürich"
    default:
      return null
  }
}
