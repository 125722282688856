// @flow
import Alert from "react-s-alert"
import { push } from "connected-react-router"

import type {
  Dispatch,
  GetState,
  Services,
} from "../../../../store/rootReducer"

import config from "../../../../config"

export const recoverPassword = (email: string) => async (
  dispatch: Dispatch,
  getState: GetState,
  { authApi }: Services
) => {
  try {
    dispatch({ type: "AUTH_RECOVER_PASSWORD_REQUESTED" })
    const response = await authApi.recoverPassword({ email })
    Alert.success(response.description)
    dispatch({ type: "AUTH_RECOVER_PASSWORD_SUCCEEDED" })
    dispatch(push(config.route.auth.signIn))
  } catch (e) {
    Alert.error(e.response.description)
    dispatch({ type: "AUTH_RECOVER_PASSWORD_FAILED" })
  }
}
