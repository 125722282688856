// @flow
import { isApiCallInProgress, getApiCallFailure } from "../ApiModule/reducer"

import type { State as ApplicationState, Action } from "../../store/rootReducer"

export type State = {
  +signedUp: boolean,
  +isSigningUp: boolean,
  +signUpErrors: ?Error,
  +reminderSent: boolean,
  +isSendingReminder: boolean,
  +reminderErrors: ?Error,
  +isSendingSuggestion: boolean,
}

const initialState = {
  signedUp: false,
  isSigningUp: false,
  signUpErrors: null,
  reminderSent: false,
  isSendingReminder: false,
  reminderErrors: null,
  isSendingSuggestion: false,
}

export default function reduce(
  state: State = initialState,
  action: Action
): State {
  switch (action.type) {
    case "NEWSLETTER_SIGNUP_REQUESTED":
      return {
        ...state,
        isSigningUp: true,
        signUpErrors: null,
      }
    case "NEWSLETTER_SIGNUP_SUCCEEDED":
      return {
        ...state,
        isSigningUp: false,
        signedUp: true,
      }
    case "NEWSLETTER_SIGNUP_FAILED":
      return {
        ...state,
        isSigningUp: false,
        signUpErrors: action.error,
      }
    case "DISMISS_NEWSLETTER_SIGNUP_ERRORS":
      return {
        ...state,
        signUpErrors: null,
      }
    case "REMINDER_EMAIL_REQUESTED":
      return {
        ...state,
        isSendingReminder: true,
        reminderErrors: null,
      }
    case "REMINDER_EMAIL_SUCCEEDED":
      return {
        ...state,
        isSendingReminder: false,
        reminderSent: true,
      }
    case "REMINDER_EMAIL_FAILED":
      return {
        ...state,
        isSendingReminder: false,
        reminderErrors: action.error,
      }
    case "DISMISS_SEND_REMINDER_ERRORS":
      return {
        ...state,
        reminderErrors: null,
      }
    case "SEND_SUGGESTION_REQUEST":
      return {
        ...state,
        isSendingSuggestion: true,
      }
    default:
      return state
  }
}

export const isSignedUp = ({ common }: ApplicationState): boolean =>
  common.email.signedUp

export const isSigningUp = ({ common }: ApplicationState): boolean =>
  common.email.isSigningUp

export const getSignupErrors = ({ common }: ApplicationState) =>
  common.email.signUpErrors

export const isReminderSent = ({ common }: ApplicationState): boolean =>
  common.email.reminderSent

export const isSendingReminder = ({ common }: ApplicationState): boolean =>
  common.email.isSendingReminder

export const getReminderErrors = ({ common }: ApplicationState) =>
  common.email.reminderErrors

export const isSendingSuggestion = (state: ApplicationState): boolean => {
  return isApiCallInProgress(state, "SEND_SUGGESTION_REQUEST")
}

export const getSendSuggestionErrors = (state: ApplicationState) => {
  return getApiCallFailure(state, "SEND_SUGGESTION_REQUEST")
}
