// @flow
import { createSelector, createSelectorCreator, defaultMemoize } from "reselect"

import { selectors } from "../../../../modules"

import type {
  State as ApplicationState,
  Action,
} from "../../../../store/rootReducer"

import { Map, List, is } from "immutable"

function getInitialValueFromSessionStorage(item: string) {
  if (typeof sessionStorage !== "undefined") {
    const item = sessionStorage.getItem("item")
    if (item != null) {
      return JSON.parse(item)
    }
    return null
  }
  return null
}

const initialState = {
  entities: Map(),
  specialtiesFilter: getInitialValueFromSessionStorage("specialtiesFilter"),
  cantonsFilter: getInitialValueFromSessionStorage("cantonsFilter"),
  selectedCanton: getInitialValueFromSessionStorage("selectedCanton"),
}

export type State = {
  +entities: Map<ExpertId, Expert>,
  +specialtiesFilter: ?Array<SpecialtyId>,
  +cantonsFilter: ?Array<string>,
  +selectedCanton: ?string,
}

export default function reduce(
  state: State = initialState,
  action: Action
): State {
  switch (action.type) {
    case "FETCH_EXPERTS_SUCCESS":
      return {
        ...state,
        entities: Map(action.response.map(t => [t.id, t])),
      }
    case "CREATE_EXPERT_SUCCESS":
      return {
        ...state,
      }
    case "SAVE_EXPERT_SUCCESS":
    case "FETCH_EXPERT_SUCCESS": {
      const { response: expert } = action
      return {
        ...state,
        entities: state.entities.set(expert.id, expert),
      }
    }
    case "TOGGLE_EXPERT_ACTIVE_SUCCESS": {
      return {
        ...state,
        entities: state.entities.update(action.expertId, expert => {
          return {
            ...expert,
            isActive: !expert.isActive,
          }
        }),
      }
    }
    case "DELETE_EXPERT_SUCCESS": {
      const { expertId } = action
      return {
        ...state,
        entities: state.entities.remove(expertId),
      }
    }
    case "FILTER_EXPERT_FIELDS": {
      return {
        ...state,
        specialtiesFilter: action.filter,
      }
    }
    case "FILTER_EXPERT_CANTON": {
      return {
        ...state,
        cantonsFilter: action.filter,
      }
    }
    case "SELECT_EXPERT_CANTON": {
      return {
        ...state,
        selectedCanton: action.canton,
      }
    }
    default:
      return state
  }
}

export const getExperts = (state: ApplicationState): List<Expert> => {
  return state.experts.experts.entities.toList()
}

export const getExpertsBySpecialty = (
  state: ApplicationState,
  field: Specialty
): List<Expert> => {
  const experts = getExperts(state)
  return experts.filter(
    ({ specialties }) => specialties.findIndex(({ id }) => field.id === id) > -1
  )
}

const immutableJsSelectorCreator = createSelectorCreator(defaultMemoize, is)

// Changes every minute to get a new random number expert
const getTimeComponent = () => Math.floor(new Date().getTime() / (1000 * 60))

export const getRandomExpert = immutableJsSelectorCreator(
  getExperts,
  getTimeComponent,
  (experts, time) => {
    if (Math.random() < 0.8) {
      return experts.find(({ path }) => path === "kevin-kleger")
    } else {
      return experts.find(({ path }) => path === "daniel-bleuer")
    }
  }
)

/* Currently unused because we don't have any external experts */
export const getRecommendedExpertBySpecialty = (
  state: ApplicationState,
  field: Specialty
) => {
  const { selectedCanton } = state.experts.experts
  const candidates = getExpertsBySpecialty(state, field)

  if (candidates.isEmpty()) {
    const allExperts = getExperts(state)
    // randomly select daniel or kevin to handle this
    if (Math.random() < 0.5) {
      return allExperts.find(({ path }) => path === "kevin-kleger")
    } else {
      return allExperts.find(({ path }) => path === "daniel-bleuer")
    }
  }

  if (selectedCanton != null) {
    const expertFromSelectedCanton = candidates.filter(
      ({ canton }) => canton === selectedCanton
    )
    if (!expertFromSelectedCanton.isEmpty()) {
      return expertFromSelectedCanton.get(
        Math.floor(Math.random() * expertFromSelectedCanton.size)
      )
    }
  }

  const userLocation = selectors.getUserLocation(state)

  if (userLocation != null) {
    const expertFromUserCanton = candidates.find(
      ({ canton }) => canton === userLocation
    )
    if (expertFromUserCanton) {
      return expertFromUserCanton
    }
  }

  return candidates.get(Math.floor(Math.random() * candidates.size))
}

export const getExpertByPath = (
  state: ApplicationState,
  path: ?string
): ?Expert => {
  return state.experts.experts.entities.find(expert => expert.path === path)
}

export const getExpertById = (
  state: ApplicationState,
  id: ExpertId
): ?Expert => {
  return state.experts.experts.entities.get(id)
}

export const getSpecialtiesFilter = (
  state: ApplicationState
): ?Array<SpecialtyId> => {
  return state.experts.experts.specialtiesFilter
}

export const getExpertCantonsFilter = (
  state: ApplicationState
): ?Array<string> => {
  return state.experts.experts.cantonsFilter
}
/*
export const getCantonsFromExperts = createSelector(
  getExperts,
  experts => Set(experts.map(({canton}) => canton)).sort().toArray()
)*/

export const getFilteredExperts = createSelector(
  getExperts,
  getSpecialtiesFilter,
  getExpertCantonsFilter,
  (experts, specialtiesFilter, cantonsFilter) => {
    return experts.filter(expert => {
      if (cantonsFilter) {
        const matches = cantonsFilter.indexOf(expert.canton) > -1
        if (!matches) {
          return false
        }
      }
      if (specialtiesFilter) {
        const specialties = expert.specialties.map(({ id }) => id)
        const matches =
          specialtiesFilter.filter(f => specialties.indexOf(f) > -1).length > 0
        if (!matches) {
          return false
        }
      }
      return true
    })
  }
)

export const isFetchingExperts = (state: ApplicationState): boolean => {
  return selectors.isApiCallInProgress(state, "FETCH_EXPERTS_REQUEST")
}

export const isFetchingExpert = (state: ApplicationState): boolean => {
  return selectors.isApiCallInProgress(state, "FETCH_EXPERT_REQUEST")
}

export const isSavingExpert = (state: ApplicationState): boolean => {
  return selectors.isApiCallInProgress(state, "SAVE_EXPERT_REQUEST")
}

export const isCreatingExpert = (state: ApplicationState): boolean => {
  return selectors.isApiCallInProgress(state, "CREATE_EXPERT_REQUEST")
}

export const getFetchingExpertsErrors = (state: ApplicationState) => {
  return selectors.getApiCallFailure(state, "FETCH_EXPERTS_REQUEST")
}
export const getFetchingExpertErrors = (state: ApplicationState) => {
  return selectors.getApiCallFailure(state, "FETCH_EXPERT_REQUEST")
}

export const getCreateExpertErrors = (state: ApplicationState): ?string => {
  const errors = selectors.getApiCallFailure(state, "CREATE_EXPERT_REQUEST")
  if (errors) {
    return errors.status
  } else {
    return null
  }
}

export const getSaveExpertErrors = (state: ApplicationState): ?string => {
  const errors = selectors.getApiCallFailure(state, "SAVE_EXPERT_REQUEST")
  if (errors) {
    return errors.status
  } else {
    return null
  }
}
