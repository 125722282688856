// @flow

import { push } from "connected-react-router"
import ReactGA from "react-ga"

import { getFixpriceRequestId } from "./reducer"
import { trackFixpriceRequestSentConversion } from "../../util/utils"

import type { State as ApplicationState } from "../../store/rootReducer"

export function goToPage(page: number) {
  return push({
    search: `page=${page}`,
  })
}

export function fetchFixpriceRequest(frid: FixpriceRequestId): ApiCall {
  return {
    CALL_API: {
      endpoint: `/fixpricerequests/${frid}`,
      config: {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      },
      types: [
        "FETCH_FIXPRICEREQUEST_REQUEST",
        "FETCH_FIXPRICEREQUEST_SUCCESS",
        "FETCH_FIXPRICEREQUEST_FAILURE",
      ],
    },
  }
}

export function createNewFixpriceRequest(templateId: TemplateId, path: string) {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    return dispatch({
      CALL_API: {
        endpoint: `/fixpricerequests/new/${templateId}`,
        config: {
          method: "POST",
          headers: {
            Accept: "application/json",
          },
        },
        types: [
          "CREATE_NEW_FIXPRICEREQUEST_REQUEST",
          "CREATE_NEW_FIXPRICEREQUEST_SUCCESS",
          "CREATE_NEW_FIXPRICEREQUEST_FAILURE",
        ],
      },
    }).then(() => {
      const id = getFixpriceRequestId(getState())
      if (id != null) {
        dispatch(push(`${path}/${id}`))
      }
    })
  }
}

export function autoSaveFixpriceRequest(
  frid: FixpriceRequestId,
  formData: Array<FormData>
) {
  return {
    CALL_API: {
      endpoint: `/fixpricerequests/${frid}`,
      config: {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      },
      types: [
        "AUTOSAVE_FIXPRICEREQUEST_REQUEST",
        "AUTOSAVE_FIXPRICEREQUEST_SUCCESS",
        "AUTOSAVE_FIXPRICEREQUEST_FAILURE",
      ],
      parameter: {
        frid,
        formData,
      },
    },
  }
}

export function completeFixpriceRequest(
  frid: FixpriceRequestId,
  formData: Array<FormData>
) {
  return {
    CALL_API: {
      endpoint: `/fixpricerequests/${frid}/complete`,
      config: {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      },
      types: [
        "COMPLETE_FIXPRICEREQUEST_REQUEST",
        "COMPLETE_FIXPRICEREQUEST_SUCCESS",
        "COMPLETE_FIXPRICEREQUEST_FAILURE",
      ],
      onSuccess: () => {
        trackFixpriceRequestSentConversion({
          fixpriceRequestId: frid,
        })
      },
    },
  }
}

export function submitFixpriceRequest(frid: FixpriceRequestId) {
  return {
    CALL_API: {
      endpoint: `/fixpricerequests/${frid}/submit`,
      config: {
        method: "POST",
      },
      types: [
        "SUBMIT_FIXPRICEREQUEST_REQUEST",
        "SUBMIT_FIXPRICEREQUEST_SUCCESS",
        "SUBMIT_FIXPRICEREQUEST_FAILURE",
      ],
    },
  }
}

export function addCustomerDataFixprice(orderId: OrderId, formData: FormData) {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    return dispatch({
      CALL_API: {
        endpoint: `/orders/${orderId}/customer`,
        config: {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        },
        types: [
          "CUSTOMER_DATA_FIXPRICE_REQUEST",
          "CUSTOMER_DATA_FIXPRICE_SUCCESS",
          "CUSTOMER_DATA_FIXPRICE_FAILURE",
        ],
        onSuccess: ({ id }) => {
          if (id) {
            dispatch(submitFixpriceRequest(id))
          }
        },
      },
    })
  }
}

export function orderFixpriceByInvoice(
  orderId: OrderId,
  formData: FormData,
  paymentUrl: string
) {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    return dispatch({
      CALL_API: {
        endpoint: `/orders/${orderId}/paymentMethod`,
        config: {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        },
        types: [
          "ORDER_FIXPRICE_REQUEST",
          "ORDER_FIXPRICE_SUCCESS",
          "ORDER_FIXPRICE_FAILURE",
        ],
        onSuccess: fixprice => {
          dispatch({ type: "REDIRECTING_TO_PAYMENT" })
          try {
            if (fixprice.template && fixprice.order) {
              ReactGA.plugin.execute("ecommerce", "addTransaction", {
                id: orderId,
                revenue: fixprice.order.totalPayable,
              })
              ReactGA.plugin.execute("ecommerce", "addItem", {
                id: orderId,
                name: fixprice.template.name,
                sku: fixprice.template.id,
                category: "Fixprice",
                price: fixprice.template.price,
                quantity: "1",
              })
              ReactGA.plugin.execute("ecommerce", "send")
              ReactGA.plugin.execute("ecommerce", "clear")
            }
          } catch (e) {
            console.error(e)
          }
          setTimeout(() => {
            window.location.href = paymentUrl
          }, 1000)
        },
      },
    })
  }
}

export function addDiscountCodeFixprice(
  orderId: OrderId,
  discountCode: ?string
) {
  const body = discountCode != null ? { code: discountCode } : {}
  return {
    CALL_API: {
      endpoint: `/orders/${orderId}/discount`,
      config: {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      },
      types: [
        "DISCOUNT_CODE_FIXPRICE_REQUEST",
        "DISCOUNT_CODE_FIXPRICE_SUCCESS",
        "DISCOUNT_CODE_FIXPRICE_FAILURE",
      ],
    },
  }
}
