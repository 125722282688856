// @flow

import { isApiCallInProgress, getApiCallFailure } from "../ApiModule/reducer"
import type { State as ApplicationState, Action } from "../../store/rootReducer"

export type State = {
  +canton: ?string,
}

const initialState = {
  canton: null,
}

export default function reduce(
  state: State = initialState,
  action: Action
): State {
  switch (action.type) {
    case "LOCATE_USER_SUCCESS":
      return {
        ...state,
        canton: action.canton,
      }
    default:
      return state
  }
}

export const getUserLocation = (state: ApplicationState): ?string => {
  return state.common.location.canton
}

export const isGettingUserLocation = (state: ApplicationState): boolean => {
  return isApiCallInProgress(state, "LOCATE_USER_REQUEST")
}

export const getUserLocationErrors = (state: ApplicationState) => {
  return getApiCallFailure(state, "LOCATE_USER_REQUEST")
}
