// @flow
import Alert from "react-s-alert"
import ReactGA from "react-ga"

import type { Dispatch, GetState, Services } from "../../store/rootReducer"

export function newsletterSignup(email: string) {
  return async (
    dispatch: Dispatch,
    getState: GetState,
    { emailAPI }: Services
  ) => {
    dispatch({ type: "NEWSLETTER_SIGNUP_REQUESTED" })
    try {
      await emailAPI.newsletterSignup(email)
      dispatch({
        type: "NEWSLETTER_SIGNUP_SUCCEEDED",
      })
      // TODO move to central listener/reducer!
      ReactGA.event({
        category: "Newsletter",
        action: "Newsletter Signed Up",
      })
    } catch (error) {
      Alert.error(error.response.description)
      dispatch({ type: "NEWSLETTER_SIGNUP_FAILED", error })
    }
  }
}

export function dismissNewsletterSignupErrors() {
  return { type: "DISMISS_NEWSLETTER_SIGNUP_ERRORS" }
}

export function sendReminderEmail(id: string, email: string, url: string) {
  return async (
    dispatch: Dispatch,
    getState: GetState,
    { emailAPI }: Services
  ) => {
    dispatch({ type: "REMINDER_EMAIL_REQUESTED" })
    try {
      await emailAPI.sendReminderEmail(id, email, url)
      dispatch({
        type: "REMINDER_EMAIL_SUCCEEDED",
      })
      // TODO move to central listener/reducer!

      ReactGA.event({
        category: "Reminder Email",
        action: "Send Reminder",
      })
    } catch (error) {
      Alert.error(error.response.description)
      dispatch({ type: "REMINDER_EMAIL_FAILED", error })
    }
  }
}

export function dismissSendReminderErrors() {
  return { type: "DISMISS_SEND_REMINDER_ERRORS" }
}

export function sendSuggestion(email: string, suggestion: string): ApiCall {
  ReactGA.event({
    category: "Suggestion",
    action: "Send Suggestion",
  })

  return {
    CALL_API: {
      endpoint: "/suggestion",
      config: {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, suggestion }),
      },
      types: [
        "SEND_SUGGESTION_REQUEST",
        "SEND_SUGGESTION_SUCCESS",
        "SEND_SUGGESTION_FAILURE",
      ],
    },
  }
}
