// @flow

import type { State as ApplicationState, Action } from "../../store/rootReducer"

import { Map } from "immutable"

type CallState =
  | "INPROGRESS"
  | "SUCCEEDED"
  | "DISMISSED"
  | { state: "FAILED", statusCode: number, status: string, response: ?ApiError }

const initialState = {
  calls: Map(),
}

export type State = {
  +calls: Map<string, CallState>,
}

export default function reduce(
  state: State = initialState,
  action: Action
): State {
  // Don't know why, but sometimes calls is unfdefined?!
  const calls = state.calls || Map()
  switch (action.type) {
    case "API_CALL_STARTED": {
      return {
        ...state,
        calls: calls.set(action.call, "INPROGRESS"),
      }
    }
    case "API_CALL_SUCCEEDED": {
      return {
        ...state,
        calls: calls.set(action.call, "SUCCEEDED"),
      }
    }
    case "API_CALL_FAILED": {
      const { statusCode, status, response } = action
      return {
        ...state,
        calls: calls.set(action.call, {
          state: "FAILED",
          statusCode,
          status,
          response,
        }),
      }
    }
    case "API_CALL_DISMISS": {
      return {
        ...state,
        calls: calls.set(action.call, "DISMISSED"),
      }
    }
    case "API_CALL_DISMISS_ALL": {
      return {
        ...state,
        calls: calls.map(call => "DISMISSED"),
      }
    }
    default:
      return state
  }
}

/* Returns false if the API has never been called.
   Can be used to fetch ininital data */

export const hasApiBeenCalled = (
  state: ApplicationState,
  call: string
): boolean => {
  // No clue why, but calls is sometimes undefined?
  if (!state.common.api.calls) {
    return false
  }
  return state.common.api.calls.has(call)
}

export const isApiCallInProgress = (
  state: ApplicationState,
  call: string
): boolean => {
  // No clue why, but calls is sometimes undefined?
  if (!state.common.api.calls) {
    return false
  }
  const action = state.common.api.calls.get(call, undefined)
  return action ? action === "INPROGRESS" : false
}

export const getApiCallFailure = (state: ApplicationState, call: string) => {
  // No clue why, but calls is sometimes undefined?
  if (!state.common.api.calls) {
    return null
  }
  const action = state.common.api.calls.get(call, undefined)
  if (action && typeof action === "object" && action.state === "FAILED") {
    return action
  } else {
    return null
  }
}
