// @flow

import React from "react"
import { Grid, Header, List } from "semantic-ui-react"

import paperclip from "../../../styles/images/paperclip-transparent.png"
import seal from "../../../styles/images/seal.png"

import "./IntroSectionContent.css"

export type Props = {
  numberOfCompletedContracts: number,
}

export default function IntroSectionContent({
  numberOfCompletedContracts,
}: Props) {
  return (
    <Grid className="HeaderBar" stackable>
      <Grid.Column width={11}>
        <Header inverted as="h1">
          Anwaltsleistungen für Private und Unternehmen
        </Header>
        <Header inverted as="h2">
          Professionell. Preiswert. Transparent.
        </Header>
      </Grid.Column>
      <Grid.Column width={5}>
        <div className="thefive">
          <img className="paperclip" alt="" src={paperclip} />
          <List>
            <List.Item>
              <List.Icon color="teal" name="checkmark" />
              <List.Content>
                In wenigen Klicks zu Ihrem individuellen Vertrag oder Dokument
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon color="teal" name="checkmark" />
              <List.Content>
                Mehr Rechtssicherheit zu fairen Preisen und ohne versteckte
                Kosten
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon color="teal" name="checkmark" />
              <List.Content>
                Kostenlose Rechtsdokumente für den Alltag
              </List.Content>
            </List.Item>
          </List>
          <img className="seal" alt="" src={seal} />
          <div className="seal">
            <span className="count">{numberOfCompletedContracts}</span>
            <br />Dokumente<br />erstellt
          </div>
        </div>
      </Grid.Column>
    </Grid>
  )
}
