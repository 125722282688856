// @flow

import * as React from "react"
import {
  Container,
  Grid,
  Item,
  Header,
  Segment,
  Image,
} from "semantic-ui-react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Link } from "react-router-dom"
import LazyLoad from "react-lazyload"

import { selectors, actionCreators } from "../../modules"
import LazyLoadedModule from "../../components/LazyLoadedModule"

import doc from "../../styles/images/document-doc.jpg"
import lawyer from "../../styles/images/lawyer.jpg"
import step1 from "../../styles/images/contract-step1.png"
import step2 from "../../styles/images/contract-step2.png"
import step3 from "../../styles/images/contract-step3.png"
import step4 from "../../styles/images/contract-step4.png"

import "./HowItWorks.css"
import SuggestContent from "../../containers/SuggestContent"
import CatalogOverview from "../../components/CatalogOverview"
import MainLayout from "../../layouts/MainLayout"
import Meta from "../../components/Meta"
import Section from "../../components/Section"

class SamplePageForm extends React.Component<{}, { formData: FormData }> {
  static fetchData() {
    return Promise.resolve({})
  }

  state = {
    formData: {},
  }

  handleChange = ({ formData }) => this.setState({ formData })

  render() {
    const formSchema = {
      type: "object",
      properties: {
        Name: {
          type: "string",
          title: "Geben Sie hier zum Beispiel Ihren Namen ein:",
        },
        Array: {
          type: "array",
          title: "Je nach Vorlage können Sie auch freie Aufzählungen machen.",
          items: {
            type: "string",
          },
        },
        EnumList: {
          type: "string",
          title: "Oder aus einer Liste vordefinierter Texte auswählen:",
          minItems: 1,
          enum: ["erste", "zweite", "dritte"],
          enumNames: ["Eins", "Zwei", "Drei"],
        },
        Bool: {
          type: "boolean",
          title: "Klicken Sie hier um diese Angabe auszuwählen",
          description:
            "Noch einfacher sind Angaben die sie einfach ein- oder ausschalten können:",
        },
      },
    }

    const uiSchema = {
      Name: {
        "ui:help": "Ihr Name wird nun direkt in die Vorschau eingefügt.",
      },
    }
    const previewTemplate = `
      Hier sehen Sie eine Vorschau Ihres Dokuments, damit Sie wissen in welchem Kontext Ihre Ergänzungen im fertigen Dokument stehen werden. Das Dokument wird noch weitere Abschnitte enthalten die aber nicht in der Vorschau angezeigt werden.

      $Name füllt gerade eine Vorlage aus.

      Das ist eine Aufzählungsliste: #foreach($Eintrag in $Array)
      <br/>- $Eintrag#end
      <br/><br/>
      Sie haben die $EnumList Auswahl getroffen.

      #if($Bool)Sie haben die zusätzliche Angabe eingeschaltet! #end
    `
    return (
      <div className="SamplePageForm">
        <LazyLoadedModule load={() => import("../../legalgo-contractomat")}>
          {({ TemplatePageForm }) => (
            <TemplatePageForm
              templateName="Ihr Vertrag"
              formSchema={formSchema}
              uiSchema={uiSchema}
              formData={this.state.formData}
              previewFormData={this.state.formData}
              onChange={this.handleChange}
              onInitializeDefaults={this.handleChange}
              loading={false}
              isFirstPage={false}
              page={0}
              isLastPage={true}
              previewTemplate={previewTemplate}
            />
          )}
        </LazyLoadedModule>
      </div>
    )
  }
}

export type Props = {
  navigation: Navigation,
  templates: Array<Template>,
  actions: {
    fetchTemplates: () => ApiCall,
  },
}

class HowItWorksTemplates extends React.Component<Props, void> {
  static fetchData(params: {}, dispatch: Dispatch) {
    return dispatch(actionCreators.fetchTemplates())
  }

  componentDidMount() {
    if (this.props.templates.length === 0) {
      this.props.actions.fetchTemplates()
    }
  }

  render() {
    const { navigation, templates } = this.props

    const title = "LegalGo Verträge und Rechtsdokumente"
    const description =
      "Mit LegalGo in wenigen Schritten zu Ihrem massgeschneiderten Rechtsdokument"

    return (
      <MainLayout className="HowItWorks">
        <Meta title={title} description={description} />
        <Section as={Container} color="white">
          <Grid stackable>
            <Grid.Row>
              <Header as="h1" content="So funktioniert LegalGo" />
            </Grid.Row>
            <Grid.Row>
              <Header
                as="h2"
                content="In wenigen Schritten zu Ihrem massgeschneiderten Rechtsdokument."
              />
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3}>
                <LazyLoad height="160px" once>
                  <Image src={step1} />
                </LazyLoad>
              </Grid.Column>
              <Grid.Column width={13}>
                <Segment stacked>
                  <Header as="h3">
                    Vertrags- oder Dokumentvorlage auswählen
                  </Header>
                  <p>
                    Wählen Sie eine der Dokumentvorlagen aus unserem Angebot
                    aus. Neben den Vertragsvorlagen finden Sie bei uns auch
                    andere Rechtsdokumente wie zum Beispiel Kündigungsschreiben.
                  </p>
                  <p>
                    Unser Angebot deckt bereits diverse Gebiete aus dem privaten
                    und geschäftlichen Alltag ab und wird in regelmässigen
                    Abständen erweitert.
                  </p>
                  <CatalogOverview
                    navigation={navigation}
                    templates={templates}
                  />
                  <p>
                    Fehlt Ihnen eine Vertrags- oder Dokumentvorlage? Dann zögern
                    Sie nicht uns zu{" "}
                    <SuggestContent as="a" href="#">
                      kontaktieren
                    </SuggestContent>
                    : Gerne erweitern wir unser Angebot nach Ihren Vorschlägen
                    und Bedürfnissen! Es ist das erklärte Ziel von LegalGo,
                    unser Produktesortiment ständig zu erweitern. Abonnieren Sie
                    daher unseren Newsletter, um stets über unsere neusten
                    Angebote informiert zu werden.
                  </p>
                </Segment>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3}>
                <LazyLoad height="160px" once>
                  <Image src={step2} />
                </LazyLoad>
              </Grid.Column>
              <Grid.Column width={13}>
                <Segment stacked>
                  <Header as="h3">Fragen zum Vertragsinhalt beantworten</Header>
                  <p>
                    {" "}
                    Sobald Sie Ihr gewünschtes Produkt ausgewählt haben («sofort
                    erstellen» klicken), können Sie mit der selbständigen
                    Erstellung Ihres Rechtsdokumentes beginnen. Die Erstellung
                    funktioniert ganz einfach: Beantworten Sie die Fragen in der
                    Eingabemaske durch Ausfüllen der Eingabefelder. Die
                    intelligente Software von LegalGo erstellt dann umgehend ein
                    auf Ihren persönlichen Angaben basierendes Rechtsdokument.
                    Die Grundbausteine in Ihrem Rechtsdokument sind allesamt
                    anwaltlich vorformuliert. Zur Übersicht und Überprüfung
                    Ihres Vertragsdokuments erhalten Sie rechts neben dem
                    Eingabeformular eine Vorschau auf das fertige Rechtsdokument
                    mitsamt Ihrer selbst getätigten persönlichen Angaben (die
                    Vorschau erfolgt jeweils auszugsweise für die gerade
                    beantworteten Fragen in der Eingabemaske). Sie können somit
                    stets nachverfolgen, wie das Rechtsdokument laufend auf Ihre
                    Bedürfnisse angepasst wird.
                  </p>
                  <SamplePageForm />
                </Segment>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3}>
                <LazyLoad height="160px" once>
                  <Image src={step3} />
                </LazyLoad>
              </Grid.Column>
              <Grid.Column width={13}>
                <Segment stacked>
                  <Header as="h3">Fertiges Dokument herunterladen</Header>
                  <p>
                    Wenn Sie die Bearbeitung abgeschlossen und bezahlt haben,
                    können Sie Ihr persönliches Dokument im pdf-Format
                    (schreibgeschützt) herunterladen.
                  </p>
                  <p>
                    Für die Bezahlung akzeptieren wir im Moment folgende
                    Kreditkarten: VISA / MASTERCARD oder wir senden Ihnen eine
                    Rechnung mit Einzahlungsschein zu.
                  </p>
                  <p>
                    Nach der Bezahlung Ihres Produktes erhalten Sie eine
                    Bestätigungsemail mit Ihrem Dokument und einer Quittung. In
                    der Bestätigungsemail erhalten Sie zudem einen Link, über
                    den Sie Ihr Vertragsdokument auch nachträglich jederzeit
                    abrufen/herunterladen können.
                  </p>
                </Segment>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3}>
                <LazyLoad height="160px" once>
                  <Image src={step4} />
                </LazyLoad>
              </Grid.Column>
              <Grid.Column width={13}>
                <Segment stacked>
                  <Header as="h3">Ausdrucken und unterschreiben</Header>
                  <p>
                    Das wars! Sie erhalten das auf Ihre persönlichen
                    Verhältnisse angepasste Rechtsdokument im pdf-Format
                    (schreibgeschützt).
                  </p>
                  <p>
                    Sollten Sie im Nachhinein Änderungen/Ergänzungen an Ihrem
                    Rechtsdokument vornehmen oder dieses überprüfen lassen
                    wollen, stehen Ihnen unterschiedliche Zusatzangebote zur
                    Verfügung. Diese können Sie jederzeit nach dem Kauf erwerben
                    (Sie erhalten einen entsprechenden Link in der
                    Kaufbestätigungsemail) oder auch zeitgleich mit dem Kauf
                    Ihres Rechtsdokumentes, sofern Sie dieses Angebot von Beginn
                    an nutzen wollen.
                  </p>
                  <p>
                    Folgende kostenpflichtige Zusatzangebote stehen zur
                    Verfügung:
                  </p>
                  <Item.Group unstackable>
                    <Item>
                      <Item.Image size="tiny">
                        <Image src={lawyer} size="huge" />
                      </Item.Image>
                      <Item.Content>
                        <Item.Header>Beurkundung durch Notar</Item.Header>
                        <Item.Description>
                          Lassen Sie Ihr Dokument oder Ihren Vertrag beim
                          Notariat db-Legal, Dufourstrasse 121, in 9000 St.
                          Gallen, beurkunden.
                        </Item.Description>
                      </Item.Content>
                    </Item>
                  </Item.Group>
                  <Item.Group unstackable>
                    <Item>
                      <Item.Image size="tiny">
                        <Image src={doc} size="huge" />
                      </Item.Image>
                      <Item.Content>
                        <Item.Header>
                          Zusätzlicher Erhalt Ihres Dokuments als Word-Datei
                        </Item.Header>
                        <Item.Description>
                          Ohne Kauf dieses Zusatzangebotes erhalten Sie Ihr
                          Rechtsdokument als schreibgeschütztes pdf-Dokument.
                          Der Erhalt als Word-Datei erlaubt es Ihnen im Sinne
                          einer Lizenz jederzeit und unbeschränkt{" "}
                          <strong>selbständig</strong> Anpassungen an Ihrem
                          Vertragsdokument vorzunehmen.
                        </Item.Description>
                      </Item.Content>
                    </Item>
                  </Item.Group>
                  <Item.Group unstackable>
                    <Item>
                      <Item.Image size="tiny">
                        <Image src={lawyer} size="huge" />
                      </Item.Image>
                      <Item.Content>
                        <Item.Header>
                          Anwaltliche Überprüfung Ihres Rechtsdokumentes
                        </Item.Header>
                        <Item.Description>
                          Umgehend nach Kauf dieses Zusatzangebotes wird Ihr
                          Dokument an einen Anwalt von LegalGo weitergeleitet.
                          Er wird Ihr Vertragsdokument vorab zur Vorbereitung
                          studieren und Sie innerhalb eines Werktages zwecks
                          Besprechung Ihrer Fragen telefonisch kontaktieren. Im
                          Rahmen eines 15-minütigen Beratungsgespräches können
                          dann noch unklare Vertragsinhalte gemeinsam mit dem
                          Anwalt geklärt werden. Bei Bedarf wird der Anwalt
                          Ihren Vertrag im Anschluss an das Beratungsgespräch
                          korrigieren (Korrektur/Anpassung bestehender Inhalte
                          Ihres selbst erstellten Rechtsdokumentes). Die
                          korrigierte Version wird Ihnen per E-Mail zugeschickt.
                        </Item.Description>
                      </Item.Content>
                    </Item>
                  </Item.Group>
                </Segment>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3} />
              <Grid.Column width={13}>
                <Segment stacked>
                  <Header as="h3">Haben Sie noch weitere Fragen?</Header>
                  <p>
                    Lesen Sie unsere{" "}
                    <Link to="/faq">Antworten auf häufig gestellte Fragen</Link>{" "}
                    zu den Produkten von LegalGo.
                  </p>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Section>
      </MainLayout>
    )
  }
}

function mapStateToProps(state) {
  const navigation = selectors.getNavigation(state)
  const templates = selectors.getContractTemplates(state)
  return {
    navigation,
    templates,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HowItWorksTemplates)
