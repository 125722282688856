// @flow

import * as React from "react"
import { Segment, Button } from "semantic-ui-react"
import { type Match, Link } from "react-router-dom"
import { withRouter } from "react-router-dom"
import { Carousel } from "react-responsive-carousel"

import {
  extractFields,
  getFeaturedPostsQuery,
} from "../../react-redux-prismic-blog"
import { PrismicLoader } from "../../react-redux-prismic-blog"

import config from "../../config/index"
import logo from "../../styles/images/logo-large.png"

import "react-responsive-carousel/lib/styles/carousel.css"

export type Props = { match: Match }

const CarouselPage = ({ children }) => {
  return <div style={{ height: "20em" }}>{children}</div>
}

const Legend = ({ children }) => {
  return (
    <p
      className="legend"
      style={{
        opacity: 1,
        background: "none",
        fontSize: "1rem",
      }}
    >
      {children}
    </p>
  )
}

const Image = ({ src, alt }) => {
  return (
    <img style={{ objectFit: "cover", height: "100%" }} src={src} alt={alt} />
  )
}

class FeaturedPostsCarousel extends React.Component<Props> {
  render() {
    const { match } = this.props
    return (
      <Segment style={{ padding: 5 }}>
        <PrismicLoader
          url={config.api.prismic}
          query={getFeaturedPostsQuery()}
          callback={({ results }) => results.map(extractFields(match))}
          renderLoading={() => (
            <Carousel showStatus={false} showThumbs={false}>
              <CarouselPage>
                <Image src={logo} alt={""} />
                <Legend>
                  <Button
                    color="teal"
                    loading={true}
                    content="Lade aktuelle Beiträge..."
                    size="big"
                    icon="arrow right"
                    labelPosition="right"
                  />
                </Legend>
              </CarouselPage>
            </Carousel>
          )}
          renderLoaded={({ response }) => (
            <Carousel
              showStatus={false}
              showThumbs={false}
              autoPlay
              infiniteLoop
            >
              {response.map((props, index) => (
                <CarouselPage key={index}>
                  <Image src={props.image} alt={props.header} />
                  <Legend>
                    <Button
                      as={Link}
                      color="teal"
                      content={props.header}
                      size="big"
                      icon="arrow right"
                      labelPosition="right"
                      to={`${config.route.blog.index}${props.url}`}
                    />
                  </Legend>
                </CarouselPage>
              ))}
            </Carousel>
          )}
        />
      </Segment>
    )
  }
}

export default withRouter(FeaturedPostsCarousel)
