// @flow

import { push } from "connected-react-router"

import type {
  Dispatch,
  GetState,
  Services,
} from "../../../../store/rootReducer"

import config from "../../../../config"

export const signIn = (
  email: string,
  password: string,
  rememberMe: boolean
) => async (dispatch: Dispatch, getState: GetState, { authApi }: Services) => {
  dispatch({ type: "AUTH_SIGN_IN_REQUESTED" })
  try {
    const response = await authApi.signIn({ email, password, rememberMe })
    dispatch({ type: "AUTH_SAVE_USER", details: response.details })
    dispatch({ type: "AUTH_SIGN_IN_SUCCEEDED" })
  } catch (e) {
    switch (e.response.code) {
      case "auth.signIn.form.invalid": {
        dispatch({ type: "AUTH_SIGN_IN_FAILED", message: e.message })
        break
      }
      case "auth.signIn.account.inactive":
        dispatch({ type: "AUTH_SIGN_IN_FAILED", message: e.message })
        dispatch({
          type: "AUTH_SAVE_ACTIVATION_EMAIL",
          email: e.response.details.email,
        })
        dispatch(push(config.route.auth.accountActivation))
        break
      default:
        dispatch({ type: "AUTH_SIGN_IN_FAILED", message: e.message })
        break
    }
  }
}
