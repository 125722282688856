// @flow
import React from "react"
import config from "../config/index"

import "./ApiDebugInfo.css"

export default () => {
  if (config.env === "production") {
    return <div className="ApiDebugInfo production" />
  } else if (config.env === "development") {
    return (
      <div className="ApiDebugInfo development">Dev: {config.apiBaseUrl}</div>
    )
  } else if (config.env === "test") {
    return <div className="ApiDebugInfo test">Test: {config.apiBaseUrl}</div>
  } else {
    return <div className="ApiDebugInfo">Unk: {config.apiBaseUrl}</div>
  }
}
