// @flow

import * as React from "react"
import {
  Loader,
  Grid,
  Header,
  Item,
  Container,
  Image,
  Segment,
} from "semantic-ui-react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import MainLayout from "../../layouts/MainLayout"
import Section from "../../components/Section"

import { selectors, actionCreators } from "../../modules"

import { withRouter, type Match } from "react-router-dom"
import ErrorMessage from "../../components/ErrorMessage"
import { type State } from "../../store/rootReducer"
import "./ProductDetails.css"
import reactGa from "react-ga"
import { stripHtml } from "../../util/utils"
import { Helmet } from "react-helmet"
import RawHtmlComponent from "../../components/RawHtmlComponent"
import defaultImage from "../../styles/images/template-default.jpg"
import CreateContractButton from "./components/CreateContractButton"
import CreateFixpriceButton from "./components/CreateFixpriceButton"
import StartChatButton from "./components/StartChatButton"
import BreadCrumbs from "../../components/BreadCrumbs"
import {
  HowContractsWork,
  HowFixpricesWork,
} from "../../components/HowItWorksButtons"
import SocialShareButtons from "../../components/SocialShareButtons"
import TemplateOrderItemList from "./components/TemplateOrderItemList"
import { LazyExpertRecommendation } from "../Experts"
import Partners from "../../components/Partners"

export type Props = {
  template: ?Template,
  path: string,
  navigation: ?PrimaryNavigation,
  isLoading: boolean,
  isCreating: boolean,
  errors: ?ApiError,
  actions: {
    fetchTemplates: () => ApiCall,
    createNewContract: (templateId: TemplateId, path: string) => void,
    createNewFixpriceRequest: (templateId: TemplateId, path: string) => void,
  },
}

const TemplateHeaderSection = ({
  template,
  isCreating,
  handleNewContract,
  handleNewFixprice,
}: {
  template: Template,
  isCreating: boolean,
  handleNewContract: (event: Event) => void,
  handleNewFixprice: (event: Event) => void,
}) => (
  <Grid className="HeaderBar" stackable>
    <Grid.Row verticalAlign="top" columns={16}>
      <Grid.Column width={10}>
        <Header className="template-name" content={template.name} />
        <RawHtmlComponent
          html={template.description && template.description["header"]}
        />
        <Container textAlign="center">
          {template.kind === "Contract" ? (
            <CreateContractButton
              content={`${template.name} erstellen`}
              size="huge"
              loading={isCreating}
              onClick={handleNewContract}
            />
          ) : (
            <CreateFixpriceButton
              content="Anfrage für Fixpreisangebot starten"
              size="huge"
              loading={isCreating}
              onClick={handleNewFixprice}
            />
          )}
        </Container>
        <Container textAlign="center">
          <StartChatButton />
        </Container>
      </Grid.Column>
      <Grid.Column width={1} />
      <Grid.Column width={5} floated="right">
        <Image
          bordered
          shape="rounded"
          size="huge"
          src={
            template.imageUrl != null
              ? template.imageUrl
              : /*not sure why, but server-rendered the leading / is missing: */
              defaultImage.startsWith("/")
              ? defaultImage
              : `/${defaultImage}`
          }
        />
      </Grid.Column>
    </Grid.Row>
  </Grid>
)

export class ProductDetails extends React.Component<Props, void> {
  static fetchData(params: {}, dispatch: Dispatch) {
    return dispatch(actionCreators.fetchTemplates())
  }

  componentDidMount() {
    const { template } = this.props
    if (!template) {
      this.props.actions.fetchTemplates()
    } else {
      reactGa.event({
        category: "Page View",
        action: `Viewed ${template.name}`,
      })
    }
  }

  handleNewContract = (event: Event) => {
    event.preventDefault()
    const { template, path, actions } = this.props
    if (template) {
      actions.createNewContract(template.id, path)
    }
  }

  handleNewFixprice = (event: Event) => {
    event.preventDefault()
    const { template, path, actions } = this.props
    if (template) {
      actions.createNewFixpriceRequest(template.id, path)
    }
  }

  isContract = () =>
    this.props.template && this.props.template.kind === "Contract"

  render() {
    const {
      template,
      navigation,
      path,
      isLoading,
      isCreating,
      errors,
    } = this.props

    const secondaryNavigation =
      navigation && template && navigation.children
        ? navigation.children.find(
            ({ offerings }) => offerings.indexOf(template.id) >= 0
          ) || {}
        : {}

    const title = template
      ? template.name +
        (template.kind === "Contract"
          ? " zum Pauschalpreis online erstellen"
          : " zum Fixpreis")
      : ""

    const description = template
      ? stripHtml(template.description["subtitle"] || "")
      : ""

    return (
      <MainLayout
        className={`TemplateDetails ${template ? template.kind : ""}`}
      >
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta itemprop="name" content={title} />
          <meta itemprop="description" content={description} />
          <meta itemprop="image" content={template && template.imageUrl} />
          <meta name="twitter:card" content="product" />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:image" content={template && template.imageUrl} />
          <meta
            name="twitter:data1"
            content={`CHF ${(template && template.price) || ""}`}
          />
          <meta name="twitter:label1" content="Price" />
          <meta property="og:type" content="product" />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={template && template.imageUrl} />
          <meta
            property="og:image:url"
            content={template && template.imageUrl}
          />
          <meta
            property="og:price:amount"
            content={(template && template.price) || ""}
          />
          <meta property="og:price:currency" content="CHF" />
          <meta
            property="og:url"
            content={`https://www.legalgo.ch/${
              navigation ? navigation.path : ""
            }/${path}`}
          />
        </Helmet>
        <Section color="blue">
          {errors ? (
            <ErrorMessage error={errors} />
          ) : !navigation || isLoading ? (
            <Loader active inline="centered" content="Loading" />
          ) : template ? (
            <TemplateHeaderSection
              template={template}
              path={path}
              navigation={navigation}
              isCreating={isCreating}
              handleNewContract={this.handleNewContract}
              handleNewFixprice={this.handleNewFixprice}
            />
          ) : (
            <ErrorMessage
              error={{ response: "Dieser Inhalt existiert nicht" }}
            />
          )}
        </Section>
        <Section color="white">
          {navigation && (
            <BreadCrumbs
              crumbs={[
                { name: navigation.title, link: `/${navigation.path}` },
                {
                  name: secondaryNavigation.title,
                  link: `/${navigation.path}#${secondaryNavigation.path}`,
                },
              ]}
            />
          )}
          {template && (
            <Grid stackable columns={16}>
              <Grid.Row verticalAlign="top">
                <Grid.Column width={11}>
                  {template.description["provisions"] &&
                    template.description["provisions"].length > 0 &&
                    template.description["provisions"] !== "<p></p>" && (
                      <Segment>
                        <RawHtmlComponent
                          html={template.description["provisions"]}
                        />
                      </Segment>
                    )}
                  <Segment basic>
                    <RawHtmlComponent
                      html={template.description["maincontent"]}
                    />
                    <p />
                    {this.isContract() ? (
                      <HowContractsWork
                        icon="question"
                        content="So erstellen Sie Ihren Vertrag"
                      />
                    ) : (
                      <HowFixpricesWork
                        icon="question"
                        content="Mehr zum Ablauf unserer Fixpreisangebote"
                      />
                    )}
                  </Segment>
                  <p />
                  <Segment className="details-for-mobile">
                    <TemplateOrderItemList
                      name={template.name}
                      description={template.description["subtitle"]}
                      kind={template.kind}
                      price={template.price}
                      extras={template.extras}
                      long={false}
                      orderButton={
                        this.isContract() ? (
                          <CreateContractButton
                            content={`${template.name} erstellen`}
                            loading={this.props.isCreating}
                            onClick={this.handleNewContract}
                            fluid
                          />
                        ) : (
                          <CreateFixpriceButton
                            content="Anfrage für Fixpreisangebot starten"
                            loading={this.props.isCreating}
                            onClick={this.handleNewFixprice}
                            fluid
                          />
                        )
                      }
                    />
                    <Item.Group
                      items={[
                        {
                          content: (
                            <RawHtmlComponent
                              html={template.description["sidebar"]}
                            />
                          ),
                        },
                      ]}
                    />
                  </Segment>
                  <LazyExpertRecommendation
                    specialty={{
                      name: secondaryNavigation.title,
                      id: secondaryNavigation.path,
                    }}
                  />
                </Grid.Column>
                <Grid.Column
                  className="sidebar"
                  width={5}
                  floated="right"
                  only="computer tablet"
                >
                  <Segment stacked>
                    <TemplateOrderItemList
                      name={template.name}
                      description={template.description["subtitle"]}
                      kind={template.kind}
                      price={template.price}
                      extras={template.extras}
                      long={false}
                      orderButton={
                        this.isContract() ? (
                          <CreateContractButton
                            content={`${template.name} erstellen`}
                            loading={this.props.isCreating}
                            onClick={this.handleNewContract}
                            fluid
                          />
                        ) : (
                          <CreateFixpriceButton
                            content="Anfrage für Fixpreisangebot starten"
                            loading={this.props.isCreating}
                            onClick={this.handleNewFixprice}
                            fluid
                          />
                        )
                      }
                    />
                    {template.description["sidebar"] &&
                      template.description["sidebar"].length > 8 && (
                        <Item.Group
                          items={[
                            {
                              content: (
                                <RawHtmlComponent
                                  html={template.description["sidebar"]}
                                />
                              ),
                            },
                          ]}
                        />
                      )}
                  </Segment>
                  <Segment basic>
                    <SocialShareButtons buttonsOnly={false} />
                  </Segment>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1}>
                <Grid.Column>
                  <Segment basic>
                    <Header
                      style={{ color: "#444" }}
                      as="h2"
                      content="Unsere Partner"
                    />
                    <Partners />
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}
        </Section>
      </MainLayout>
    )
  }
}

export type OwnProps = {
  match: Match,
}

function mapStateToProps(state: State, ownProps: OwnProps) {
  const { primaryCategory, name } = ownProps.match.params
  const template = selectors.getTemplateByPath(state, name)
  const navigation = selectors.getPrimaryNavigation(state, primaryCategory)
  const path =
    primaryCategory != null && name != null ? `/${primaryCategory}/${name}` : ""
  return {
    template,
    navigation,
    path,
    isLoading: selectors.isFetchingTemplates(state),
    isCreating:
      selectors.isCreatingContract(state) ||
      selectors.isCreatingFixpriceRequest(state),
    errors:
      selectors.getFetchingTemplatesErrors(state) ||
      selectors.getCreateContractErrors(state) ||
      selectors.getCreateFixpriceRequestErrors(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProductDetails)
)
