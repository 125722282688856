// @flow
// flowlint deprecated-type:off

import * as React from "react"
import { Loader } from "semantic-ui-react"

export type Props = {
  children: (module: *) => React.Node,
  load: (module: *) => Promise<*>,
}

export default class LazyLoadedModule extends React.Component<
  Props,
  { mod: ?* }
> {
  state = {
    // short for "module" but that's a keyword in js, so "mod"
    mod: null,
  }

  componentDidMount() {
    this.load(this.props)
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.load !== this.props.load) {
      this.load(nextProps)
    }
  }

  load(props: Props) {
    props.load().then(mod => {
      this.setState({
        // handle both es imports and cjs
        mod: mod.default ? mod.default : mod,
      })
    })
  }

  render() {
    return this.state.mod ? (
      this.props.children(this.state.mod)
    ) : (
      <Loader active inline="centered" size="large" />
    )
  }
}
