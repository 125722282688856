// @flow

import * as React from "react"
import { Container } from "semantic-ui-react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { withRouter } from "react-router"
import ReactGA from "react-ga"

import { selectors } from "../modules"

import { hasLocalStorage } from "../util/utils"

import "./Reminder.css"

export type Props = {
  id: ?OfferingRequestId,
  scrolled: boolean,
  name: ?string,
  order: ?Order,
  pathname: string,
}

class Reminder extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
    this.reminderEvent()
  }

  componentWillReceiveProps({ id, name }: Props) {
    if (this.props.id !== id && id && name != null && hasLocalStorage()) {
      localStorage.setItem("ReminderId", id)
      localStorage.setItem("ReminderName", name)
      this.reminderEvent()
    }
  }

  reminderEvent() {
    if (this.isActive()) {
      ReactGA.event({
        category: "Reminder Bookmark",
        action: `Showed for ${this.props.id || ""}`,
      })
    }
  }

  handleClick = () => {
    ReactGA.event({
      category: "Reminder Bookmark",
      action: `Clicked on ${this.props.id || ""}`,
    })
  }

  isActive = () => {
    const { id, order, pathname } = this.props
    const isAlreadyEditing = id ? pathname.indexOf(id) > 0 : false
    const hasNoOffering = id === null
    const isAlreadyPaid = order ? order.state === "Paid" : false
    return !(isAlreadyEditing || hasNoOffering || isAlreadyPaid)
  }

  render() {
    const { id, name, scrolled } = this.props

    const multilineName: Array<string> = (name || "")
      .split(" ")
      .reverse()
      .reduce((sum: Array<string>, value: string) => {
        if (sum.length === 0) {
          return [value]
        }
        const [previous, ...rest] = sum
        if (previous.length + value.length < 10) {
          return [`${value} ${previous}`, ...rest]
        }
        return [value, ...sum]
      }, [])

    return (
      <div className={`ReminderWrapper ${scrolled ? "scrolled" : ""}`}>
        <Container
          className={`Reminder ${this.isActive() ? "active" : "inactive"}`}
        >
          <div className="Bookmark">
            <p className="hidden">
              {id && (
                <Link onClick={this.handleClick} to={`/${id || ""}`}>
                  Ihre Eingaben wurden gesichert. Klicken Sie hier um
                  fortzufahren:
                </Link>
              )}
            </p>
            <p className="name">
              <Link onClick={this.handleClick} to={`/${id || ""}`}>
                {multilineName.map((n, index) => (
                  <span key={index}>
                    {n}
                    <br />
                  </span>
                ))}
              </Link>
            </p>
          </div>
        </Container>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const contract = selectors.getContract(state)
  const fixprice = selectors.getFixpriceRequest(state)

  let name = contract
    ? contract.template.name
    : fixprice
      ? fixprice.template.name
      : ""

  const order: ?Order = contract
    ? contract.order
    : fixprice
      ? fixprice.order
      : null

  let id = contract ? contract.id : fixprice ? fixprice.id : null

  if (!id && !name && hasLocalStorage()) {
    id = localStorage.getItem("ReminderId")
    name = localStorage.getItem("ReminderName")
  }

  return {
    pathname: ownProps.location.pathname,
    id,
    name,
    order,
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(Reminder)
)
