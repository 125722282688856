// @flow

import environments from "./environments"

const config = {
  env: process.env.NODE_ENV != null ? process.env.NODE_ENV : "development",
  analytics: true,
  apiBaseUrl: "",
  domain: "https://www.legalgo.ch",
  apiServer: "https://api.legalgo.ch",
  route: {
    index: "/",
    auth: {
      index: "/auth",
      signIn: "/auth/sign-in",
      signUp: "/auth/sign-up",
      passwordRecovery: "/auth/password/recovery",
      accountActivation: "/auth/account/activation",
    },
    contact: {
      index: "/ueber-uns",
    },
    experts: {
      index: "/experten",
    },
    terms: {
      index: "/agb",
    },
    newsletter: {
      index: "/newsletter",
    },
    infos: {
      faq: "/faq",
      howContractsWork: "/so-funktionieren-vertraege",
      howFixpricesWork: "/so-funktionieren-fixpreise",
    },
    blog: {
      index: "/blog",
      preview: "/blog/preview",
    },
  },
  api: {
    prismic: "https://legalgo.cdn.prismic.io/api/v2",
    sentry: "https://d64812ac79ef4e8b997be131f8f0e646@sentry.legalgo.ch/5",
  },
}

// ========================================================
// Environment Configuration
// ========================================================
const overrides = environments[config.env]
if (overrides) {
  Object.assign(config, overrides())
}

export default config
